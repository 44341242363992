import React, { useEffect, useState, useRef } from 'react'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
import Slider from "react-slick";
import {Link} from "react-router-dom";

function Quick_Access()
{
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const settings = {
        className : 'hom-qui-acc-sli',
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 3000,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false
                }
            }
        ]
    };

    return (
        <div>
            {/* QUICK ACCESS */}
            <section>
                <div class="str home-acces-main">
                    <div class="container">
                        <div class="row">
                            {/* BACKGROUND SHAPE */}
                            <div class="wedd-shap">
                                <span class="abo-shap-1"></span>
                                <span class="abo-shap-4"></span>
                            </div>
                            {/* END BACKGROUND SHAPE */}

                            <div class="home-tit">
                                <p>Quick Access</p>
                                <h2><span>Our Services</span></h2>
                                <span class="leaf1"></span>
                                <span class="tit-ani-"></span>
                            </div>
                            <div class="home-acces">
                                {/* <ul class="hom-qui-acc-sli"> */}
                                <Slider {...settings}>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc1" data-wow-delay="0.1s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/user.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>Browse Profiles</h4>
                                                <p>1200+ Profiles</p>
                                                {/* <a href="all-profiles.html">View more</a> */}
                                                { (!isLoggedIn) && <Link to="/login.html">View more</Link>}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc2" data-wow-delay="0.2s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/gate.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>Wedding</h4>
                                                <p>1200+ Profiles</p>
                                                {/* <a href="wedding-video.html">View more</a> */}
                                                { (!isLoggedIn) && <Link to="/login.html">View more</Link>}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/couple.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>All Services</h4>
                                                <p>1200+ Profiles</p>
                                                {/* <a href="services.html">View more</a> */}
                                                { (!isLoggedIn) && <Link to="/login.html">View more</Link>}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/hall.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>Join Now</h4>
                                                <p>Start for free</p>
                                                {/* <a href="plans.html">Get started</a> */}
                                                { (!isLoggedIn) && <Link to="/sign-up.html">Get Started</Link>}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/photo-camera.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>Photo gallery</h4>
                                                <p>1200+ Profiles</p>
                                                {/* <a href="photo-gallery.html">View more</a> */}
                                                { (!isLoggedIn) && <Link to="/login.html">View more</Link>}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                            <div class="con">
                                                <img src={"/assets/images/icon/cake.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                                <h4>Blog & Articles</h4>
                                                <p>Start for free</p>
                                                { (!isLoggedIn) && <Link to="/sign-up.html">Get Started</Link>}
                                            </div>
                                        </div>
                                    </li>
                                </Slider>
                                {/* </ul> */}
                            {/* <Slider {...settings}>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc1" data-wow-delay="0.1s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/user.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>Browse Profiles</h4>
                                            <p>1200+ Profiles</p>
                                            <a href="all-profiles.html">View more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc2" data-wow-delay="0.2s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/gate.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>Wedding</h4>
                                            <p>1200+ Profiles</p>
                                            <a href="wedding-video.html">View more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/couple.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>All Services</h4>
                                            <p>1200+ Profiles</p>
                                            <a href="services.html">View more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/hall.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>Join Now</h4>
                                            <p>Start for free</p>
                                            <a href="plans.html">Get started</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc3" data-wow-delay="0.3s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/photo-camera.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>Photo gallery</h4>
                                            <p>1200+ Profiles</p>
                                            <a href="photo-gallery.html">View more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='slider-item'>
                                    <div class="wow fadeInUp hacc hacc4" data-wow-delay="0.4s">
                                        <div class="con">
                                            <img src={"/assets/images/icon/cake.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            <h4>Blog & Articles</h4>
                                            <p>Start for free</p>
                                            <a href="blog.html">Get started</a>
                                        </div>
                                    </div>
                                </div>
                            </Slider> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Quick_Access