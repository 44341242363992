import { combineReducers } from 'redux';

const authData = (state = {}, action) => {
    switch (action.type) {
        case "AUTH_USER_SUCCESS":
            return {
              token: action.token,
              isLoggedIn: true
            }

        case "AUTH_USER_FAIL":
            return {
              token: null,
              isLoggedIn: false
            }
        default:
          return state;
    }
}

const createUser = (state = {}, action ) => {
    switch (action.type) {
        case 'CREATE_USER_LOADING':
            return {
                isLoading : true,
                token : null,
                isError : false,
                isSuccess : false,
                errors : null,
                isLoggedIn : false
            }
            break;
        case 'CREAT_USER_SUCCESS':
            return {
                isLoading : false,
                token : action.token,
                isError : false,
                isSuccess : true,
                errors : null,
                isLoggedIn : false
            }
            break;
        case 'CREAT_USER_FAILURE':
            return {
                isLoading : false,
                token : null,
                isError : true,
                isSuccess : false,
                errors : null,
                isLoggedIn : false
            }
            break;
        default:
            return state;
    }
}

const loginUser = (state = {}, action ) => {
    switch (action.type) {
        case 'LOGIN_USER_LOADING':
            return {
                isLoading : true,
                token : null,
                isError : false,
                isSuccess : false,
                errors : null,
                isLoggedIn : false
            }
            break;
        case 'LOGIN_USER_SUCCESS':
            return {
                isLoading : false,
                token : action.token,
                isError : false,
                isSuccess : true,
                errors : null,
                isLoggedIn : true
            }
            break;
        case 'LOGIN_USER_FAILURE':
            return {
                isLoading : false,
                token : null,
                isError : true,
                isSuccess : false,
                errors : null,
                isLoggedIn : false
            }
            break;
        default:
            return state;
    }
}

const forgotPassword = (state = {}, action ) => {
    switch (action.type) {
        case 'FORGOT_PASSWORD_LOADING':
            return {
                isLoading : true,
                token : null,
                isError : false,
                isSuccess : false,
                errors : null,
            }
            break;
        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                isLoading : false,
                token : action.token,
                isError : false,
                isSuccess : true,
                errors : null,
            }
            break;
        case 'FORGOT_PASSWORD_FAILURE':
            return {
                isLoading : false,
                token : null,
                isError : true,
                isSuccess : false,
                errors : null,
            }
            break;
        default:
            return state;
    }
}

const getCommonData = (state = {}, action ) => {
    switch (action.type) {
        case 'COMMON_DATA_SUCCESS':
            return {
                isLoading : false,
                isError : false,
                isSuccess : true,
                errors : null,
                common_data : action.payload,
            }
            break;
        case 'COMMON_DATA_FAILURE':
            return {
                isLoading : false,
                isError : true,
                isSuccess : false,
                errors : null,
            }
            break;
        default:
            return state;
    }
}

const searchMembers = (state = {}, action ) => {
    switch (action.type) {
        case 'SEARCH_MEMBERS_LOADING':
            return {
                isLoading : true,
                token : null,
                isError : false,
                isSuccess : false,
                errors : null,
            }
            break;
        case 'SEARCH_MEMBERS_SUCCESS':
            return {
                isLoading : false,
                token : action.token,
                isError : false,
                isSuccess : true,
                errors : null,
            }
            break;
        case 'SEARCH_MEMBERS_FAILURE':
            return {
                isLoading : false,
                token : null,
                isError : true,
                isSuccess : false,
                errors : null,
            }
            break;
        default:
            return state;
    }
}

export default combineReducers ({
    createUser,
    loginUser,
    authData,
    forgotPassword,
    searchMembers,
    getCommonData
})