import React, { useEffect, useState } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import SubmitLoader from '../../components/common/submit_loader';

function Contact()
{
    const[pageContent, setPageContent] = useState('');
    const[isLoading, setIsLoading] = useState(true);

    const [submitBtnText, changeSubmitBtnText] = useState('Send Enquiry');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    })

    const ContactupSchema = Yup.object({
        contact_name: Yup.string().required('Required'),
        contact_email: Yup.string().email('Invalid email address').required('Required'),
        contact_mobile: Yup.string().required('Required').min(10, 'Mobile must be exactly 10 digits').max(10, 'Mobile must be exactly 10 digits'),
        contact_subject: Yup.string().required('Required'),
        contact_message: Yup.string().required('Required').min(10, 'Message must be 10 characters or more').max(100, 'Message must be less than 100 characters'),
    });

    function blockSpecialChar(evt, obj){
        /*evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 46)) {
            return false;
        }
        return true;*/

        console.log(evt);
        console.log(evt.key);

        var data = evt.key;

        var regex = new RegExp("^[0-9]$");
        var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
        else if(data.Length >= 10)
        {
            evt.preventDefault();
            return false;
        }
    }

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/contact_us',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                resetForm({values: ''});
                COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                changeSubmitBtnText('Send Enquiry');
                changeSubmitBtnStatus(false);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Send Enquiry');
                changeSubmitBtnStatus(false);
            }
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Send Enquiry');
            changeSubmitBtnStatus(false);
        });
        setSubmitting(false);
    }

    //

    return (
        <div className='contact-page'>
            <Top/>
            <Header/>
            
            {/*  BANNER  */}
                <section>
                    <div class="str">
                        <div class="ban-inn ab-ban pg-cont">
                            <div class="container">
                                <div class="row">
                                    <div class="hom-ban">
                                        <div class="ban-tit">
                                            <span>We are here to assist you.</span>
                                            <h1>Contact us</h1>
                                            <p>Most Trusted and premium Matrimony Service in the World.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/*  END  */}

            {/*  START  */}
                <section>
                    <div class="ab-sec2 pg-cont">
                        <div class="container">
                            <div class="row">
                                <ul>
                                    <li>
                                        <div class="we-here">
                                            <h3>Our office</h3>
                                            <h4 className='p-b-20 p-t-0'>{CONSTANTS.PROJECT_TITLE}</h4>
                                            <span><i class="fa fa-phone" aria-hidden="true"></i> <a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER}>{CONSTANTS.PUBLIC_CONTACT_NUMBER}</a></span>
                                            <span><i class="fa fa-envelope-o" aria-hidden="true"></i> <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}>{CONSTANTS.PUBLIC_CONTACT_EMAIL}</a></span>
                                            <span><i class="fa fa-map-marker" aria-hidden="true"></i> {CONSTANTS.PUBLIC_ADDRESS}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="we-cont">
                                            <img src={"/assets/images/icon/trust.png?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                            <h4>Customer Relations</h4>
                                            <p>Most Trusted and premium Matrimony Service in the World.</p>
                                            <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL} class="cta-rou-line">Get Support</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="we-cont">
                                            <img src={"/assets/images/icon/telephone.png?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                            <h4>WhatsApp Support</h4>
                                            <p>Most Trusted and premium Matrimony Service in the India.</p>
                                            <a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER} class="cta-rou-line">Talk to sales</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  END  */}

                {/*  REGISTER  */}
                <section>
                    <div class="login pg-cont">
                        <div class="container">
                            <div class="row">

                                <div class="inn">
                                    <div class="lhs">
                                        <div class="tit">
                                            <h2>Now <b>Contact to us</b> Easy and fast.</h2>
                                        </div>
                                        <div class="im">
                                            <img src={"/assets/images/login-couple.png?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                        </div>
                                        <div class="log-bg">&nbsp;</div>
                                    </div>
                                    <div class="rhs">
                                        <div>
                                            <div class="form-tit">
                                                <h4>Let's talk</h4>
                                                <h1>Send your enquiry now </h1>
                                            </div>
                                            <div class="form-login">
                                            <Formik
                                                initialValues={{  contact_name: '', contact_email: '', contact_mobile: '', contact_subject: '', contact_message: '' }}
                                                validationSchema={ContactupSchema}
                                                onSubmit={SubmitForm}
                                                >
                                            {formik => (
                                                <form class="cform fvali" onSubmit={formik.handleSubmit}>
                                                    <div class="alert alert-success cmessage" style={{display:'none'}} role="alert">
                                                        Your message was sent successfully.
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="lb">Name:</label>
                                                        <input type="text" id="contact_name" class="form-control"
                                                            placeholder="Enter your full name" name="contact_name" onChange={formik.handleChange} value={formik.values.contact_name} />
                                                        {formik.errors.contact_name ? <div className='m-t-10 text-left error'>{formik.errors.contact_name}</div> : null}
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="lb">Email:</label>
                                                        <input type="email" class="form-control" id="contact_email"
                                                            placeholder="Enter email" name="contact_email" onChange={formik.handleChange} value={formik.values.contact_email} />
                                                        {formik.errors.contact_email ? <div className='m-t-10 text-left error'>{formik.errors.contact_email}</div> : null}
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="lb">Phone:</label>
                                                        <input type="number" class="form-control" id="contact_mobile"
                                                            placeholder="Enter phone number" name="contact_mobile" onChange={formik.handleChange} value={formik.values.contact_mobile}  pattern="\d{10}" minLength = "10" maxLength = "10" onInvalid="this.setCustomValidity('Please enter 10 digit mobile number.')" onInput={(event) => {if (event.target.value.length >= 10) { event.target.value = event.target.value.slice(0, 10); console.log('if'); /* this.setCustomValidity('') */ } else if (event.target.value.length < 10) {  console.log('else');/* this.setCustomValidity('Please enter 10 digit mobile number.'); */ }}}  onKeyPress={(event) => {return blockSpecialChar(event)}} />
                                                        {formik.errors.contact_mobile ? <div className='m-t-10 text-left error'>{formik.errors.contact_mobile}</div> : null}
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="lb">Subject:</label>
                                                        <input type="text" class="form-control" id="contact_subject"
                                                            placeholder="Enter subject" name="contact_subject" onChange={formik.handleChange} value={formik.values.contact_subject} />
                                                        {formik.errors.contact_subject ? <div className='m-t-10 text-left error'>{formik.errors.contact_subject}</div> : null}
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="lb">Message:</label>
                                                        <textarea name="contact_message" class="form-control" id="contact_message"
                                                            placeholder="Enter message" onChange={formik.handleChange} value={formik.values.contact_message}></textarea>
                                                        {formik.errors.contact_message ? <div className='m-t-10 text-left error'>{formik.errors.contact_message}</div> : null}
                                                    </div>
                                                    {/* <button type="submit" class="btn btn-primary">{submitBtnText}</button> */}
                                                    <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                                </form>
                                            )}
                                            </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/*  END  */}
            <Footer/>
        </div>
    )
}

export default Contact