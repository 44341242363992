import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';

let rest_params = {};

function FullCMS(props)
{
    const[pageContent, setPageContent] = useState('<center><i class="fa fa-spinner fa-spin"></i></center>');
    const[isLoading, setIsLoading] = useState(true);

    function getPayPackages()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/'+props.api_url, rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setPageContent(responce.data.content);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
        //if(pageContent === ''   )
            getPayPackages();
    }, [pageContent])

    return (
        <div>
            <Top/>
            <Header/>

            {/* REGISTER */}
            <section>
                <div class="login pg-faq">
                    <div class="container">
                        <div class="row">
                            <div class="inn ab-faq-lhs">
                                <div class="form-tit">
                                    {/* <h4></h4> */}
                                    <h1>{props.page_title}</h1>
                                </div>
                                <div class="text-left p-25" dangerouslySetInnerHTML={{ __html: pageContent }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*     END      */}
            <Footer/>
        </div>
    )
}

export default FullCMS