
import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// Import React FilePond
import { FilePond, registerPlugin } from "../../react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

import Introduction from '../../components/pages/member_profile/introduction';
import BasicInformation from '../../components/pages/member_profile/basic_information';
import PersonalInformation from '../../components/pages/member_profile/personal_information';
import PresentAddress from '../../components/pages/member_profile/present_address';
import EducationCareer from './member_profile/education_career';
import PhysicalAttributes from './member_profile/physical_attributes';
import Language from './member_profile/language';
import YourLikes from './member_profile/your_likes';
import PersonalAttitudeBehavior from './member_profile/personal_attitude_and_behavior';
import ResidencyInformation from './member_profile/residency_information';
import SpiritualSocialBackground from './member_profile/spiritual_and_social_background';
import LifeStyle from './member_profile/life_style';
import AstronomicInformation from './member_profile/astronomic_information';
import PermanentAddress from '../../components/pages/member_profile/permanent_address';
import FamilyInfo from './member_profile/family_info';
import AdditionalPersonalDetails from './member_profile/additional_personal_details';
import PartnerExpectation from './member_profile/partner_expectation';
import { parseJSON } from 'date-fns';

let post_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;
let full_name = 'Visitor';

function MemberProfile()
{
    // Register the plugins
    registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useLocation();

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);
    const [profilePic, selectProfilePic] = useState();
    const [loadingDataText, setLoadingDataText] = useState(<i class="fa fa-spinner fa-spin"></i>);

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userBasicData, setUserBasicData] = useState({});
    const [userLanguageData, setUserLanguageData] = useState({});
    const [userPresentAddressData, setUserPresentAddressData] = useState({});
    const [userPermanentAddressData, setUserPermanentAddressData] = useState({});
    const [userHobbiesInterestData, setUserHobbiesInterestData] = useState({});
    const [userEducationCareerData, setUserEducationCareerData] = useState({});
    const [userAttitudeBehaviorData, setUserAttitudeBehaviorData] = useState({});
    const [userResidencyInformationData, setUserResidencyInformationData] = useState({});
    const [userSpiritualData, setUserSpiritualData] = useState({});
    const [userLifeStyleData, setUserLifeStyleData] = useState({});
    const [userFamilyInfoData, setUserFamilyInfoData] = useState({});
    const [userAstronomicInformationData, setUserAstronomicInformationData] = useState({});
    const [userAdditionalPersonalData, setUserAdditionalPersonalData] = useState({});
    const [userPartnerExpectationData, setUserPartnerExpectationData] = useState({});
    const [userPhysicalData, setUserPhysicalData] = useState({});
    const [userGalleryData, setUserGalleryData] = useState([]);
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    const [userProfileSettings, setUserProfileSettings] = useState(common_data.common_data.user_profile_settings);

    const [myInterestsList, setMyInterestsList] = useState([]);
    const [myFollowersList, setMyFollowersList] = useState([]);
    const [myShortlistsList, seMyShortlistsList] = useState([]);
    const [myFollowedList, seMyFollowedList] = useState([]);
    const [myIgnoredList, seMyIgnoredList] = useState([]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const tabProfileBtnRef = useRef(null);
    const tabInterestsBtnRef = useRef(null);
    const tabFollowersBtnRef = useRef(null);
    const tabShortlistBtnRef = useRef(null);
    const tabFollowedBtnRef = useRef(null);
    const tabIgnoredBtnRef = useRef(null);

    let user_avatar = '/assets/images/profiles/man.png';
    if(isLoggedIn)
    {
        console.log();
        user_avatar = CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+userData.profile_image.thumb+'&r='+Math. random();
        full_name = userData.first_name+' '+userData.last_name;
    }

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    })

    function setAllUsersData (user_data)
    {
        setUserBasicData(user_data.basic_info);
        setUserLanguageData(user_data.language);
        setUserPresentAddressData(user_data.present_address);
        setUserPermanentAddressData(user_data.permanent_address);
        setUserHobbiesInterestData(user_data.hobbies_and_interest);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
        setUserLifeStyleData(user_data.life_style);
        setUserFamilyInfoData(user_data.family_info);
        setUserAstronomicInformationData(user_data.astronomic_information);
        setUserAttitudeBehaviorData(user_data.personal_attitude_and_behavior);
        setUserAdditionalPersonalData(user_data.additional_personal_details);
        setUserResidencyInformationData(user_data.residency_information);
        setUserPartnerExpectationData(user_data.partner_expectation);
        setUserPhysicalData(user_data.physical_attributes);
        setUserGalleryData(user_data.gallery);
    }

    const fetchUserProfileData = async () => {
        console.log('in fetchUserProfileData');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['member_id'] = userData.member_id;
            post_params['user_id'] = userData.member_id;

            console.log('in fetchUserProfileData try');

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/member_profile',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    //console.log(response);
                    if(response.data.success)
                    {
                        console.log(response);
                        /* if(Object.keys(userData).length < 1)
                        { */
                            setAllUsersData(response.data.member_data);
                            COMMONFUNCTIONS.setSession('UserDetails', response.data.member_data);
                            setUserData(response.data.member_data);
                            //COMMONFUNCTIONS.GoToPage(navigate, '/my-profile.html');
                        //}
                        //console.log(userData);
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    const loadMyInterests = async () => {
        console.log('in loadMyInterests');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            //post_params['page'] = 1;
            post_params['user_id'] = userData.member_id;

            setLoadingDataText(<i class="fa fa-spinner fa-spin"></i>);

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/myInterestList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    if(response.data.success)
                    {
                        console.log(response.data.my_interest_data.count);
                        if(response.data.my_interest_data.count > 0)
                            setMyInterestsList(response.data.my_interest_data.array_total_interests)
                        else
                            setLoadingDataText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    const loadMyFollowers = async () => {
        console.log('in loadMyFollowers');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = userData.member_id;

            setLoadingDataText(<i class="fa fa-spinner fa-spin"></i>);

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/myFollowersList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    if(response.data.success)
                    {
                        console.log(response);
                        if(response.data.my_followers_data.count > 0)
                            setMyFollowersList(response.data.my_followers_data.my_followers)
                        else
                            setLoadingDataText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    const loadMyShortlists = async () => {
        console.log('in loadMyShortlists');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = userData.member_id;

            setLoadingDataText(<i class="fa fa-spinner fa-spin"></i>);

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/myShortList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    if(response.data.success)
                    {
                        console.log(response);
                        if(response.data.my_shortlist_data.count > 0)
                            seMyShortlistsList(response.data.my_shortlist_data.shortlist_data);
                        else
                            setLoadingDataText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    const loadMyFollowed = async () => {
        console.log('in loadMyFollowed');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = userData.member_id;

            setLoadingDataText(<i class="fa fa-spinner fa-spin"></i>);

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/myFollowedList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    if(response.data.success)
                    {
                        console.log(response);
                        if(response.data.my_followed_data.count > 0)
                            seMyFollowedList(response.data.my_followed_data.followed_data);
                        else
                            setLoadingDataText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    const loadMyIgnored = async () => {
        console.log('in loadMyIgnored');
        try {
            let post_params = {};
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = userData.member_id;

            setLoadingDataText(<i class="fa fa-spinner fa-spin"></i>);

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/myIgnoredList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    if(response.data.success)
                    {
                        console.log(response);
                        if(response.data.my_ignoredlist_data.count > 0)
                            seMyIgnoredList(response.data.my_ignoredlist_data.ignoredlist_data);
                        else
                            setLoadingDataText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            console.log('in catch');
            console.log(error);
            setError(error);
        } finally {
            console.log('in finally');
            setIsLoading(false);
        }
    }

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    function unblockIgnore (member_id)
	{
        if (isLoggedIn)
        {
            confirmAlert({
                customUI: ({ onClose }) => {
                        return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_unblock')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('are_you_sure_that_you_want_to_unblock_this_member_from_ignored_list?')}</b></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                            //this.handleClickConfirm();
                            /* saveIgnorelist(user_id ,member_id);
                            setIgnored(true); */
                            setUnIgnored(userData.member_id, member_id);
                            setTimeout(() =>{onClose();},1000);                                    
                          }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                          </div>
                        );
                    }
              }); 
        }
        /* else
		{
			props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
		} */
    }

    function setUnIgnored(user_id ,member_id)
    {
        if (isLoggedIn)
        {
            post_params['user_id'] = user_id;
            post_params['member_id'] = member_id;
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/do_unblock',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    tabIgnoredBtnRef.current.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        /* else
        {
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
        } */
    }

    function confirmRemoveShortlist (member_id)
	{
        if (isLoggedIn)
        {
            confirmAlert({
                customUI: ({ onClose }) => {
                        return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_remove')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('are_you_sure_that_you_want_to_remove_this_member_from_shortlist?')}</b></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                            //this.handleClickConfirm();
                            /* saveIgnorelist(user_id ,member_id);
                            setIgnored(true); */
                            removeShortlist(userData.member_id, member_id);
                            setTimeout(() =>{onClose();},1000);                                    
                          }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                          </div>
                        );
                    }
              }); 
        }
        /* else
		{
			props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
		} */
    }

    function removeShortlist(user_id ,member_id)
    {
        if (isLoggedIn)
        {
            post_params['user_id'] = user_id;
            post_params['member_id'] = member_id;
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/remove_shortlist',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    tabShortlistBtnRef.current.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        /* else
        {
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
        } */
    }

    function gotoExpressInterest (member_id, type)
	{
        if (isLoggedIn)
        {
            COMMONFUNCTIONS.getUsersRemainingInterests(userData.member_id);
            //this.AlertProLoader.open();
            
            setTimeout(
                () =>
                {
                    //props.setSelectedMemberId(member_id);
                    var rem_interests = COMMONFUNCTIONS.getSession('user_interest_count');

                    confirmAlert({
                        customUI: ({ onClose }) => {
                                return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_express_interest')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('you_have_remaining_express_interest') +' '+ rem_interests +' '+ t('times')}</b><br/><br/><span>**N.B. {t('expressing_an_interest_will_cost_1_from_your_remaining_interests')}**</span></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                                    //this.handleClickConfirm();
                                    saveExpressInterest(userData.member_id, member_id, type);
                                    //setIsInterested(true);
                                    setTimeout(() =>{onClose();},1000);                                    
                                  }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                                  </div>
                                );
                            }
                      }); 

                },
                1000
              );
        }
        else
		{
            /* props.setTextLoginModal('Please Log In To Express Interest On This Member.');
            props.modalOpenLoginBtnRef.current.click(); */
            /* COMMONFUNCTIONS.commonMessageInfoToast('Please Log In To Express Interest On This Member.');
            setTimeout(() => { gotoLogin(); }, 2000); */
		}
    }

    function saveExpressInterest(user_id, member_id, type)
    {
        post_params['user_id'] = user_id;
        post_params['member_id'] = member_id;
        post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        let resp_status = false;
    
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_interest',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                if(type == 'shortlist')
                    tabShortlistBtnRef.current.click();
                else if(type == 'followed')
                    tabFollowedBtnRef.current.click();
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
            }
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            /* if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message); */
        });
    }

    function confirmRemoveFollowed (member_id)
	{
        if (isLoggedIn)
        {
            confirmAlert({
                customUI: ({ onClose }) => {
                        return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_unfollow')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('are_you_sure_that_you_want_to_unfollow_this_member?')}</b></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                            //this.handleClickConfirm();
                            /* saveIgnorelist(user_id ,member_id);
                            setIgnored(true); */
                            removeFollowed(userData.member_id, member_id);
                            setTimeout(() =>{onClose();},1000);                                    
                          }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                          </div>
                        );
                    }
              }); 
        }
        /* else
		{
			props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
		} */
    }

    function removeFollowed(user_id ,member_id)
    {
        if (isLoggedIn)
        {
            post_params['user_id'] = user_id;
            post_params['member_id'] = member_id;
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_unfollow',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    tabFollowedBtnRef.current.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        /* else
        {
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
        } */
    }

    function handleInit() {
        console.log("FilePond instance has initialised");
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
        /* fetchUserProfileData(); */
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
            <section>
                <div class="profi-pg profi-ban">
                    <div class="">
                        <div class="">
                            <div class="profile">
                                <div class="pg-pro-big-im">
                                    <div class="s1">
                                        <img src={user_avatar} alt={full_name} loading="lazy" class="pro" />
                                    </div>
                                    <div class="s3 pos-absolute p-b-40 m-b-10">
                                        {/* <FilePond
                                            //ref={(ref) => (this.pond = ref)}
                                            files={profilePic}
                                            allowMultiple={true}
                                            server={{
                                                // fake server to simulate loading a 'local' server file and processing a file
                                                process: (fieldName, file, metadata, load) => {
                                                // simulates uploading a file
                                                setTimeout(() => {
                                                    load(Date.now());
                                                }, 1500);
                                                },
                                                load: (source, load) => {
                                                // simulates loading a file from the server
                                                fetch(source)
                                                    .then((res) => res.blob())
                                                    .then(load);
                                                },
                                            }}
                                            oninit={() => handleInit()}
                                            onupdatefiles={(fileItems) => {
                                                // Set currently active file objects to this.state
                                                selectProfilePic(fileItems.map((fileItem) => fileItem.file));
                                                /* this.setState({
                                                    files: fileItems.map((fileItem) => fileItem.file),
                                                });
                                            }}
                                            ></FilePond> */}
                                            <FilePond
                                                files={profilePic}
                                                onupdatefiles={selectProfilePic}
                                                allowMultiple={true}
                                                acceptedFileTypes={['image/*']} 
                                                maxFiles={1}
                                                //server="/api/main/profile"
                                                server= { {
                                                    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                                        var myHeaders = new Headers();
                                                        myHeaders.append("Authorization", 'Basic bGFnbmFzb2hhbGFfYWRtaW46TGFnbmFATWF0cmltb255LmMwbQ==');

                                                        var formdata = new FormData();
                                                        formdata.append("profile_image", file, file.name);
                                                        formdata.append("para1", "update_image");
                                                        formdata.append("user_id", userData.member_id);
                                                        formdata.append(CONSTANTS.REST_KEY_NAME, CONSTANTS.REST_KEY_VALUE);

                                                        var requestOptions = {
                                                            method: 'POST',
                                                            headers: myHeaders,
                                                            body: formdata,
                                                            redirect: 'follow'
                                                        };

                                                        fetch(CONSTANTS.SERVER_BASE_URL+'/api/main/profile', requestOptions)
                                                        .then(response => response.text())
                                                        .then(result => {
                                                            let responceJson = JSON.parse(result);
                                                            COMMONFUNCTIONS.commonMessageInfoToast(responceJson.message);
                                                            fetchUserProfileData();
                                                            load(file);
                                                        })
                                                        .catch(error => {
                                                            console.log('error', error);
                                                            COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                                                        });

                                                        // fieldName is the name of the input field
                                                        // file is the actual file object to send
                                                        /* console.log(file);
                                                        console.log(file.name);
                                                        let formData = new FormData();
                                                        formData.append(fieldName, file, file.name);
                                                        formData.append('para1', "update_image");
                                                        formData.append('user_id', userData.member_id);
                                                        //formData.append('profile_image', {type: 'image/jpg', uri : image_uri, name : 'uploadAvatarTmp.jpg'});
                                                        formData.append(CONSTANTS.REST_KEY_NAME, CONSTANTS.REST_KEY_VALUE);

                                                        let heads = COMMONFUNCTIONS.getPostHeaders();
                                                        //heads['Content-Type'] = 'multipart/form-data;';
                                                        //heads[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
                                                        //heads['Authorization'] = 'Basic bGFnbmFzb2hhbGFfYWRtaW46TGFnbmFATWF0cmltb255LmMwbQ==';
                                                        //heads['Content-Type'] = 'application/x-www-form-urlencoded';
                                                        //heads['Content-Type'] = 'application/json';

                                                        const requestOptions = {
                                                            method: 'POST',
                                                            headers: heads,//COMMONFUNCTIONS.getPostHeaders(),
                                                            body: formData
                                                        };
                                                        fetch(CONSTANTS.SERVER_BASE_URL+'/api/main/profile', requestOptions)
                                                            .then(response => response.json())
                                                            .then(data => console.log(data.id)); */
                                            
                                                        /* const request = new XMLHttpRequest();
                                                        request.open('POST', '/api/main/profile');
                                            
                                                        // Should call the progress method to update the progress to 100% before calling load
                                                        // Setting computable to false switches the loading indicator to infinite mode
                                                        request.upload.onprogress = (e) => {
                                                            progress(e.lengthComputable, e.loaded, e.total);
                                                        };
                                            
                                                        // Should call the load method when done and pass the returned server file id
                                                        // this server file id is then used later on when reverting or restoring a file
                                                        // so your server knows which file to return without exposing that info to the client
                                                        request.onload = function () {
                                                            if (request.status >= 200 && request.status < 300) {
                                                                // the load method accepts either a string (id) or an object
                                                                load(request.responseText);
                                                            } else {
                                                                // Can call the error method if something is wrong, should exit after
                                                                error('oh no');
                                                            }
                                                        };
                                            
                                                        request.send(formData); */
                                            
                                                        // Should expose an abort method so the request can be cancelled
                                                        return {
                                                            abort: () => {
                                                                // This function is entered if the user has tapped the cancel button
                                                                //request.abort();
                                            
                                                                // Let FilePond know the request has been cancelled
                                                                abort();
                                                            },
                                                        };
                                                    },
                                                } }
                                                name="profile_image"
                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                            />
                                        {/* <a href="#!" class="cta fol cta-chat">Chat now</a>
                                        <span class="cta cta-sendint" data-toggle="modal" data-target="#sendInter">Send
                                            interest</span> */}
                                    </div>
                                    <div className='clear'></div>
                                </div>
                            </div>
                            <div class="profi-pg profi-bio p-l-20">
                                <Tabs>
                                    <TabList>
                                        <Tab>
                                            <a ref={tabProfileBtnRef} class="nav-link p_nav p-l-5 p-r-5 active"><i class="fa fa-user"></i> Profile</a>
                                        </Tab>
                                        <Tab onClick={() => { loadMyInterests(); }}>
                                            <a ref={tabInterestsBtnRef} class="nav-link my_interests p_nav p-l-5 p-r-5" title="My Interests"><i class="fa fa-heart"></i> Interests</a></Tab>
                                        <Tab onClick={() => { loadMyFollowers(); }}>
                                            <a ref={tabFollowersBtnRef} class="nav-link my_followers p_nav p-l-5 p-r-5" title="My Followers"><i class="fa fa-star"></i> Followers</a></Tab>
                                        <Tab onClick={() => { loadMyShortlists(); }}>
                                            <a ref={tabShortlistBtnRef} class="nav-link short_list p_nav p-l-5 p-r-5" title="Shortlist" onclick="profile_load('short_list')"><i class="fa fa-list-ul"></i> Shortlist</a></Tab>
                                        <Tab onClick={() => { loadMyFollowed(); }}>
                                            <a ref={tabFollowedBtnRef} class="nav-link followed_users p_nav p-l-5 p-r-5" title="Followed Users" onclick="profile_load('followed_users')"><i class="fa fa-star"></i> Followed</a></Tab>
                                        <Tab onClick={() => { loadMyIgnored(); }}>
                                            <a ref={tabIgnoredBtnRef} class="nav-link ignored_list p_nav p-l-5 p-r-5" title="Ignored List" onclick="profile_load('ignored_list')"><i class="fa fa-ban"></i> Ignored</a></Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div class="lhs m-t-20">
                                            <div class="pro-pg-intro">
                                                <h1>{full_name} ({ userData.member_profile_id })</h1>
                                                <div class="pro-info-status">
                                                    <span class="stat-1"><b>100</b> viewers</span>
                                                    <span class="stat-2"><b>Available</b> online</span>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div style={{ width: 95, height: 135 }} title='PROFILE COMPLETED'>
                                                            {/* <CircularProgressbar value={66} /> */}
                                                            <CircularProgressbar
                                                                value={userData.profile_completion_percentage}
                                                                text={`${userData.profile_completion_percentage}%`}
                                                                styles={buildStyles({
                                                                    // This is in units relative to the 100x100px
                                                                    // SVG viewbox.
                                                                    //textSize: "14px"
                                                                    //textColor: "red",
                                                                    pathColor: "#e7015e",
                                                                    //trailColor: "gold"
                                                                })}
                                                            />
                                                            {/* <span>Profile Completed</span> */}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                            <span>City: <strong>{ userPermanentAddressData.permanent_city_title }</strong></span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={"/assets/images/icon/pro-age.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                            <span>Age: <strong>{ userData.age }</strong></span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                            <span>Height: <strong>{ userData.height }</strong></span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                            <span>Job: <strong>{ userEducationCareerData.occupation_title }</strong></span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* PROFILE ABOUT */}

                                            {/* Personal Information */}
                                                <PersonalInformation />
                                            {/* END Personal Information */}

                                            {/* PROFILE ABOUT */}
                                            <div class="pr-bio-c pr-bio-conta">
                                                <h3>Contact info</h3>
                                                <ul>
                                                    <li><span><i class="fa fa-mobile"
                                                                aria-hidden="true"></i><b>Phone:</b>{ userData.mobile }</span></li>
                                                    <li><span><i class="fa fa-envelope-o"
                                                                aria-hidden="true"></i><b>Email:</b>{ userData.email }</span>
                                                    </li>
                                                    <li><span><i class="fa fa fa-map-marker" aria-hidden="true"></i><b>Address: </b>{ userPermanentAddressData.permanent_city_title+', '+userPermanentAddressData.permanent_state_title+', '+userPermanentAddressData.permanent_country_title }</span></li>
                                                </ul>
                                            </div>
                                            {/* END PROFILE ABOUT */}

                                            {/* END PROFILE ABOUT */}
                                            {/* PROFILE ABOUT */}
                                            <div class="pr-bio-c pr-bio-gal" id="gallery">
                                                <h3>Photo gallery</h3>
                                                <div id="image-gallery">
                                                { (userGalleryData != null) && userGalleryData.map(gallery_item => (
                                                    <div class="pro-gal-imag">
                                                        <div class="img-wrapper">
                                                            <a href="#!"><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_GALLERY_DIR_PATH+gallery_item.image} class="img-responsive" alt="" /></a>
                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                    {/* <div class="pro-gal-imag">
                                                        <div class="img-wrapper">
                                                            <a href="#!"><img src={"/assets/images/profiles/1.jpg" class="img-responsive" alt="" /></a>
                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pro-gal-imag">
                                                        <div class="img-wrapper">
                                                            <a href="#!"><img src={"/assets/images/profiles/6.jpg" class="img-responsive" alt="" /></a>
                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pro-gal-imag">
                                                        <div class="img-wrapper">
                                                            <a href="#!"><img src={"/assets/images/profiles/14.jpg" class="img-responsive" alt="" /></a>
                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* END PROFILE ABOUT */}

                                            <Introduction userData={userData} fetchUserProfileData={fetchUserProfileData} />

                                            {/* Basic Information */}
                                                <BasicInformation userData={userData} fetchUserProfileData={fetchUserProfileData} />
                                            {/* END Basic Information */}

                                            {/* Present Address */}
                                                { (userProfileSettings.present_address == 'yes') && <PresentAddress fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Present Address */}

                                            {/* Education And Career */}
                                                { (userProfileSettings.education_and_career == 'yes') && <EducationCareer fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Education And Career */}

                                            {/* Physical Attributes */}
                                                { (userProfileSettings.physical_attributes == 'yes') && <PhysicalAttributes fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Physical Attributes */}

                                            {/* Language */}
                                                { (userProfileSettings.language == 'yes') && <Language fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Language */}

                                            {/* Hobby */}
                                                { (userProfileSettings.hobbies_and_interests == 'yes') && <YourLikes fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Hobby */}

                                            {/* Attitude And Behavior */}
                                                { (userProfileSettings.personal_attitude_and_behavior == 'yes') && <PersonalAttitudeBehavior fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Hobby */}

                                            {/* Residency Information */}
                                                { (userProfileSettings.residency_information == 'yes') && <ResidencyInformation  fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Residency Information */}

                                            {/* Spiritual And Social Background */}
                                                { (userProfileSettings.spiritual_and_social_background == 'yes') && <SpiritualSocialBackground fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Spiritual And Social Background */}

                                            {/* Life Style */}
                                                { (userProfileSettings.life_style == 'yes') && <LifeStyle fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Life Style */}

                                            {/* Astronomic Information */}
                                                { (userProfileSettings.astronomic_information == 'yes') && <AstronomicInformation fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Astronomic Information */}

                                            {/* Permanent Address */}
                                                { (userProfileSettings.permanent_address == 'yes') && <PermanentAddress fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Permanent Address */}

                                            {/* Family Information */}
                                                { (userProfileSettings.family_information == 'yes') && <FamilyInfo fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Family Information */}

                                            {/* Additional Personal Details */}
                                                { (userProfileSettings.additional_personal_details == 'yes') && <AdditionalPersonalDetails fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Additional Personal Details */}

                                            {/* Partner Expectation */}
                                                { (userProfileSettings.partner_expectation == 'yes') && <PartnerExpectation fetchUserProfileData={fetchUserProfileData} /> }
                                            {/* END Partner Expectation */}

                                            {/* PROFILE ABOUT 
                                            <div class="pr-bio-c pr-bio-hob">
                                                <h3>Hobbies</h3>
                                                <ul>
                                                    <li><span>Modelling</span></li>
                                                    <li><span>Watching movies</span></li>
                                                    <li><span>Playing volleyball</span></li>
                                                    <li><span>Hangout with family</span></li>
                                                    <li><span>Adventure travel</span></li>
                                                    <li><span>Books reading</span></li>
                                                    <li><span>Music</span></li>
                                                    <li><span>Cooking</span></li>
                                                    <li><span>Yoga</span></li>
                                                </ul>
                                            </div>*/}
                                            {/* END PROFILE ABOUT */}
                                            {/* PROFILE ABOUT 
                                            <div class="pr-bio-c menu-pop-soci pr-bio-soc">
                                                <h3>Social media</h3>
                                                <ul>
                                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                    <li><a href="#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                                </ul>
                                            </div>*/}
                                            {/* END PROFILE ABOUT */}
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div class="card m-t-40">
                                            <div class="card-header">
                                                <h3 class="card-title">My Interests</h3>
                                            </div>
                                            <div class="card-body p-0">
                                                <table class="table table-responsive-lg">
                                                    {/* <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Religion</th>
                                                            <th>Location</th>
                                                            <th>Mother Tongue</th>
                                                            <th style={{width: 40+'px'}}>Label</th>
                                                        </tr>
                                                    </thead> */}
                                                        { (myInterestsList != null) && <tbody>
                                                        { myInterestsList.map((interest_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+interest_item.profile_image.thumb+'?r='+Math. random()} alt={full_name} width="50px" /></td>
                                                                <td>{interest_item.first_name+' '+interest_item.last_name}</td>
                                                                <td>{interest_item.religion_title}</td>
                                                                <td>{interest_item.country_title}</td>
                                                                <td>{interest_item.mother_tongue_title}</td>
                                                                <td>
                                                                    { (interest_item.status == 'pending') && <span class="badge bg-secondary white-bold-day p-2" title={COMMONFUNCTIONS.camelCase(interest_item.status)}>{COMMONFUNCTIONS.camelCase(interest_item.status)}</span> }
                                                                    { (interest_item.status == 'accepted') && <span class="badge bg-success white-bold-day p-2" title={COMMONFUNCTIONS.camelCase(interest_item.status)}>{COMMONFUNCTIONS.camelCase(interest_item.status)}</span> }
                                                                    { (interest_item.status == 'rejected') && <span class="badge bg-danger white-bold-day p-2" title={COMMONFUNCTIONS.camelCase(interest_item.status)}>{COMMONFUNCTIONS.camelCase(interest_item.status)}</span> }              
                                                                </td>
                                                            </tr>
                                                        ))}</tbody> }
                                                        { (myInterestsList.length < 1) && <tbody className='d-block'><tr className='d-contents'>
                                                                <td className='d-block' colSpan={7}><center className='header'>{loadingDataText}</center></td>
                                                            </tr></tbody> }
                                                    
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div class="card m-t-40">
                                            <div class="card-header">
                                                <h3 class="card-title">My Followers</h3>
                                            </div>
                                            <div class="card-body p-0">
                                                <table class="table table-responsive-lg">
                                                    {/* <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Religion</th>
                                                            <th>Location</th>
                                                            <th>Mother Tongue</th>
                                                            <th style={{width: 40+'px'}}>Label</th>
                                                        </tr>
                                                    </thead> */}
                                                        { (myFollowersList != null) && <tbody>
                                                        {  myFollowersList.map((follower_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+follower_item.profile_image.thumb+'?r='+Math. random()} alt={full_name} width="50px" /></td>
                                                                <td>{follower_item.first_name+' '+follower_item.last_name}</td>
                                                                <td>{follower_item.religion_title}</td>
                                                                <td>{follower_item.country_title}</td>
                                                                <td>{follower_item.mother_tongue_title}</td>
                                                                {/* <td><span class="badge bg-danger">55%</span></td> */}
                                                            </tr>
                                                        ))}</tbody> }
                                                        { (myFollowersList.length < 1) && <tbody className='d-block'><tr className='d-contents'>
                                                                <td className='d-block' colSpan={7}><center className='header'>{loadingDataText}</center></td>
                                                            </tr></tbody> }
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div class="card m-t-40">
                                            <div class="card-header">
                                                <h3 class="card-title">Shortlists</h3>
                                            </div>
                                            <div class="card-body p-0">
                                                <table class="table table-responsive-lg">
                                                    {/* <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Religion</th>
                                                            <th>Location</th>
                                                            <th>Mother Tongue</th>
                                                            <th style={{width: 40+'px'}}>Label</th>
                                                        </tr>
                                                    </thead> */}
                                                        { (myShortlistsList != null) && <tbody>
                                                        { myShortlistsList.map((shortlist_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+shortlist_item.profile_image.thumb+'?r='+Math. random()} alt={full_name} width="50px" /></td>
                                                                <td>{shortlist_item.first_name+' '+shortlist_item.last_name}</td>
                                                                <td>{shortlist_item.religion_title}</td>
                                                                <td>{shortlist_item.country_title}</td>
                                                                <td>{shortlist_item.mother_tongue_title}</td>
                                                                <td>
                                                                    { 
                                                                        (!shortlist_item.is_already_interested) && <span onClick={()=> {gotoExpressInterest(shortlist_item.member_id, 'shortlist');}} class="badge bg-success white-bold-day p-2 m-r-5 m-b-5 cursor-pointer" title={t('express_interest')}><i class="fa fa-heart"></i></span>
                                                                    }
                                                                        
                                                                    {
                                                                        (shortlist_item.is_already_interested) && <span onClick={()=> {/* unblockIgnore(ignored_item.member_id); */}} class="badge bg-secondary white-bold-day p-2 m-r-5 m-b-5" title={t('interest_expressed')}><i class="fa fa-heart"></i></span>
                                                                    }

                                                                    <span onClick={()=> {confirmRemoveShortlist(shortlist_item.member_id);}} class="badge bg-danger white-bold-day p-2 cursor-pointer" title={t('remove')}><i class="fa fa-close"></i></span>
                                                                </td>
                                                            </tr>
                                                        ))}</tbody> }
                                                        { (myShortlistsList.length < 1) && <tbody className='d-block'><tr className='d-contents'>
                                                                <td className='d-block' colSpan={7}><center className='header'>{loadingDataText}</center></td>
                                                            </tr></tbody> }
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div class="card m-t-40">
                                            <div class="card-header">
                                                <h3 class="card-title">Followed Users</h3>
                                            </div>
                                            <div class="card-body p-0">
                                                <table class="table table-responsive-lg">
                                                    {/* <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Religion</th>
                                                            <th>Location</th>
                                                            <th>Mother Tongue</th>
                                                            <th style={{width: 40+'px'}}>Label</th>
                                                        </tr>
                                                    </thead> */}
                                                        { (myFollowedList != null) && <tbody>
                                                        { myFollowedList.map((followed_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+followed_item.profile_image.thumb+'?r='+Math. random()} alt={full_name} width="50px" /></td>
                                                                <td>{followed_item.first_name+' '+followed_item.last_name}</td>
                                                                <td>{followed_item.religion_title}</td>
                                                                <td>{followed_item.country_title}</td>
                                                                <td>{followed_item.mother_tongue_title}</td>
                                                                <td>
                                                                    { 
                                                                        (!followed_item.is_already_interested) && <span onClick={()=> {gotoExpressInterest(followed_item.member_id, 'followed');}} class="badge bg-success white-bold-day p-2 m-r-5 m-b-5 cursor-pointer" title={t('express_interest')}><i class="fa fa-heart"></i></span>
                                                                    }
                                                                        
                                                                    {
                                                                        (followed_item.is_already_interested) && <span class="badge bg-secondary white-bold-day p-2 m-r-5 m-b-5" title={t('interest_expressed')}><i class="fa fa-heart"></i></span>
                                                                    }

                                                                    <span onClick={()=> {confirmRemoveFollowed(followed_item.member_id);}} class="badge bg-danger white-bold-day p-2 cursor-pointer" title={t('remove')}><i class="fa fa-close"></i></span>
                                                                </td>
                                                            </tr>
                                                        ))}</tbody> }
                                                        { (myFollowedList.length < 1) && <tbody className='d-block'><tr className='d-contents'>
                                                                <td className='d-block' colSpan={7}><center className='header'>{loadingDataText}</center></td>
                                                            </tr></tbody> }
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div class="card m-t-40">
                                            <div class="card-header">
                                                <h3 class="card-title">Ignored List</h3>
                                            </div>
                                            <div class="card-body p-0">
                                                <table class="table table-responsive-lg">
                                                    {/* <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Religion</th>
                                                            <th>Location</th>
                                                            <th>Mother Tongue</th>
                                                            <th style={{width: 40+'px'}}>Label</th>
                                                        </tr>
                                                    </thead> */}
                                                        { (myIgnoredList != null) && <tbody>
                                                        { myIgnoredList.map((ignored_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td><img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+ignored_item.profile_image.thumb+'?r='+Math. random()} alt={full_name} width="50px" /></td>
                                                                <td>{ignored_item.first_name+' '+ignored_item.last_name}</td>
                                                                <td>{ignored_item.religion_title}</td>
                                                                <td>{ignored_item.country_title}</td>
                                                                <td>{ignored_item.mother_tongue_title}</td>
                                                                <td><span onClick={()=> {unblockIgnore(ignored_item.member_id);}} class="badge bg-success white-bold-day p-2 cursor-pointer" title={t('unblock')}><i class="fa fa-check"></i></span></td>
                                                            </tr>
                                                        ))}</tbody> }
                                                        { (myIgnoredList.length < 1) && <tbody className='d-block'><tr className='d-contents'>
                                                                <td className='d-block' colSpan={7}><center className='header'>{loadingDataText}</center></td>
                                                            </tr></tbody> }
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END PROFILE */}
            <Footer/>
        </div>
    )
}

export default MemberProfile