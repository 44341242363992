import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function AdditionalPersonalDetails(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [country_data] = useState(common_data.common_data.country_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userAdditionalPersonalData, setUserAdditionalPersonalData] = useState(userData.additional_personal_details);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const AdditionalPersonalDetailsSchema = Yup.object({
        /* country: Yup.string().required('Required'),
        state: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_additional_personal_details";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserAdditionalPersonalData(user_data.additional_personal_details);
    }

    return (
        <div>
            {
                (userAdditionalPersonalData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_additional_personal_details">{t('additional_personal_details')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_additional_personal_details" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>Home District:</b> <span title={ userAdditionalPersonalData.home_district }>{ userAdditionalPersonalData.home_district }</span></li>

                        <li><b>Family Residence:</b> <span title={ userAdditionalPersonalData.family_residence }>{ userAdditionalPersonalData.family_residence }</span></li>

                        <li><b>Father's Occupation:</b> <span title={ userAdditionalPersonalData.fathers_occupation }>{ userAdditionalPersonalData.fathers_occupation }</span></li>

                        <li><b>Special Circumstances:</b> <span title={ userAdditionalPersonalData.special_circumstances }>{ userAdditionalPersonalData.special_circumstances }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_additional_personal_details">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  home_district: userAdditionalPersonalData.home_district, family_residence: userAdditionalPersonalData.family_residence, fathers_occupation: userAdditionalPersonalData.fathers_occupation, special_circumstances: userAdditionalPersonalData.special_circumstances }}
                        validationSchema={AdditionalPersonalDetailsSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('additional_personal_details')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('home_district')}:</label>
                                            <input type="text" id="home_district" class="form-control" placeholder={t('enter_home_district')} name="home_district" onChange={formik.handleChange} value={formik.values.home_district} />
                                            {formik.errors.home_district ? <div className='m-t-10 text-left error'>{formik.errors.home_district}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('family_residence')}:</label>
                                            <input type="text" id="family_residence" class="form-control" placeholder={t('enter_family_residence')} name="family_residence" onChange={formik.handleChange} value={formik.values.family_residence} />
                                            {formik.errors.family_residence ? <div className='m-t-10 text-left error'>{formik.errors.family_residence}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('fathers_occupation')}:</label>
                                            <input type="text" id="fathers_occupation" class="form-control" placeholder={t('enter_fathers_occupation')} name="fathers_occupation" onChange={formik.handleChange} value={formik.values.fathers_occupation} />
                                            {formik.errors.fathers_occupation ? <div className='m-t-10 text-left error'>{formik.errors.fathers_occupation}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('special_circumstances')}: </label>
                                            <input type="text" id="special_circumstances" class="form-control" placeholder={t('enter_special_circumstances')} name="special_circumstances" onChange={formik.handleChange} value={formik.values.special_circumstances} />
                                            {formik.errors.special_circumstances ? <div className='m-t-10 text-left error'>{formik.errors.special_circumstances}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AdditionalPersonalDetails