import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import SubmitLoader from '../../components/common/submit_loader';

let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;

function ChangePassword()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoggedIn, checkLoginStatus] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));

    const SignupSchema = Yup.object({
        current_password: Yup.string().required('Required'),
        new_password: Yup.string().required('Required'),
        //confirm_password: Yup.string().required('Required'),
        confirm_password: Yup.string().label(t('confirm_password')).required('Required').oneOf([Yup.ref('new_password'), null], 'New Password & Confirm Password must match.'),

        //confirm_password: Yup.string().label('confirm password').required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    //const SubmitForm = (values, { setSubmitting }) => {
    function SubmitForm (values) {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        values['para1'] = 'update_password';
        values['user_id'] = user_id;
        console.log(values);
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageSuccessToast("You are about to be logged out automatically");
                }, 2000);
                setTimeout(() => {
                    COMMONFUNCTIONS.GoToPage(navigate, '/logout.html');
                }, 5000);
            }
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);

            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
        //setSubmitting(false);
    }

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
                <section>
                    <div class="profi-pg profi-ban">
                        <div class="">
                            <div class="">
                                <div class="profile">
                                    <div class="pg-pro-big-im">
                                        <div class="s1">
                                            {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                            { getProfileImage(userData.profile_image) }
                                        </div>
                                    </div>
                                </div>
                                <div class="profi-pg profi-bio">
                                    <div class="lhs">
                                        <div class="pro-pg-intro">
                                            <h1 className='header'>{t('change_password')}<Link className="float-right" to="/my-profile.html"><i class="fa fa-times" aria-hidden="true"></i></Link></h1>
                                        </div>
                                    </div>

                                    {/* PROFILE lHS */}
                                    <div class="rhs">
                                        <div className='row'>
                                            <div className='col-md-11 m-t-20 p-20 gray-border'>
                                                <Formik
                                                    initialValues={{ current_password: '', new_password: '', confirm_password: '' }}
                                                    validationSchema={SignupSchema}
                                                    //onSubmit={SubmitForm}
                                                    onSubmit={async (values, { resetForm }) => {
                                                        SubmitForm(values);
                                                        resetForm();
                                                    }}
                                                    >
                                                {formik => (
                                                    <form onSubmit={formik.handleSubmit} className='w-95'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('current_password')}:</label>
                                                            <input type="password" class="form-control" id="current_password" placeholder={t('enter_current_password')} name="current_password" value={formik.values.current_password} onChange={formik.handleChange} autoComplete="off"/>
                                                            {formik.errors.current_password ? <div className='m-t-10 text-left error'>{formik.errors.current_password}</div> : null}
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="lb">{t('new_password')}:</label>
                                                            <input type="password" class="form-control" id="new_password" placeholder={t('enter_new_password')} name="new_password" value={formik.values.new_password} onChange={formik.handleChange} autoComplete="off"/>
                                                            {formik.errors.new_password ? <div className='m-t-10 text-left error'>{formik.errors.new_password}</div> : null}
                                                        </div>

                                                        <div class="form-group">
                                                            <label class="lb">{t('confirm_password')}:</label>
                                                            <input type="password" class="form-control" id="confirm_password" placeholder={t('enter_confirm_password')} name="confirm_password" value={formik.values.confirm_password} onChange={formik.handleChange} autoComplete="off"/>
                                                            {formik.errors.confirm_password ? <div className='m-t-10 text-left error'>{formik.errors.confirm_password}</div> : null}
                                                        </div>
                                                        <div className='text-right'>
                                                            <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                                        </div>
                                                        
                                                    </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END PROFILE lHS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END PROFILE */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default ChangePassword