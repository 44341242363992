import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation, Link} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import LeftSearch from '../../components/common/left_search';
import Footer from '../../components/common/footer';
import Loader from '../../components/pages/loader';

import ProfileActionPanelModal from '../../components/common/profile_action_panel_modal';

import MemberItems from './member_items';

let home_search_params = {};
let temp_search_params = {};
let final_search_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;
let page = 1;
let rcount = null;

let values = {};
 
const refresh = (setItems) => {};

export default function Members(props)
{
    const {t} = useTranslation();
    const [items, setItems] = React.useState([]);
    const navigate = useNavigate();

    const passed_params = useLocation();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    const [selectedMemberType, setSelectedMemberType] = useState(props.member_type);

    const [member_type, setMemberType] = useState(props.member_type);

    const [userData, setUserData] = useState({});
    const [userBasicInfoData, setUserBasicInfoData] = useState({});
    const [userPermanentAddressData, setUserPermanentAddressData] = useState({});
    const [userEducationCareerData, setUserEducationCareerData] = useState({});
    const [userSpiritualData, setUserSpiritualData] = useState({});
    const [userPhysicalData, setUserPhysicalData] = useState({});
    const [userLanguageData, setUserLanguageData] = useState({});
    
    const [isInterested, setInterested] = useState();
    const [isShortlisted, setShortlisted] = useState();
    const [isFollowed, setFollowed] = useState();
    const [isIgnored, setIgnored] = useState();
    const [isReported, setReported] = useState();

    const [isMemberSelected, setMemberSelected] = useState(false);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    

    //console.log(passed_params);
    if(passed_params.state !== null)
    {
        home_search_params = passed_params.state.search_params;
        temp_search_params = passed_params.state.search_params;
    }
        

    const [hasMore, setHasMore] = useState(true);
    //const [search_params, setSearchParams] = useState({});
    const [mobFilterShow, setMobFilterShow] = useState('');
    const [listingViewClass, setListingViewClass] = useState('all-list-sh');
    const [gridIconClassActive, setGridIconClass] = useState('');
    const [listIconClassActive, setListIconClass] = useState('act');

    const [textLoginModal, setTextLoginModal] = useState();
    const [contentExpressModal, setContentExpressModal] = useState();
    const [expressModalHeaderText, setExpressModalHeaderText] = useState(t('confirm'));

    const modalOpenLoginBtnRef = useRef(null);
    const modalCloseLoginBtnRef = useRef(null);
    const modalOpenExpressBtnRef = useRef(null);
    const modalCloseExpressBtnRef = useRef(null);

    const modalOpenMemberProfileBtnRef = useRef(null);
    const modalCloseMemberProfileBtnRef = useRef(null);

    const changeFilterGender = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['gender'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterAge = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['age_range'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMemberId = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['member_id'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMaritalStatus = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['marital_status'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterReligion = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['religion'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterCaste = (event) => {
        //recordsListPanel = true;
        console.log(event);

        let selectedCastes = [];
        if(event.length > 0)
        {
            for (let index = 0; index < event.length; index++) {
                selectedCastes.push(event[index].value);
            }
        }

        temp_search_params['caste'] = selectedCastes.join();
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterLanguage = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['language'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterProfession = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['profession'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterEducation = (event) => {
        temp_search_params['highest_education'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterCountry = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['country'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterState = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['state'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterCity = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['city'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMemberType = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        setMemberType(null);
        temp_search_params['search_member_type'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[]);
        setSelectedMemberType(event.target.value);
    }

    const fetchUserProfileData = async (member_id) => {
        try {
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            values['member_id'] = member_id;
            values['user_id'] = user_id;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/member_profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    //console.log(response);
                    if(response.data.success)
                    {
                        console.log(response);
                        setMemberSelected(true);
                        setAllUsersData(response.data.member_data);
                        /* if(Object.keys(userData).length < 1)
                        {
                            setAllUsersData(response.data.member_data);
                        } */
                        //console.log(userData);
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = (setItems, items) => {

        final_search_params = temp_search_params;
        final_search_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        final_search_params['count'] = rcount;
        final_search_params['method'] = 'search';
        //final_search_params['search_member_type'] = 'all';
        if(member_type !== null)
        {
            //console.log(member_type);
            final_search_params['search_member_type'] = member_type;
        }

        if(isLoggedIn)
        {
            final_search_params['user_id'] = user_id;
        }
        else if(page > 1)
        {
            setTextLoginModal('Please Log In To View More Profiles.');
            modalOpenLoginBtnRef.current.click();
            setHasMore(false);
            return false;
        }

        //console.log(final_search_params);
        /* if(Object.keys(params_to_search).length > 0)
        {
            final_search_params = params_to_search;
        } */

        /* alert(Object.keys(params_to_search).length);
        alert(Object.keys(temp_search_params).length);
        alert(Object.keys(search_params).length); */

        if(typeof final_search_params.age_range !== 'undefined' && final_search_params.age_range !== '' && final_search_params.age_range !== null)
        {
            //console.log('age_range::'+final_search_params.age_range);

            let age_array = final_search_params.age_range.split('-');

            //console.log(age_array);

            final_search_params['aged_from'] = age_array[0];
            final_search_params['aged_to'] = age_array[1];
        }

          //axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=10`)
          axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/ajax_member_list',final_search_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
         .then((res) => {
          //console.log(res);
          if(res.data.count < 10)
          {
              //setIsLoading(false);
              setHasMore(false);
              if(res.data.count < 1)
                COMMONFUNCTIONS.commonMessageErrorToast('No more data to load.');
          }
          setItems([...items, ...res.data.records]);
          page = page + 1;
          rcount = rcount + 10;
          //console.log(rcount);
         });
    };

    function setAllUsersData (user_data)
    {
        console.log(user_data);
        setUserData(user_data);
        setUserBasicInfoData(user_data.basic_info);
        setUserPermanentAddressData(user_data.permanent_address);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
        setUserPhysicalData(user_data.physical_attributes);
        setUserLanguageData(user_data.language);

        setInterested(user_data.is_interested);
        setShortlisted(user_data.is_shortlisted);
        setFollowed(user_data.is_followed);
        setIgnored(user_data.is_ignored);
        setReported(user_data.is_reported);
    }

    function updateExpressInterest(member_id)
    {
        if (isLoggedIn)
        {
            let act_status = saveExpressInterest(user_id ,member_id);

            //modalCloseExpressBtnRef.current.click();
        }
		else
		{
			setTextLoginModal('Please Log In To Express Interest On This Member.');
            modalOpenLoginBtnRef.current.click();
		}
    }

    function saveExpressInterest(user_id, member_id)
    {
        values['user_id'] = user_id;
        values['member_id'] = member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        let resp_status = false;
    
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_interest',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            //console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                resp_status = true;
                //setStates(response.data.state_data);
                setInterested(true);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                resp_status = false;
                setInterested(false);
            }
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            /* if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message); */
        });
    }

    function gotoUserProfile(member_id)
    {
        //console.log(member_id);
        navigate("/profile-details.html", { state: {member_id : member_id}, replace: true });
    }

    function openMemberProfileModal(member_id)
    {
        console.log(member_id);
        fetchUserProfileData(member_id);
        modalOpenMemberProfileBtnRef.current.click();
    }

    function changeRecordLayout(type)
    {
        if(type == 'grid') {
            setGridIconClass('act');
            setListIconClass('');
            setListingViewClass('all-list-sh view-grid');
        }
        else{
            setGridIconClass('');
            setListIconClass('act');
            setListingViewClass('all-list-sh');
        }
    }

    function getProfileImage(img_obj)
    {
        //console.log(img_obj);
        let profile_image = "/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD;
        if(img_obj !== undefined)
        {
            //console.log(img_obj);
            let image_json_data = JSON.parse(img_obj);

            if(image_json_data[0].profile_image.length > 1)
                image_name = image_json_data[0].profile_image;

            profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);
        }

        /* let profile_image = "/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD;

        if(img_obj.profile_image !== undefined)
            profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, img_obj.profile_image); */

        return <img src={profile_image} alt="" />;
    }

    function getMemberImage(userData)
    {
        //console.log(userData);
        //console.log(userData.profile_image);
        let profile_image = '';
        if(Object.keys(userData).length !== 0)
        {
            //console.log(userData.profile_image.profile_image);
            if(userData.profile_image.profile_image.length > 1)
            {
                image_name = userData.profile_image.profile_image;
                profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);
            }
            else
            {
                if(userData.gender_id == '2')
                    profile_image = 'http://localhost/projects/lagnasohala/matrimony_2/uploads/profile_image/default_w.png';
                else
                    profile_image = 'http://localhost/projects/lagnasohala/matrimony_2/uploads/profile_image/default_m.png';
            }
        }
        else
        {
            if(userData.gender_id == '2')
                profile_image = 'http://localhost/projects/lagnasohala/matrimony_2/uploads/profile_image/default_w.png';
            else
                profile_image = 'http://localhost/projects/lagnasohala/matrimony_2/uploads/profile_image/default_m.png';
        }
        
        console.log(profile_image);

        return <img src={profile_image} alt={ COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) } title={ COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) } />;
    }

    function gotoLogin()
    {
        COMMONFUNCTIONS.moveToTop();
        navigate("/login.html");
    }

    function gotoShortlist (member_id)
	{
        if (isLoggedIn)
        {
            /* var remainingInterest = COMMONFUNCTIONS.getUsersRemainingInterests(user_id);
            this.AlertProLoader.open();
            
            setTimeout(
                () =>
                {
                    console.log(remainingInterest._55);
                    this.setState({
                        remaining_interest_count : remainingInterest._55,
                        alert_message : "Remaining Express Interest(s): "+remainingInterest._55,
                    });
                    //alert_message = "Remaining Express Interest(s): "+this.state.remaining_interest_count;
                    this.AlertPro.open();
                    this.AlertProLoader.close();
                },
                3000
              ); */
        }
        else
		{
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
		}
    }

    function saveShortlist (user_id, member_id)
    {
        //console.log(user_id+'==='+member_id+'==='+selected_member_id);
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_shortlist',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setShortlisted(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setShortlisted(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }
    
    function removeShortlist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/remove_shortlist',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setShortlisted(false);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setShortlisted(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Remove This Member From Shortlist.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function saveFollowlist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_follow',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setFollowed(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setFollowed(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Follow This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }
    
    function addUnfollowList (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_unfollow',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setFollowed(false);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setFollowed(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Un-Follow This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function saveIgnorelist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_ignore',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    isIgnored(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    isIgnored(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function gotoExpressInterest (member_id)
	{
        if (isLoggedIn)
        {
            COMMONFUNCTIONS.getUsersRemainingInterests(user_id);
            //this.AlertProLoader.open();
            
            setTimeout(
                () =>
                {
                    props.setSelectedMemberId(member_id);
                    var rem_interests = COMMONFUNCTIONS.getSession('user_interest_count');
                    /* //console.log(COMMONFUNCTIONS.getSession('user_interest_count'));
                    props.setExpressModalHeaderText(t('confirm_express_interest'));
                    props.setContentExpressModal(<p class='text-center'><b>{t('you_have_remaining_express_interest') +' '+ rem_interests +' '+ t('times')}</b><br/><br/><span>**N.B. {t('expressing_an_interest_will_cost_1_from_your_remaining_interests')}**</span></p>)

                    props.modalOpenExpressBtnRef.current.click();
                    setInterested(true); */

                    confirmAlert({
                        customUI: ({ onClose }) => {
                                return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_express_interest')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('you_have_remaining_express_interest') +' '+ rem_interests +' '+ t('times')}</b><br/><br/><span>**N.B. {t('expressing_an_interest_will_cost_1_from_your_remaining_interests')}**</span></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                                    //this.handleClickConfirm();
                                    props.updateExpressInterest(member_id);
                                    setInterested(true);
                                    setTimeout(() =>{onClose();},1000);                                    
                                  }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                                  </div>
                                );
                            }
                      }); 

                },
                1000
              );
        }
        else
		{
            props.setTextLoginModal('Please Log In To Express Interest On This Member.');
            props.modalOpenLoginBtnRef.current.click();
            /* COMMONFUNCTIONS.commonMessageInfoToast('Please Log In To Express Interest On This Member.');
            setTimeout(() => { gotoLogin(); }, 2000); */
		}
    }

    function gotoIgnore (member_id)
	{
        if (isLoggedIn)
        {
            confirmAlert({
                customUI: ({ onClose }) => {
                        return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_ignore')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('are_you_sure_that_you_want_to_ignore_this_member?')}</b></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                            //this.handleClickConfirm();
                            saveIgnorelist(user_id ,member_id);
                            setIgnored(true);
                            setTimeout(() =>{onClose();},1000);                                    
                          }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                          </div>
                        );
                    }
              }); 
        }
        else
		{
			props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
		}
    }
    
    React.useEffect(()=>{
        fetchData(setItems,items)
    },[])

    return (
        <>
            <Top/>
            <Header/>
            {/* SUB-HEADING */}
                <section>
                    <div class="all-pro-head">
                        <div class="container">
                            <div class="row">
                                <h1>Lakhs of Happy Marriages</h1>
                                <a href="sign-up.html">Join now for Free <i class="fa fa-handshake-o" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                    {/* FILTER ON MOBILE VIEW */}
                    <div class="fil-mob fil-mob-act" id="filter_search_btn" onClick={() => { setMobFilterShow('block'); }}>
                        <h4>Profile filters <i class="fa fa-filter" aria-hidden="true"></i> </h4>
                    </div>
                </section>
                {/* END */}

                {/* START */}
                <section>
                    <div class="all-weddpro all-jobs all-serexp">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-3 form-select fil-mob-view" id="filter_search_panel" style={{display:mobFilterShow}}>
                                    <LeftSearch search_params={home_search_params} changeFilterGenderFunction={changeFilterGender} changeFilterAgeFunction={changeFilterAge} changeFilterMemberIdFunction={changeFilterMemberId} changeFilterMaritalStatusFunction={changeFilterMaritalStatus} changeFilterReligionFunction={changeFilterReligion} changeFilterCasteFunction={changeFilterCaste} changeFilterProfessionFunction={changeFilterProfession} changeFilterEducationFunction={changeFilterEducation} changeFilterCountryFunction={changeFilterCountry} changeFilterStateFunction={changeFilterState} changeFilterCityFunction={changeFilterCity} changeFilterMemberTypeFunction={changeFilterMemberType} changeFilterLanguageFunction={changeFilterLanguage} fetchData={fetchData} setMobFilterShow={setMobFilterShow} member_type={member_type} selectedMemberType={selectedMemberType} />
                                </div>
                                <div class="col-md-9">
                                    <div class="short-all">
                                        <div class="short-lhs">
                                            {/* Showing <b>32</b> profiles */}
                                        </div>
                                        <div class="short-rhs">
                                            <ul>
                                                <li>
                                                    Sort by:
                                                </li>
                                                <li>
                                                    <div class="form-group">
                                                        <select class="chosen-select">
                                                            <option value="">Most relative</option>
                                                            <option value="Men">Date listed: Newest</option>
                                                            <option value="Men">Date listed: Oldest</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class= {"sort-grid sort-grid-1 "+gridIconClassActive} onClick={() => { changeRecordLayout('grid'); }}>
                                                        <i class="fa fa-th-large" aria-hidden="true"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class={"sort-grid sort-grid-2 "+listIconClassActive} onClick={() => { changeRecordLayout('list'); }}>
                                                        <i class="fa fa-bars" aria-hidden="true"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class={listingViewClass}>
                                        <ul>
                                            <InfiniteScroll
                                                dataLength={items.length} //This is important field to render the next data
                                                next={() => {
                                                    fetchData(setItems, items);
                                                }}
                                                hasMore={hasMore}
                                                loader={<h4><center>Loading...</center></h4>}
                                                /* endMessage={
                                                <p style={{ textAlign: "center" }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                                } */
                                                // below props only if you need pull down functionality
                                                refreshFunction={refresh}
                                                endMessage={<p><center>No more data to load.</center></p>}
                                                /* pullDownToRefresh
                                                pullDownToRefreshThreshold={50}
                                                pullDownToRefreshContent={
                                                    <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
                                                }
                                                releaseToRefreshContent={
                                                    <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
                                                } */
                                            >
                                                <div style={{ minHeight: "0" }}>
                                                { items.map(item => (
                                                        <MemberItems item={item} setTextLoginModal={setTextLoginModal} modalOpenLoginBtnRef={modalOpenLoginBtnRef} modalOpenExpressBtnRef={modalOpenExpressBtnRef}
                                                        modalOpenMemberProfileBtnRef={modalOpenMemberProfileBtnRef}modalCloseMemberProfileBtnRef={modalCloseMemberProfileBtnRef}
                                                        openMemberProfileModal={openMemberProfileModal}
                                                        setContentExpressModal={setContentExpressModal} setExpressModalHeaderText={setExpressModalHeaderText} setSelectedMemberId={setSelectedMemberId} updateExpressInterest={updateExpressInterest} getProfileImage={getProfileImage} gotoUserProfile={gotoUserProfile} />
                                                    ))}
                                                </div>
                                            </InfiniteScroll>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END */}


                {/* INTEREST POPUP */}
                <div class="modal fade" id="sendInter">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">Send interest to <span class="intename2">Jolia</span></h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="lhs">
                                    <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" class="intephoto2" />
                                </div>
                                <div class="rhs">
                                    <h4>Permissions: <span class="intename2">Jolia</span> Can able to view the below details</h4>
                                    <ul>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_about" checked="" />
                                                <label for="pro_about">About section</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_photo" />
                                                <label for="pro_photo">Photo gallery</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_contact" />
                                                <label for="pro_contact">Contact info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_person" />
                                                <label for="pro_person">Personal info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_hobbi" />
                                                <label for="pro_hobbi">Hobbies</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_social" />
                                                <label for="pro_social">Social media</label>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="form-floating">
                                        <textarea class="form-control" id="comment" name="text"
                                            placeholder="Comment goes here"></textarea>
                                        <label for="comment">Write some message to <span class="intename"></span></label>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Send interest</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/* END INTEREST POPUP */}

                {/* CHAT CONVERSATION BOX START */}
                <div class="chatbox">
                    <span class="comm-msg-pop-clo"><i class="fa fa-times" aria-hidden="true"></i></span>

                    <div class="inn">
                        <form name="new_chat_form" method="post">
                            <div class="s1">
                                <img src={"/assets/images/user/2.jpg?x="+CONSTANTS.RANDOM_PWD} class="intephoto2" alt="" />
                                <h4><b class="intename2">Julia</b>,</h4>
                                <span class="avlsta avilyes">Available online</span>
                            </div>
                            <div class="s2 chat-box-messages">
                                <span class="chat-wel">Start a new chat!!! now</span>
                                <div class="chat-con">
                                    <div class="chat-lhs">Hi</div>
                                    <div class="chat-rhs">Hi</div>
                                </div>
                                {/*<span>Start A New Chat!!! Now</span>*/}
                            </div>
                            <div class="s3">
                                <input type="text" name="chat_message" placeholder="Type a message here.." required="" />
                                <button id="chat_send1" name="chat_send" type="submit">Send <i class="fa fa-paper-plane-o"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <a ref={modalOpenLoginBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#login_notice_modal">login</a>
                <div class="modal fade" id="login_notice_modal">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content notification-modal">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{t('please_log_in')}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="text-center">{textLoginModal}</div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => { modalCloseLoginBtnRef.current.click();gotoLogin(); }}>Login</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalCloseLoginBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <a ref={modalOpenExpressBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#expression_modal">express</a>
                <div class="modal fade" id="expression_modal">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content notification-modal">

                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{expressModalHeaderText}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div class="modal-body seninter">
                                <div class="text-center">{contentExpressModal}</div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => { updateExpressInterest(selectedMemberId);modalCloseExpressBtnRef.current.click(); }}>Confirm</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalCloseExpressBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <a ref={modalOpenMemberProfileBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#member_profile_modal">login</a>
                <div class="modal fade bg-white" id="member_profile_modal">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h1 class="modal-title seninter-tit">
                                    <span className='intename2 font-20'>{CONSTANTS.PROJECT_TITLE}</span>
                                </h1>
                                {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                            </div>

                            {/* Modal body */}
                            <div class="modal-body pd-0">
                            {/* {
                                (userData.length > 0) ?  */}
                                {
                                    (isMemberSelected) ? 
                                    <section>
                                        <div class="profi-pg profi-ban">
                                            <div class="">
                                                <div class="">
                                                    <div class="profile">
                                                        <div class="pg-pro-big-im">
                                                            <div class="s1">
                                                                {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                                                { getMemberImage(userData) }
                                                                <i class="fa fa-arrow-circle-o-left float-right mv-pro font-35" data-toggle="modal" data-target="#user_introduction" aria-hidden="true" style={{position: 'fixed',top: 2+'%', right: 15+'px', zIndex: 999, opacity: 0.7, backgroundColor: '#FFF', width: 38+'px', padding:2+'px', borderRadius:25+'px' }} onClick={() =>  {modalCloseMemberProfileBtnRef.current.click(); setMemberSelected(false);} /* navigate('/members.html') */}></i>
                                                            </div>
                                                            <div class="s3">
                                                            {/* {
                                                                (isInterested) ? 
                                                                    <a className="cta express cta-chat w-25 active" title='Interest Expressed' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Expressed Interest In This Member.'); }} ><i class="fa fa-heart"></i></a>
                                                                    : 
                                                                    <a className="cta express cta-chat w-25" title='Express Interest'  onClick={() => {gotoExpressInterest(props.item.member_id)}} ><i class="fa fa-heart"></i></a>
                                                            }

                                                            {
                                                                (isShortlisted) ? 
                                                                    <a title='Shortlisted'  onClick={() => { removeShortlist(user_id, userData.member_id); }}  className='cta express cta-chat w-25 active' ><i class="fa fa-list-ul"></i></a>
                                                                    : 
                                                                    <a title='Shortlist' onClick={() => { saveShortlist(user_id, userData.member_id); }} class="cta express cta-chat w-25"><i class="fa fa-list-ul"></i></a>
                                                            }

                                                            {
                                                                (isFollowed) ? 
                                                                    <a title='Unfollow'  onClick={() => { addUnfollowList(user_id, userData.member_id); }} className='cta express cta-chat w-25 active' ><i class="fa fa-star"></i></a>
                                                                    : 
                                                                    <a title='Follow' onClick={() => { saveFollowlist(user_id, userData.member_id); }} className='cta express cta-chat w-25' ><i class="fa fa-star"></i></a>
                                                            }

                                                            {
                                                                (isIgnored) ? 
                                                                    <a title='Unfollow' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Ignored This Member.'); }} className='cta express cta-chat w-25 active' ><i class="fa fa-ban"></i></a>
                                                                    : 
                                                                    <a title='Ignore Profile'  onClick={() => { gotoIgnore(userData.member_id); }} className='cta express cta-chat w-25 ' ><i class="fa fa-ban"></i></a>
                                                            } */}
                                                            {/* {
                                                                (isMemberSelected) ? <ProfileActionPanelModal item={userData} setTextLoginModal={props.setTextLoginModal} modalOpenLoginBtnRef={props.modalOpenLoginBtnRef} modalOpenExpressBtnRef={props.modalOpenExpressBtnRef} setContentExpressModal={props.setContentExpressModal} setExpressModalHeaderText={props.setExpressModalHeaderText} setSelectedMemberId={props.setSelectedMemberId} updateExpressInterest={props.updateExpressInterest} is_interested={isInterested} is_shortlisted={isShortlisted} is_followed={true} is_ignored={isIgnored} is_reported={isReported} /> : ''
                                                            } */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="profi-pg profi-bio">
                                                        <div class="lhs">
                                                            <div class="pro-pg-intro">
                                                                <h1>{ COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) }</h1>
                                                                <div class="pro-info-status">
                                                                    <span class="stat-1"><b>100</b> viewers</span>
                                                                    <span class="stat-2"><b>Available</b> online</span>
                                                                    {/* <button onClick={() => navigate('/members.html')}>Back</button> */}
                                                                </div>
                                                                <ul>
                                                                    {
                                                                        (userBasicInfoData.marital_status_title) ? <li>
                                                                            <div>
                                                                                {/* <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/> */}
                                                                                <span>Marital Status: <strong>{ userBasicInfoData.marital_status_title }</strong></span>
                                                                            </div>
                                                                        </li> : ''
                                                                    }
                                                                    {
                                                                        (userData.age) ? <li>
                                                                            <div>
                                                                                {/* <img src={"/assets/images/icon/pro-age.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/> */}
                                                                                <span>Age: <strong>{ userData.age } Yrs</strong></span>
                                                                            </div>
                                                                        </li> : ''
                                                                    }
                                                                    {
                                                                        (userData.height) ? <li>
                                                                            <div>
                                                                                {/* <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/> */}
                                                                                <span>Height: <strong>{ userData.height } Feet</strong></span>
                                                                            </div>
                                                                        </li> : ''
                                                                    }
                                                                    {
                                                                        (userBasicInfoData.on_behalf_title) ? <li>
                                                                            <div>
                                                                                {/* <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/> */}
                                                                                <span>Registered: <strong>{ userBasicInfoData.on_behalf_title }</strong></span>
                                                                            </div>
                                                                        </li> : ''
                                                                    }
                                                                </ul>
                                                            </div>
                                                            {/* PROFILE ABOUT */}
                                                            {
                                                                (userData.introduction) ? <div class="pr-bio-c pr-bio-abo">
                                                                    <h3>About</h3>
                                                                    <p>{ userData.introduction }</p>
                                                                </div> : ''
                                                            }
                                                            
                                                            {/* END PROFILE ABOUT */}
                                                            {/* PROFILE ABOUT */}
                                                            {/* <div class="pr-bio-c pr-bio-gal" id="gallery">
                                                                <h3>Photo gallery</h3>
                                                                <div id="image-gallery">
                                                                    <div class="pro-gal-imag">
                                                                        <div class="img-wrapper">
                                                                            <a href="#!"><img src={"/assets/images/profiles/1.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="pro-gal-imag">
                                                                        <div class="img-wrapper">
                                                                            <a href="#!"><img src={"/assets/images/profiles/6.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="pro-gal-imag">
                                                                        <div class="img-wrapper">
                                                                            <a href="#!"><img src={"/assets/images/profiles/14.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                                            <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            {/* END PROFILE ABOUT */}

                                                            {/* INTRODUCTION */}

                                                            { (userData.introduction != "") ? <div class="pr-bio-c pr-bio-conta"><h3>Introduction</h3><b>{ userData.introduction }</b></div> : '' }
                                                            
                                                            {/* END INTRODUCTION */}

                                                            {/* PROFILE ABOUT */}
                                                            {/* <div class="pr-bio-c pr-bio-conta">
                                                                <h3>Contact info</h3>
                                                                <ul>
                                                                    { (userData.mobile != "") ? <li><span><i class="fa fa-mobile" aria-hidden="true"></i><b>Phone:</b>{ userData.mobile }</span></li> : '' }

                                                                    { (userData.email != "") ? <li><span><i class="fa fa-envelope-o" aria-hidden="true"></i><b>Email:</b>{ userData.email }</span></li> : '' }

                                                                    {/* <li><span><i class="fa fa fa-map-marker" aria-hidden="true"></i><b>Address: </b>{ userPermanentAddressData.permanent_city_title+', '+userPermanentAddressData.permanent_state_title+', '+userPermanentAddressData.permanent_country_title }</span></li>
                                                                </ul>
                                                            </div> */}
                                                            {/* END PROFILE ABOUT */}
                                                            {/* PROFILE ABOUT */}
                                                            <div class="pr-bio-c pr-bio-info">
                                                                <h3>Personal information</h3>
                                                                <ul>
                                                                    <li><b>Name:</b> { COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) }</li>

                                                                    { (userData.member_profile_id != "") ? <li><b>Profile ID:</b> { userData.member_profile_id }</li> : '' }

                                                                    {/* { (userData.mobile != "") ? <li><b>Mobile:</b> { userData.mobile }</li> : '' }

                                                                    { (userData.email) ? <li><b>Email:</b> { userData.email }</li> : '' } */}
                                                                    
                                                                    <li><b>Age:</b> { userData.age } Years</li>
                                                                    <li><b>Date of birth:</b> { userData.date_of_birth }</li>

                                                                    { (userBasicInfoData.marital_status_title != "") ? <li><b>Marital Status:</b> { userBasicInfoData.marital_status_title }</li> : '' }

                                                                    { (userData.height > 0) ? <li><b>Height:</b> { userData.height } Feet</li> : '' }

                                                                    {/* { (userPhysicalData.weight) ? <li><b>Weight:</b> { userPhysicalData.weight } kg</li> : '' } */}
                                                                    
                                                                    { (userEducationCareerData.highest_education_title) ? <li><b>Education:</b> { userEducationCareerData.highest_education_title }</li> : '' }

                                                                    { (userEducationCareerData.occupation_title) ? <li><b>Occupation:</b> { userEducationCareerData.occupation_title }</li> : '' }

                                                                    { (userSpiritualData.caste_title) ? <li><b>Caste:</b> { userSpiritualData.caste_title }</li> : '' }

                                                                    { (userSpiritualData.religion_title) ? <li><b>Religion:</b> { userSpiritualData.religion_title }</li> : '' }

                                                                    { (userLanguageData.mother_tongue_title) ? <li><b>Mother Tongue:</b> { userLanguageData.mother_tongue_title }</li> : '' }

                                                                    { (userPermanentAddressData.permanent_city_title) ? <li><b>City:</b> { userPermanentAddressData.permanent_city_title }</li> : '' }

                                                                    { (userPermanentAddressData.permanent_state_title) ? <li><b>State:</b> { userPermanentAddressData.permanent_state_title }</li> : '' }

                                                                    {/* <li><b>Profession:</b> Working</li>
                                                                    <li><b>Company:</b> Google</li>
                                                                    <li><b>Position:</b> Web developer</li>
                                                                    <li><b>Salary:</b> $1000 p/m</li> */}
                                                                </ul>
                                                            </div>
                                                            {/* END PROFILE ABOUT */}
                                                            {/* PROFILE ABOUT
                                                            <div class="pr-bio-c pr-bio-hob">
                                                                <h3>Hobbies</h3>
                                                                <ul>
                                                                    <li><span>Modelling</span></li>
                                                                    <li><span>Watching movies</span></li>
                                                                    <li><span>Playing volleyball</span></li>
                                                                    <li><span>Hangout with family</span></li>
                                                                    <li><span>Adventure travel</span></li>
                                                                    <li><span>Books reading</span></li>
                                                                    <li><span>Music</span></li>
                                                                    <li><span>Cooking</span></li>
                                                                    <li><span>Yoga</span></li>
                                                                </ul>
                                                            </div> */}
                                                            {/* END PROFILE ABOUT */}
                                                            {/* PROFILE ABOUT 
                                                            <div class="pr-bio-c menu-pop-soci pr-bio-soc">
                                                                <h3>Social media</h3>
                                                                <ul>
                                                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                                    <li><a href="#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                                                </ul>
                                                            </div>*/}
                                                            {/* END PROFILE ABOUT */}


                                                        </div>

                                                        {/* PROFILE lHS */}
                                                        <div class="rhs">
                                                            {/* HELP BOX */}
                                                            <div class="prof-rhs-help">
                                                                <div class="inn">
                                                                    <h3>Tell us your Needs</h3>
                                                                    <p>Tell us what kind of service or experts you are looking.</p>
                                                                    {(isLoggedIn) ? <Link to="/contact.html">Contact Us</Link> : <Link to="/sign-up.html">Register</Link> }
                                                                </div>
                                                            </div>
                                                            {/* END HELP BOX */}
                                                            {/* RELATED PROFILES }
                                                            <div class="slid-inn pr-bio-c wedd-rel-pro">
                                                                <h3>Related profiles</h3>
                                                                <ul class="slider3">
                                                                    <li>
                                                                        <div class="wedd-rel-box">
                                                                            <div class="wedd-rel-img">
                                                                                <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                                                <span class="badge badge-success">21 Years old</span>
                                                                            </div>
                                                                            <div class="wedd-rel-con">
                                                                                <h5>Christine</h5>
                                                                                <span>City: <b>New York City</b></span>
                                                                            </div>
                                                                            <a href="profile-details.html" class="fclick"></a>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="wedd-rel-box">
                                                                            <div class="wedd-rel-img">
                                                                                <img src={"/assets/images/profiles/2.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                                                <span class="badge badge-success">21 Years old</span>
                                                                            </div>
                                                                            <div class="wedd-rel-con">
                                                                                <h5>Christine</h5>
                                                                                <span>City: <b>New York City</b></span>
                                                                            </div>
                                                                            <a href="profile-details.html" class="fclick"></a>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="wedd-rel-box">
                                                                            <div class="wedd-rel-img">
                                                                                <img src={"/assets/images/profiles/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                                                <span class="badge badge-success">21 Years old</span>
                                                                            </div>
                                                                            <div class="wedd-rel-con">
                                                                                <h5>Christine</h5>
                                                                                <span>City: <b>New York City</b></span>
                                                                            </div>
                                                                            <a href="profile-details.html" class="fclick"></a>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="wedd-rel-box">
                                                                            <div class="wedd-rel-img">
                                                                                <img src={"/assets/images/profiles/4.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                                                <span class="badge badge-success">21 Years old</span>
                                                                            </div>
                                                                            <div class="wedd-rel-con">
                                                                                <h5>Christine</h5>
                                                                                <span>City: <b>New York City</b></span>
                                                                            </div>
                                                                            <a href="profile-details.html" class="fclick"></a>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="wedd-rel-box">
                                                                            <div class="wedd-rel-img">
                                                                                <img src={"/assets/images/profiles/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                                                <span class="badge badge-success">21 Years old</span>
                                                                            </div>
                                                                            <div class="wedd-rel-con">
                                                                                <h5>Christine</h5>
                                                                                <span>City: <b>New York City</b></span>
                                                                            </div>
                                                                            <a href="profile-details.html" class="fclick"></a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            { END RELATED PROFILES */}
                                                        </div>
                                                        {/* END PROFILE lHS */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section> : <Loader />
                                }
                                
                                {/* : ''
                            } */}
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalCloseMemberProfileBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END */}
                <Footer/>
            </>
    );
}