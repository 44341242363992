import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

import {Link} from "react-router-dom";

function Counts()
{
    return (
        <section>
            <div class="ab-cont">
                <div class="container">
                    <div class="row">
                        <ul>
                            <li>
                                <div class="ab-cont-po">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                                    <div>
                                        <h4>2K</h4>
                                        <span>Couples paired</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="ab-cont-po">
                                    <i class="fa fa-users" aria-hidden="true"></i>
                                    <div>
                                        <h4>1000K+</h4>
                                        <span>Registerents</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="ab-cont-po">
                                    <i class="fa fa-male" aria-hidden="true"></i>
                                    <div>
                                        <h4>800K+</h4>
                                        <span>Mens</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="ab-cont-po">
                                    <i class="fa fa-female" aria-hidden="true"></i>
                                    <div>
                                        <h4>200K+</h4>
                                        <span>Womens</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Counts