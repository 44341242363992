import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function AstronomicInformation(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [moon_sign_data] = useState(common_data.common_data.moonsign_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userAstronomicInformationData, setUserAstronomicInformationData] = useState(userData.astronomic_information);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const AstronomicInformationSchema = Yup.object({
        /* country: Yup.string().required('Required'),
        state: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_astronomic_information";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserAstronomicInformationData(user_data.astronomic_information);
    }

    return (
        <div>
            {
                (userAstronomicInformationData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_astronomic_information">{t('astronomic_information')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_astronomic_information" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('sun_sign')}:</b> <span title={ userAstronomicInformationData.sun_sign }>{ userAstronomicInformationData.sun_sign }</span></li>

                        <li><b>{t('moon_sign')}:</b> <span title={ userAstronomicInformationData.moon_sign_title }>{ userAstronomicInformationData.moon_sign_title }</span></li>

                        <li><b>{t('city_of_birth')}:</b> <span title={ userAstronomicInformationData.city_of_birth }>{ userAstronomicInformationData.city_of_birth }</span></li>

                        <li><b>{t('time_of_birth')}:</b> <span title={ userAstronomicInformationData.time_of_birth }>{ userAstronomicInformationData.time_of_birth }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_astronomic_information">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{ sun_sign: userAstronomicInformationData.sun_sign, moon_sign: userAstronomicInformationData.moon_sign_id, time_of_birth: userAstronomicInformationData.time_of_birth, city_of_birth: userAstronomicInformationData.city_of_birth }}
                        validationSchema={AstronomicInformationSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('astronomic_information')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('sun_sign')}:</label>
                                            <input type="text" id="sun_sign" class="form-control" placeholder={t('enter_affection')} name="sun_sign" onChange={formik.handleChange} value={formik.values.sun_sign} />
                                            {formik.errors.sun_sign ? <div className='m-t-10 text-left error'>{formik.errors.sun_sign}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('moon_sign')}:</label>
                                            <select class="chosen-select" id="moon_sign" name='moon_sign' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {moon_sign_data.map((option, index) => (
                                                    <option key={index} value={option.moonsign_id} selected={(option.moonsign_id == userAstronomicInformationData.moon_sign_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.moon_sign ? <div className='m-t-10 text-left error'>{formik.errors.moon_sign}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('city_of_birth')}:</label>
                                            <input type="text" id="city_of_birth" class="form-control" placeholder={t('enter_city_of_birth')} name="city_of_birth" onChange={formik.handleChange} value={formik.values.city_of_birth} />
                                            {formik.errors.city_of_birth ? <div className='m-t-10 text-left error'>{formik.errors.city_of_birth}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('time_of_birth')}: </label>
                                            <input type="text" id="time_of_birth" class="form-control" placeholder={t('enter_time_of_birth')} name="time_of_birth" onChange={formik.handleChange} value={formik.values.time_of_birth} />
                                            {formik.errors.time_of_birth ? <div className='m-t-10 text-left error'>{formik.errors.time_of_birth}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AstronomicInformation