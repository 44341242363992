import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom";
import i18next from "i18next"

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
import LanguageOptions from '../common/language_dropdown'

function Top()
{
    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [social_link_data, setSocialLinkData] = useState([]);

    useEffect(() => {
        if(common_data !== null)
            setSocialLinkData(common_data.common_data.social_links_data);
    })

    const handleClick=(e)=>{
        //console.log(e.target.value);
        //i18next.init({keySeparator : '_'});
        i18next.changeLanguage(e.target.value)
    }

    return (
        <div>
            {/* PRELOADER
            <div id="preloader">
                <div class="plod">
                    <span class="lod1"><img src="images/loder/1.png" alt="" loading="lazy" /></span>
                    <span class="lod2"><img src="images/loder/2.png" alt="" loading="lazy" /></span>
                    <span class="lod3"><img src="images/loder/3.png" alt="" loading="lazy" /></span>
                </div>
            </div> */}
            <div class="pop-bg"></div>
            {/* END PRELOADER */}

            {/* POPUP SEARCH */}
            <div class="pop-search">
                <span class="ser-clo">+</span>
                <div class="inn">
                    <form>
                        <input type="text" placeholder="Search here..." />
                    </form>
                    <div class="rel-sear">
                        <h4>Top searches:</h4>
                        <Link to="/all-profiles.html">Browse all profiles</Link>
                        <Link to="/all-profiles.html">Mens profile</Link>
                        <Link to="/all-profiles.html">Female profile</Link>
                        <Link to="/all-profiles.html">New profiles</Link>
                    </div>
                </div>
            </div>
            {/* END */}
            {/*} TOP MENU */}
            <div class="head-top">
                <div class="container">
                    <div class="row">
                        <div class="lhs">
                            <ul>
                                {/* <li><LanguageOptions onChange={(e)=> handleClick(e)}/></li>
                                <li><Link to="/#!" class="ser-open"><i class="fa fa-search" aria-hidden="true"></i></Link></li> */}
                                <li><Link to="/about.html">About</Link></li>
                                <li><Link to="/faq.html">FAQ</Link></li>
                                <li><Link to="/contact.html">Contact</Link></li>
                            </ul>
                        </div>
                        <div class="rhs">
                            <ul>
                                <li><a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER}><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;{CONSTANTS.PUBLIC_CONTACT_NUMBER}</a></li>
                                <li><a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}><i class="fa fa-envelope-o"
                                            aria-hidden="true"></i>&nbsp; {CONSTANTS.PUBLIC_CONTACT_EMAIL}</a></li>

                                {/* {social_link_data.map((option, index) => {
                                    if(option.type === 'facebook' && option.value.length > 0) {
                                        return <li><a href={option.value} target='_blank'><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    }
                                    if(option.type == 'twitter' && option.value.length > 0) { 
                                        return <li><a href={option.value} target='_blank'><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    }

                                    if(option.type == 'whatsapp' && option.value.length > 0) { 
                                        return <li><a href={option.value} target='_blank'><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li> 
                                    }
                                })} */}
                                <li><a href={CONSTANTS.ANDROID_APP_LINK} target='_blank' title="Android Application"><i class={'fa fa-android'} aria-hidden="true"></i></a></li>
                                <li><a href={'https://wa.me/'+CONSTANTS.CONTACT_NUMBER} target='_blank' title="WhatsApp"><i class={'fa fa-whatsapp'} aria-hidden="true"></i></a></li>
                                {social_link_data.map((option, index) => (
                                    (option.value.length > 0 ) ? <li><a href={option.value} target='_blank' title={COMMONFUNCTIONS.camelCase(option.type)}><i class={option.icon} aria-hidden="true"></i></a></li> : ''
                                ))}
                                {/* <li><Link to="/#!"><i class="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                <li><Link to="/#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/*} END */}
        </div>
    )
}

export default Top