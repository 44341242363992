import React from 'react'

import * as CONSTANTS from '../../Constants';
import {Link} from "react-router-dom";

function PlanItems(props)
{
    //console.log(props);
    function ListItem({ item }) {
        //console.log(item[1]);
        //const {label, value} = item[1];
        let values = Object.values(item[1]);

        if (typeof values === 'object') {
            return (
                <li><i class="fa fa-check" aria-hidden="true"></i>{values}</li>
            );
        }
        return <li><i class="fa fa-check" aria-hidden="true"></i>{values}</li>;
    }

    const loadItems = (main_props) => {
        //console.log(props);
        //let[plans] = items;
        //console.log(plans);
        let items = main_props.items;
        if(items.length > 0)
        {
            //console.log(items);
            return (
                items.map((plan, index) => {
                    //console.log(plan);
                    let  top_plan_class = '';
                    if(index == 1)
                        top_plan_class = 'pri-box-pop';

                    return(
                        <li class="col-md-4 m-b-20">
                            <div class={"pri-box " + top_plan_class}>
                                { (index == 1) && <span class="pop-pln">Most popular plan</span> }
                                <h2>{plan.name}</h2>
                                {/* <p>Printer took a type and scrambled </p> */}
                                { (index == 0) && <a href="sign-up.html" class="cta my-3">Get Started</a> }
                                {/* { (index != 0) && <a onClick={() => {props.openPaymentModal();}} class="cta">Pay Now</a> } */}
                                { (index != 0) && <div className='row my-3'><div className='col-md-2'></div><div className='col-md-4 text-center'><a onClick={() => {props.openPaymentModal('phonepe');}}><img src={"/assets/images/icon/pp.png?x="+CONSTANTS.RANDOM_PWD} width={50+'%'} alt="PhonePe" title="PhonePe" className='p-t-10'/></a></div><div className='col-md-4 text-center'><a onClick={() => {props.openPaymentModal('gpay');}}><img src={"/assets/images/icon/gp.png?x="+CONSTANTS.RANDOM_PWD} width={50+'%'} alt="Google Pay" title="Google Pay"  className='p-t-10'/></a></div><div className='col-md-2'></div><div className='col-md-12 m-t-25'><a onClick={() => {props.openPayUPaymentModal(index);}}><img src={"/assets/images/icon/pu.png?x="+CONSTANTS.RANDOM_PWD} width={50+'%'} alt="PayUMoney" title="PayUMoney" className='p-t-10'/></a></div></div> }
                                <span class="pri-cou"><b>&#8377; {plan.amount}</b></span>
                                <ol>
                                {Object.entries(plan.plan_description).map((item, key) => {
                                    return <ListItem key={key} item={item} />;
                                })}
                                {/* { (plan.plan_description.express_interest !== undefined) && <li><i class="fa fa-check" aria-hidden="true"></i> {plan.plan_description.express_interest}
                                    </li>
                                } */}
                                    {/* <li><i class="fa fa-close close" aria-hidden="true"></i> 5 Premium Profiles view /mo
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                    <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li> */}
                                </ol>
                            </div>
                        </li>
                    )
                })
            )
        }
    }

    return (
        <>
            {loadItems(props)}
        </>
    )
}

export default PlanItems