import React, { useEffect, useState } from 'react'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import {Link} from "react-router-dom";

function Home_Register_Banner()
{
    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    return (
        <div>
            {/* FIND YOUR MATCH BANNER */}
            <section>
                <div class="str count">
                    <div class="container">
                        <div class="row">
                            <div class="fot-ban-inn">
                                <div class="lhs">
                                    <h2>Find your perfect Match now</h2>
                                    <p>lacinia viverra lectus. Fusce imperdiet ullamcorper metus eu fringilla.Lorem Ipsum is
                                        simply dummy text of the printing and typesetting industry.</p>

                                    {(isLoggedIn) ? <Link to="/contact.html" class="cta-4">Help & Support</Link> : <><Link to="/sign-up.html" class="cta-3">Register</Link><Link to="/contact.html" class="cta-4">Help & Support</Link></> }
                                    {/* <a href="sign-up.html" class="cta-3">Register Now</a>
                                    <a href="sign-up.html" class="cta-4">Help & Support</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Home_Register_Banner