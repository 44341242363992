import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
//import Select from "react-select";

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function PhysicalAttributes(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));

    const [complexion_data] = useState(common_data.common_data.complexion_data);
    const [body_type_data] = useState(common_data.common_data.body_type_data);
    const [blood_group_data] = useState(common_data.common_data.blood_group_data);
    const [any_disability_data] = useState(common_data.common_data.physically_challenged_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userPhysicalData, setUserPhysicalData] = useState(userData.physical_attributes);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const PhysicalAttributesSchema = Yup.object({
        /* highest_education: Yup.string().required('Required'),
        occupation: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_physical_attributes";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserPhysicalData(user_data.physical_attributes);
    }

    return (
        <div>
            {
                (userPhysicalData) ? <div class="pr-bio-c pr-bio-info">
                <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_physical_attributes">{t('physical_attributes')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_physical_attributes" aria-hidden="true"></i></h3>
                <ul>
                    <li><b>{t('height')}:</b> <span title={ userData.height }> { userData.height } Feet</span></li>

                    <li><b>{t('weight')}:</b> <span title={ userPhysicalData.weight }> { userPhysicalData.weight } KG</span></li>

                    <li><b>{t('eye_color')}:</b> <span title={ userPhysicalData.eye_color }> { userPhysicalData.eye_color }</span></li>

                    <li><b>{t('hair_color')}:</b> <span title={ userPhysicalData.hair_color }> { userPhysicalData.hair_color }</span></li>

                    <li><b>{t('complexion')}:</b> <span title={ userPhysicalData.complexion_title }> { userPhysicalData.complexion_title }</span></li>

                    <li><b>{t('blood_group')}:</b> <span title={ userPhysicalData.blood_group_title }> { userPhysicalData.blood_group_title }</span></li>
                    
                    <li><b>{t('body_type')}:</b> <span title={ userPhysicalData.body_type_title }> { userPhysicalData.body_type_title }</span></li>

                    <li><b>{t('body_art')}:</b> <span title={ userPhysicalData.body_art }> { userPhysicalData.body_art }</span></li>

                    <li><b>{t('any_disability')}:</b> <span title={ userPhysicalData.any_disability_title }> { userPhysicalData.any_disability_title }</span></li>
                    
                </ul>
            </div> : ''
            }

            <div class="modal fade" id="user_physical_attributes">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  height: userData.height, weight: userPhysicalData.weight, eye_color: userPhysicalData.eye_color, hair_color: userPhysicalData.hair_color, complexion: userPhysicalData.complexion_id, blood_group: userPhysicalData.blood_group_id, body_type: userPhysicalData.body_type_id, body_art: userPhysicalData.body_art, any_disability: userPhysicalData.any_disability_id }}
                        validationSchema={PhysicalAttributesSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('physical_attributes')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('height')}: </label>
                                            <div class="input-group mb-3">
                                                <input type="text" id="height" class="form-control" placeholder={t('height')} name="height" onChange={formik.handleChange} value={formik.values.height} />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">{t('Feet')}</span>
                                                </div>
                                            </div>
                                            {formik.errors.height ? <div className='m-t-10 text-left error'>{formik.errors.height}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('weight')}: </label>
                                            <div class="input-group mb-3">
                                                <input type="text" id="weight" class="form-control" placeholder={t('enter_weight')} name="weight" onChange={formik.handleChange} value={formik.values.weight} />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">{t('KG')}</span>
                                                </div>
                                            </div>
                                            {formik.errors.weight ? <div className='m-t-10 text-left error'>{formik.errors.weight}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('eye_color')}: </label>
                                            <input type="text" id="eye_color" class="form-control" placeholder={t('enter_eye_color')} name="eye_color" onChange={formik.handleChange} value={formik.values.eye_color} />
                                            {formik.errors.eye_color ? <div className='m-t-10 text-left error'>{formik.errors.eye_color}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('hair_color')}: </label>
                                            <input type="text" id="hair_color" class="form-control" placeholder={t('enter_hair_color')} name="hair_color" onChange={formik.handleChange} value={formik.values.hair_color} />
                                            {formik.errors.hair_color ? <div className='m-t-10 text-left error'>{formik.errors.hair_color}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('complexion')}:</label>
                                            <select class="chosen-select" id="complexion" name='complexion' onChange={formik.handleChange} >
                                                <option value="">- {t('choose')} -</option>
                                                {complexion_data.map((option, index) => (
                                                    <option key={index} value={option.complexion_id} selected={(option.complexion_id == userPhysicalData.complexion_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.complexion ? <div className='m-t-10 text-left error'>{formik.errors.complexion}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('blood_group')}:</label>
                                            < select class="chosen-select" id="blood_group" name='blood_group' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {blood_group_data.map((option, index) => (
                                                    <option key={index} value={option.blood_group_id} selected={(option.blood_group_id == userPhysicalData.blood_group_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.blood_group ? <div className='m-t-10 text-left error'>{formik.errors.blood_group}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('body_type')}:</label>
                                            <select class="chosen-select" id="body_type" name='body_type' onChange={formik.handleChange} >
                                                <option value="">- {t('choose')} -</option>
                                                {body_type_data.map((option, index) => (
                                                    <option key={index} value={option.body_type_id} selected={(option.body_type_id == userPhysicalData.body_type_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.body_type ? <div className='m-t-10 text-left error'>{formik.errors.body_type}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('body_art')}: </label>
                                            <input type="text" id="body_art" class="form-control" placeholder={t('enter_body_art')} name="body_art" onChange={formik.handleChange} value={formik.values.body_art} />
                                            {formik.errors.body_art ? <div className='m-t-10 text-left error'>{formik.errors.body_art}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('any_disability')}:</label>
                                            < select class="chosen-select" id="any_disability" name='any_disability' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {any_disability_data.map((option, index) => (
                                                    <option key={index} value={option.physically_challenged_id} selected={(option.physically_challenged_id == userPhysicalData.any_disability_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.any_disability ? <div className='m-t-10 text-left error'>{formik.errors.any_disability}</div> : null}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default PhysicalAttributes