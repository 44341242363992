import { combineReducers } from 'redux';

const getUser = ( state = {}, action) => {
    switch (action.type) {
        case 'GET_USER_LOADING':
            return {
                isLoading : true,
                isError : false,
                isSuccess : false,
                userDetails : null,
                user_id : null,
                errors : null,
            }
            break;
        case 'GET_USER_SUCCESS':
            return {
                isLoading : false,
                isError : false,
                isSuccess : true,
                userDetails : action.payload,
                user_id : action.payload.member_id,
                errors : null,
            }
            break;
        case 'GET_USER_FAILURE':
            return {
                isLoading : false,
                isError : true,
                isSuccess : false,
                userDetails : null,
                user_id : null,
                errors : action.payload,
            }
            break;
        default:
            return state; 
    }
}

const getCommonPublicData = ( state = {}, action) => {
    switch (action.type) {
        case 'GET_COMMON_PUBLIC_DATA_SUCCESS':
            return {
                isLoading : false,
                isError : false,
                isSuccess : true,
                commonPublicData : action.payload,
                errors : null,
            }
            break;
        case 'GET_COMMON_PUBLIC_DATA_FAILURE':
            return {
                isLoading : false,
                isError : true,
                isSuccess : false,
                userDetails : null,
                errors : action.payload,
            }
            break;
        default:
            return state; 
    }
}

export default combineReducers ({
    getUser,
    getCommonPublicData
})