import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

const PrivateRoute = () => {
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    return isLoggedIn ? <Outlet /> : <Navigate to="/login.html" />;
}

export default PrivateRoute