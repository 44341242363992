import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
//import Select from "react-select";

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function PresentAddress(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [country_data] = useState(common_data.common_data.country_data);
    //const [onbehalf_data] = useState(common_data.common_data.onbehalf_data);

    //const [userData, ] = useState(props.userData);
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userPresentAddressData, setUserPresentAddressData] = useState(userData.present_address);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(userPresentAddressData.country_id);
    const [selectedState, setSelectedState] = useState(userPresentAddressData.state_id);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);

        if(states == null)
            getStatesByCountry(userPresentAddressData.country_id);

        if(cities == null)
            getCitiesByState(userPresentAddressData.state_id);

    }, [userData, states])

    const PresentAddressSchema = Yup.object({
        country: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_present_address";
        values['user_id'] = userData.member_id;
        values['country'] = selectedCountry;
        values['state'] = selectedState;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserPresentAddressData(user_data.present_address);
    }

    function setCountryVal (value)
    {
        setSelectedCountry(value);
    }

    function setStateVal (value)
    {
        setSelectedState(value);
    }

    const changeCountry = (event) => {
        getStatesByCountry(event.value);
    };

    function getStatesByCountry(country_id)
    {
        values['country_id'] = country_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_state_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
                setStates(response.data.state_data);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const state_options = (states, value) => {
        if (typeof states === 'object' && states !== null )
        {
            return states.map((el) => <option key={el.state_id} value={el.state_id} selected={(el.state_id == value) ? 'selected' : ''} >{el.name}</option>);
        }
    }

    const changeState = (event) => {
        getCitiesByState(event.value);
    };

    function getCitiesByState(state_id)
    {
        values['state_id'] = state_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_city_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
                setCities(response.data.city_data);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const city_options = (cities, value) => {
        if (typeof cities === 'object' && cities !== null )
        {
            return cities.map((el) => <option key={el.city_id} value={el.city_id} selected={(el.city_id == value) ? 'selected' : ''} >{el.name}</option>);
        }
    }

    return (
        <div>
            {
                (userPresentAddressData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_present_address">{t('present_address')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_present_address" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('country')}:</b> <span title={ userPresentAddressData.country_title }>{ userPresentAddressData.country_title }</span></li>
                        <li><b>{t('state')}:</b> <span title={ userPresentAddressData.state_title }>{ userPresentAddressData.state_title }</span></li>

                        { (userPresentAddressData.city_title) ? <li><b>{t('city')}:</b> <span title={ userPresentAddressData.city_title }>{ userPresentAddressData.city_title }</span></li> : '' }
                        
                        <li><b>{t('postal_code')}:</b> <span title={ userPresentAddressData.postal_code }>{ userPresentAddressData.postal_code }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_present_address">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  country: userPresentAddressData.country_id, state: userPresentAddressData.state_id, city: userPresentAddressData.city_id, postal_code: userPresentAddressData.postal_code }}
                        validationSchema={PresentAddressSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('present_address')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('country')}:</label>
                                            <select class="chosen-select" id="country" name='country' onChange={(event) => {
                                                console.log(event.target.value);
                                                changeCountry(event.target);
                                                //this.handleChange();
                                                setStates(event.target.value);
                                                setCountryVal(event.target.value);
                                            }} >
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userPresentAddressData.country_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>

                                            {/* <Select className="chosen-select sam-select" id="country" name='country' isSearchable={true} options={country_data}></Select> */}
                                            {formik.errors.country ? <div className='m-t-10 text-left error'>{formik.errors.country}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('state')}:</label>
                                            < select class="chosen-select" id="state" name='state' onChange={(event) => {
                                                console.log(event.target.value);
                                                //this.handleChange();
                                                changeState(event.target);
                                                setStateVal(event.target.value);
                                            }} >
                                                <option value="">- {t('choose')} -</option>
                                                { state_options(states, userPresentAddressData.state_id) }
                                                {/* {marital_status_data.map((option, index) => (
                                                    <option key={index} value={option.marital_status_id} selected={(option.marital_status_id == userBasicData.marital_status_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}  */}
                                            </select>
                                            {/* <Select className="chosen-select sam-select" id="state" name='state' isSearchable={true} options={type_state}></Select> */}
                                            {formik.errors.state ? <div className='m-t-10 text-left error'>{formik.errors.state}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('city')}:</label>
                                            <select class="chosen-select" id="city" name='city' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                { city_options(cities, userPresentAddressData.city_id) }
                                                {/* {marital_status_data.map((option, index) => (
                                                    <option key={index} value={option.marital_status_id} selected={(option.marital_status_id == userBasicData.marital_status_id) ? 'selected' : '' }>{option.name}</option>
                                                ))} */}
                                            </select>
                                            {formik.errors.city ? <div className='m-t-10 text-left error'>{formik.errors.city}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('postal_code')}: </label>
                                            <input type="text" id="postal_code" class="form-control" placeholder={t('enter_postal_code')} name="postal_code" onChange={formik.handleChange} value={formik.values.postal_code} />
                                            {formik.errors.postal_code ? <div className='m-t-10 text-left error'>{formik.errors.postal_code}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default PresentAddress