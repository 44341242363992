import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

function Introduction(props)
{
    const {t} = useTranslation();

    //const [userData, ] = useState(props.userData);
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const inputElement = React.useRef()
    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        setUserData(props.userData);
    })

    const AboutSchema = Yup.object({
        introduction: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_introduction";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        /* console.log(values);
        console.log(inputElement); */

        //inputElement.click();
        //handleClick();

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setSubmitting(false);
            modalCloseBtnRef.current.click();
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
        
    }

    return (
        <div>
            {
                (userData.introduction) ? <div class="pr-bio-c pr-bio-abo">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_introduction">{t('about')}</b> <i class="fa fa-pencil float-right mv-pro" data-toggle="modal" data-target="#user_introduction" aria-hidden="true" ref={modalCloseBtnRef}></i></h3>
                    <p><div dangerouslySetInnerHTML={{__html: userData.introduction_br}}></div></p>
                </div> : ''
            }

            <div class="modal fade" id="user_introduction">
                <div class="modal-dialog modal-md">
                    <Formik
                        initialValues={{  introduction: userData.introduction }}
                        validationSchema={AboutSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">ABOUT</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={inputElement}>&times;</button>
                            </div>

                            <div className="modal-body seninter">
                                <div>
                                    <h4>About Yourself</h4>

                                    <div class="form-floating">
                                        <textarea class="form-control textarea-no-border" id="introduction" name="introduction" placeholder="About" onChange={formik.handleChange} value={formik.values.introduction} rows={8}></textarea>
                                        <label for="comment">Write about yourself <span class="intename"></span></label>
                                        {formik.errors.introduction ? <div className='m-t-10 text-left error'>{formik.errors.introduction}</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                {/* <button type="button" class="btn btn-primary">Save</button> */}
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Introduction