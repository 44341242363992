import React, { useEffect, useState } from 'react'

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import {Link} from "react-router-dom";

import MobileNavigation from '../common/mobile_navigation';

function Footer()
{
    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    //const [social_link_data] = useState(common_data.common_data.social_links_data);
    const [social_link_data, setSocialLinkData] = useState([]);

    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    useEffect(() => {
        if(common_data !== null)
            setSocialLinkData(common_data.common_data.social_links_data);
    })

    return (
        <div>
            <section class="wed-hom-footer">
                <div class="container">
                    <div class="row foot-supp">
                        <h2><span>Free support:</span> <a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER}>{CONSTANTS.PUBLIC_CONTACT_NUMBER}</a> &nbsp;&nbsp;|&nbsp;&nbsp; <span>Email:</span> <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}>{CONSTANTS.PUBLIC_CONTACT_EMAIL}</a></h2>
                    </div>
                    <div class="row wed-foot-link wed-foot-link-1">
                        <div class="col-md-4">
                            <h4>Get In Touch</h4>
                            <p><i class="fa fa-map-marker m-r-10" aria-hidden="true"></i> {CONSTANTS.PUBLIC_ADDRESS}</p>
                            <p><i class="fa fa-phone m-r-10" aria-hidden="true"></i> <a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER}>{CONSTANTS.PUBLIC_CONTACT_NUMBER}</a></p>
                            <p><i class="fa fa-envelope-o m-r-10" aria-hidden="true"></i> <a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}>{CONSTANTS.PUBLIC_CONTACT_EMAIL}</a></p>
                        </div>
                        <div class="col-md-4">
                            <h4>HELP &amp; SUPPORT</h4>
                            <ul>
                                <li><Link to="/about.html">About Company</Link> </li>
                                <li><Link to="/contact.html">Contact Us</Link> </li>
                                <li><Link to="/faq.html">FAQs</Link> </li>
                                <li><a href="/terms_and_conditions.html" target='_blank'>Terms & Conditions</a> </li>
                                <li><a href="/privacy_policy.html" target='_blank'>Privacy Policy</a> </li>
                                <li><a href="/refund_policy.html" target='_blank'>Refund Policy</a> </li>
                            </ul>
                        </div>
                        <div class="col-md-4 fot-soc">
                            <h4>SOCIAL MEDIA</h4>
                            <ul>
                            <li><a href={CONSTANTS.ANDROID_APP_LINK} target='_blank'><img src={"/assets/images/social/8.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/></a></li>
                            <li><a href={'https://wa.me/'+CONSTANTS.CONTACT_NUMBER} target='_blank'><img src={"/assets/images/social/66.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/></a></li>
                            {social_link_data.map((option, index) => (
                                (option.value.length > 0 ) ? <li><a href={option.value} target='_blank'><img src={"/assets/images/social/"+option.social_links_id+".png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/></a></li> : ''
                            ))}
                            </ul>
                        </div>
                    </div>
                    <div class="row foot-count">
                        <p>{CONSTANTS.PROJECT_TITLE} - Trusted by over thousands of Boys & Girls for successfull marriage. {(isLoggedIn) ? <a
                                    href="sign-up.html" class="btn btn-primary btn-sm">Join us today !</a> : '' }
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div class="cr">
                    <div class="container">
                        <div class="row">
                            <p>Copyright &copy; <span id="cry">{(new Date().getFullYear())}</span> <a href={CONSTANTS.SERVER_BASE_URL} target="_blank">{CONSTANTS.PROJECT_TITLE}</a> All
                                rights reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
            <MobileNavigation />
        </div>
    )
}

export default Footer