import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

function PersonalInformation(props)
{
    const {t} = useTranslation();

    //const [userData, ] = useState(props.userData);
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userBasicData, setUserBasicData] = useState({});
    const [userEducationCareerData, setUserEducationCareerData] = useState({});
    const [userPhysicalData, setUserPhysicalData] = useState({});
    const [userSpiritualData, setUserSpiritualData] = useState({});

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const inputElement = React.useRef()
    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    })

    const AboutSchema = Yup.object({
        introduction: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_introduction";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        /* console.log(values);
        console.log(inputElement); */

        //inputElement.click();
        //handleClick();

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setSubmitting(false);
            modalCloseBtnRef.current.click();
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserBasicData(user_data.basic_info);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
    }

    return (
        <div>
            {
                (userData.userBasicData) ? <div class="pr-bio-c pr-bio-info">
                <h3>Personal Information</h3>
                <ul>
                    <li><b>Name:</b> <span title={ userData.first_name+' '+userData.last_name }>{ userData.first_name } { userData.last_name }</span></li>
                    <li><b>Mobile:</b> <span title={ userData.mobile }>{ userData.mobile }</span></li>

                    { (userData.email) ? <li><b>Email:</b> <span title={ userData.email }>{ userData.email }</span></li> : '' }
                    
                    <li><b>Marital Status:</b> <span title={ userBasicData.marital_status_title }>{ userBasicData.marital_status_title }</span></li>

                    <li><b>Age:</b> <span title={ userData.age+' Years' }>{ userData.age } Years</span></li>
                    <li><b>Date of birth:</b> <span title={ userData.date_of_birth }>{ userData.date_of_birth }</span></li>

                    { (userData.height > 0) ? <li><b>Height:</b> <span title={ userData.height+' Feet' }>{ userData.height } Feet</span></li> : '' }

                    { (userPhysicalData.weight) ? <li><b>Weight:</b> <span title={ userPhysicalData.weight+' kg' }>{ userPhysicalData.weight } kg</span></li> : '' }
                    
                    { (userEducationCareerData.highest_education_title) ? <li><b>Education:</b> <span title={ userEducationCareerData.highest_education_title }>{ userEducationCareerData.highest_education_title }</span></li> : '' }

                    { (userEducationCareerData.occupation_title) ? <li><b>Occupation:</b> <span title={ userEducationCareerData.occupation_title }>{ userEducationCareerData.occupation_title }</span></li> : '' }

                    { (userSpiritualData.religion_title) ? <li><b>Religion:</b> <span title={ userSpiritualData.religion_title }>{ userSpiritualData.religion_title }</span></li> : '' }

                    { (userSpiritualData.caste_title) ? <li><b>Caste:</b> <span title={ userSpiritualData.caste_title }>{ userSpiritualData.caste_title }</span></li> : '' }
                </ul>
            </div> : ''
            }

            <div class="modal fade" id="user_personal_information">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">

                        {/* Modal Header */}
                        <div class="modal-header">
                            <h4 class="modal-title seninter-tit">Personal Information</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        {/* Modal body */}
                        <div class="modal-body seninter">
                            <div class="lhs">
                                <img src="images/profiles/1.jpg" alt="" class="intephoto1" />
                            </div>
                            <div class="rhs">
                                <h4><span class="intename1">Jolia</span> Can able to view the below details</h4>
                                <ul>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_about" checked="" />
                                            <label for="pro_about">About section</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_photo" />
                                            <label for="pro_photo">Photo gallery</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_contact" />
                                            <label for="pro_contact">Contact info</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_person" />
                                            <label for="pro_person">Personal info</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_hobbi" />
                                            <label for="pro_hobbi">Hobbies</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chbox">
                                            <input type="checkbox" id="pro_social" />
                                            <label for="pro_social">Social media</label>
                                        </div>
                                    </li>
                                </ul>
                                <div class="form-floating">
                                    <textarea class="form-control" id="comment" name="text"
                                        placeholder="Comment goes here"></textarea>
                                    <label for="comment">Write some message to <span class="intename"></span></label>
                                </div>
                            </div>
                        </div>

                        {/* Modal footer */}
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Send interest</button>
                            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalInformation