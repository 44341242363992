import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
import Select, {
    components,
    MultiValueGenericProps,
    MultiValueProps,
    OnChangeValue,
    Props,
  } from 'react-select';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';
import MultiSelect from '../../../components/common/multi_select';

let values = {};

function PartnerExpectation(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [marital_status_data] = useState(common_data.common_data.marital_status_data);
    const [religion_data] = useState(common_data.common_data.religion_data);
    const [caste_data, setCasteData] = useState({});
    const [country_data] = useState(common_data.common_data.country_data);
    const [decision_data] = useState(common_data.common_data.decision_data);
    const [drinking_habits_data] = useState(common_data.common_data.drinking_habits_data);
    const [physically_challenged_data] = useState(common_data.common_data.physically_challenged_data);

    const [education_data] = useState(common_data.common_data.education_data);
    const [profession_data] = useState(common_data.common_data.occupation_data);
    const [diet_data] = useState(common_data.common_data.dietary_habits_data);
    const [body_type_data] = useState(common_data.common_data.body_type_data);
    const [language_data] = useState(common_data.common_data.language_data);
    const [family_value_data] = useState(common_data.common_data.family_value_data);
    const [complexion_data] = useState(common_data.common_data.complexion_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userPartnerExpectationData, setUserPartnerExpectationData] = useState(userData.partner_expectation);

    const [selectedReligion, setSelectedReligion] = useState(userData.partner_expectation.partner_religion);

    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(userData.partner_expectation.partner_marital_status_id);
    const [preSelectedMaritalStatus, setPreSelectedMaritalStatus] = useState({});

    const [selectedEducation, setSelectedEducation] = useState(userData.partner_expectation.partner_education_id);
    const [preSelectedEducation, setPreSelectedEducation] = useState({});

    const [selectedProfession, setSelectedProfession] = useState(userData.partner_expectation.partner_profession_id);
    const [preSelectedProfession, setPreSelectedProfession] = useState({});

    const [selectedDiet, setSelectedDiet] = useState(userData.partner_expectation.partner_diet_id);
    const [preSelectedDiet, setPreSelectedDiet] = useState({});

    const [selectedBodyType, setSelectedBodyType] = useState(userData.partner_expectation.partner_body_type_id);
    const [preSelectedBodyType, setPreSelectedBodyType] = useState({});

    const [selectedDisability, setSelectedDisability] = useState(userData.partner_expectation.partner_any_disability_id);
    const [preSelectedDisability, setPreSelectedDisability] = useState({});

    const [selectedMotherTongue, setSelectedMotherTongue] = useState(userData.partner_expectation.partner_mother_tongue_id);
    const [preSelectedMotherTongue, setPreSelectedMotherTongue] = useState({});

    const [selectedFamilyValue, setSelectedFamilyValue] = useState(userData.partner_expectation.partner_family_value);
    const [preSelectedFamilyValue, setPreSelectedFamilyValue] = useState({});

    const [selectedPreferedCountry, setSelectedPreferedCountry] = useState(userData.partner_expectation.prefered_country_id);
    const [preSelectedPreferedCountry, setPreSelectedPreferedCountry] = useState({});

    const [selectedComplexion, setSelectedComplexion] = useState(userData.partner_expectation.partner_complexion_id);
    const [preSelectedComplexion, setPreSelectedComplexion] = useState({});

    const [selectedCaste, setSelectedCaste] = useState(userData.partner_expectation.partner_caste_id);
    const [preSelectedCaste, setPreSelectedCaste] = useState({});

    const [castes, setCastes] = useState({});
    const [subcastes, setSubCastes] = useState({});

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
        {
            getCastesByReligion(userData.partner_expectation.partner_religion_id);
            setAllUsersData(userData);
        }
    }, [userData])

    const PartnerExpectationSchema = Yup.object({
        /* mother_tongue: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_partner_expectation";
        values['user_id'] = userData.member_id;
        values['partner_marital_status'] = selectedMaritalStatus;
        //values['partner_country_of_residence'] = selectedFamilyValue;
        values['partner_religion'] = selectedReligion;
        values['partner_caste'] = selectedCaste;
        values['partner_sub_caste'] = selectedFamilyValue;
        values['partner_complexion'] = selectedComplexion;
        values['partner_education'] = selectedEducation;
        values['partner_profession'] = selectedProfession;
        values['partner_diet'] = selectedDiet;
        values['partner_body_type'] = selectedBodyType;
        //values['manglik'] = selectedFamilyValue;
        values['partner_any_disability'] = selectedDisability;
        values['partner_mother_tongue'] = selectedMotherTongue;
        values['partner_family_value'] = selectedFamilyValue;
        values['prefered_country'] = selectedPreferedCountry;
        values['prefered_state'] = selectedFamilyValue;

        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        //console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            //console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserPartnerExpectationData(user_data.partner_expectation);
        
        generatePreSelectedMaritalStatusArray(user_data.partner_expectation.partner_marital_status_id);
        generatePreSelectedEducationArray(user_data.partner_expectation.partner_education_id);
        generatePreSelectedProfessionArray(user_data.partner_expectation.partner_profession_id);
        generatePreSelectedDietArray(user_data.partner_expectation.partner_diet_id);
        generatePreSelectedBodyTypeArray(user_data.partner_expectation.partner_body_type_id);
        generatePreSelectedDisabilityArray(user_data.partner_expectation.partner_any_disability_id);
        generatePreSelectedMotherTongueArray(user_data.partner_expectation.partner_mother_tongue_id);
        generatePreSelectedFamilyValueArray(user_data.partner_expectation.partner_family_value_id);
        generatePreSelectedPreferedCountryArray(user_data.partner_expectation.prefered_country_id);
        generatePreSelectedComplexionArray(user_data.partner_expectation.partner_complexion_id);
        generatePreSelectedCasteArray(user_data.partner_expectation.partner_caste_id);
    }

    // Hobbies Change Functions Start

    function handleMaritalStatusChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.marital_status_id);
        });
        setSelectedMaritalStatus(selected_items.toString());
        setPreSelectedMaritalStatus(data);
    }

    function generatePreSelectedMaritalStatusArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            marital_status_data.forEach((x, i) => {
                    if(new_value_array.includes(x.marital_status_id))
                        value_data.push(marital_status_data[i]);
                }
            );
        }
        setPreSelectedMaritalStatus(value_data);
    }

    // Hobbies Change Functions End

    // Education Change Functions Start
    function handleEducationChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.education_id);
        });
        setSelectedEducation(selected_items.toString());
        setPreSelectedEducation(data);
    }

    function generatePreSelectedEducationArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            education_data.forEach((x, i) => {
                    if(new_value_array.includes(x.education_id))
                        value_data.push(education_data[i]);
                }
            );
        }
        setPreSelectedEducation(value_data);
    }

    // Education Change Functions End

    // Profession Change Functions Start

    function handleProfessionChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.occupation_id);
        });
        setSelectedProfession(selected_items.toString());
        setPreSelectedProfession(data);
    }

    function generatePreSelectedProfessionArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            profession_data.forEach((x, i) => {
                    if(new_value_array.includes(x.occupation_id))
                        value_data.push(profession_data[i]);
                }
            );
        }
        setPreSelectedProfession(value_data);
    }

    // Profession Change Functions End

    // Diet Change Functions Start

    function handleDietChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.dietary_habits_id);
        });
        setSelectedDiet(selected_items.toString());
        setPreSelectedDiet(data);
    }

    function generatePreSelectedDietArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            diet_data.forEach((x, i) => {
                    if(new_value_array.includes(x.dietary_habits_id))
                        value_data.push(diet_data[i]);
                }
            );
        }
        setPreSelectedDiet(value_data);
    }

    // Diet Change Functions End

    // BodyType Change Functions Start

    function handleBodyTypeChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.body_type_id);
        });
        setSelectedBodyType(selected_items.toString());
        setPreSelectedBodyType(data);
    }

    function generatePreSelectedBodyTypeArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            body_type_data.forEach((x, i) => {
                    if(new_value_array.includes(x.body_type_id))
                        value_data.push(body_type_data[i]);
                }
            );
        }
        setPreSelectedBodyType(value_data);
    }

    // BodyType Change Functions End

    // Disability Change Functions Start

    function handleDisabilityChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.physically_challenged_id);
        });
        setSelectedDisability(selected_items.toString());
        setPreSelectedDisability(data);
    }

    function generatePreSelectedDisabilityArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            physically_challenged_data.forEach((x, i) => {
                    if(new_value_array.includes(x.physically_challenged_id))
                        value_data.push(physically_challenged_data[i]);
                }
            );
        }
        setPreSelectedDisability(value_data);
    }

    // Disability Change Functions End

    // MotherTongue Change Functions Start

    function handleMotherTongueChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.language_id);
        });
        setSelectedMotherTongue(selected_items.toString());
        setPreSelectedMotherTongue(data);
    }

    function generatePreSelectedMotherTongueArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            language_data.forEach((x, i) => {
                    if(new_value_array.includes(x.language_id))
                        value_data.push(language_data[i]);
                }
            );
        }
        setPreSelectedMotherTongue(value_data);
    }

    // MotherTongue Change Functions End

    // FamilyValue Change Functions Start

    function handleFamilyValueChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.family_value_id);
        });
        setSelectedFamilyValue(selected_items.toString());
        setPreSelectedFamilyValue(data);
    }

    function generatePreSelectedFamilyValueArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            family_value_data.forEach((x, i) => {
                    if(value_array.includes(x.family_value_id))
                        value_data.push(family_value_data[i]);
                }
            );
        }
        setPreSelectedFamilyValue(value_data);
    }

    // FamilyValue Change Functions End

    // PreferedCountry Change Functions Start

    function handlePreferedCountryChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.country_id);
        });
        setSelectedPreferedCountry(selected_items.toString());
        setPreSelectedPreferedCountry(data);
    }

    function generatePreSelectedPreferedCountryArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            country_data.forEach((x, i) => {
                    if(new_value_array.includes(x.country_id))
                        value_data.push(country_data[i]);
                }
            );
        }
        setPreSelectedPreferedCountry(value_data);
    }

    // PreferedCountry Change Functions End

    // Complexion Change Functions Start

    function handleComplexionChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.complexion_id);
        });
        setSelectedComplexion(selected_items.toString());
        setPreSelectedComplexion(data);
    }

    function generatePreSelectedComplexionArray(value_array)
    {
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            complexion_data.forEach((x, i) => {
                    if(new_value_array.includes(x.complexion_id))
                        value_data.push(complexion_data[i]);
                }
            );
        }
        setPreSelectedComplexion(value_data);
    }

    // Complexion Change Functions End

    // Complexion Change Functions Start

    function handleCasteChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.caste_id);
        });
        setSelectedCaste(selected_items.toString());
        setPreSelectedCaste(data);
    }

    function generatePreSelectedCasteArray(value_array)
    {
        //console.log(value_array);
        //console.log(caste_data);
        let value_data = [];
        if(value_array !== null)
        {
            let new_value_array = value_array.split(',');
            if(caste_data.length > 0) 
            {
                caste_data.forEach((x, i) => {
                        if(new_value_array.includes(x.caste_id))
                            value_data.push(caste_data[i]);
                    }
                );
            }
        }
        //console.log(value_data);
        setPreSelectedCaste(value_data);
    }

    // Caste Change Functions End

    function setReligionVal(value)
    {
        setSelectedReligion(value);
    }

    const changeReligion = (event) => {
        getCastesByReligion(event.value);
    };

    function getCastesByReligion(religion_id)
    {
        //console.log(religion_id);
        values['religion_id'] = religion_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_caste_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            //console.log(response.data.caste_data);
            if(response.data.success)
            {
                setCastes(response.data.caste_data);
                setCasteData(response.data.caste_data);
            }
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const MyOption = ({ children, ...props }) => {
        //console.log(children);
        //console.log(props);
        return (
            <components.Option {...props}>
            {children}
            {/* <option value={props.data.hobbies_id}>{props.data.title}</option> */}
            </components.Option>
        );
    };

    return (
        <div>
            {
                (userPartnerExpectationData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_partner_expectation">{t('partner_expectation')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_partner_expectation" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('general_requirement')}:</b> <span title={ userPartnerExpectationData.general_requirement }>{ userPartnerExpectationData.general_requirement }</span></li>

                        <li><b>{t('age')}:</b> <span title={ userPartnerExpectationData.partner_min_age }>{ userPartnerExpectationData.partner_min_age }</span> - <span title={ userPartnerExpectationData.partner_max_age }>{ userPartnerExpectationData.partner_max_age } {t('years')}</span></li>

                        <li><b>{t('height')}:</b> <span title={ userPartnerExpectationData.partner_min_height }>{ userPartnerExpectationData.partner_min_height }</span> - <span title={ userPartnerExpectationData.partner_max_height }>{ userPartnerExpectationData.partner_max_height } {t('foot')}
                        </span></li>

                        <li><b>{t('weight')}:</b> <span title={ userPartnerExpectationData.partner_min_weight }>{ userPartnerExpectationData.partner_min_weight }</span> - <span title={ userPartnerExpectationData.partner_max_weight }>{ userPartnerExpectationData.partner_max_weight } {t('kg')}</span></li>

                        <li><b>{t('marital_status')}:</b> <span title={ userPartnerExpectationData.partner_marital_status }>{ userPartnerExpectationData.partner_marital_status }</span></li>

                        <li><b>{t('with_children_acceptables')}:</b> <span title={ userPartnerExpectationData.with_children_acceptables }>{ userPartnerExpectationData.with_children_acceptables }</span></li>

                        <li><b>{t('country_of_residence_title')}:</b> <span title={ userPartnerExpectationData.partner_country_of_residence_title }>{ userPartnerExpectationData.partner_country_of_residence_title }</span></li>

                        <li><b>{t('religion')}:</b> <span title={ userPartnerExpectationData.partner_religion_title }>{ userPartnerExpectationData.partner_religion_title }</span></li>

                        <li><b>{t('caste/sect')}:</b> <span title={ userPartnerExpectationData.partner_caste_title }>{ userPartnerExpectationData.partner_caste_title }</span></li>

                        <li><b>{t('sub_caste')}:</b> <span title={ userPartnerExpectationData.partner_sub_caste_title }>{ userPartnerExpectationData.partner_sub_caste_title }</span></li>

                        <li><b>{t('education')}:</b> <span title={ userPartnerExpectationData.partner_education_title }>{ userPartnerExpectationData.partner_education_title }</span></li>

                        <li><b>{t('profession')}:</b> <span title={ userPartnerExpectationData.partner_profession_title }>{ userPartnerExpectationData.partner_profession_title }</span></li>

                        <li><b>{t('drinking_habits')}:</b> <span title={ userPartnerExpectationData.partner_drinking_habits_title }>{ userPartnerExpectationData.partner_drinking_habits_title }</span></li>

                        <li><b>{t('smoking_habits')}:</b> <span title={ userPartnerExpectationData.partner_smoking_habits_title }>{ userPartnerExpectationData.partner_smoking_habits_title }</span></li>

                        <li><b>{t('diet')}:</b> <span title={ userPartnerExpectationData.partner_diet_title }>{ userPartnerExpectationData.partner_diet_title }</span></li>

                        <li><b>{t('body_type')}:</b> <span title={ userPartnerExpectationData.partner_body_type_title }>{ userPartnerExpectationData.partner_body_type_title }</span></li>

                        <li><b>{t('personal_value')}:</b> <span title={ userPartnerExpectationData.partner_personal_value }>{ userPartnerExpectationData.partner_personal_value }</span></li>

                        <li><b>{t('manglik')}:</b> <span title={ userPartnerExpectationData.manglik_title }>{ userPartnerExpectationData.manglik_title }</span></li>

                        <li><b>{t('any_disability')}:</b> <span title={ userPartnerExpectationData.partner_any_disability_title }>{ userPartnerExpectationData.partner_any_disability_title }</span></li>

                        <li><b>{t('mother_tongue')}:</b> <span title={ userPartnerExpectationData.partner_mother_tongue_title }>{ userPartnerExpectationData.partner_mother_tongue_title }</span></li>

                        <li><b>{t('family_value')}:</b> <span title={ userPartnerExpectationData.partner_family_value_title }>{ userPartnerExpectationData.partner_family_value_title }</span></li>

                        <li><b>{t('prefered_country')}:</b> <span title={ userPartnerExpectationData.prefered_country_title }>{ userPartnerExpectationData.prefered_country_title }</span></li>

                        <li><b>{t('prefered_state')}:</b> <span title={ userPartnerExpectationData.prefered_state_title }>{ userPartnerExpectationData.prefered_state_title }</span></li>

                        <li><b>{t('prefered_status')}:</b> <span title={ userPartnerExpectationData.prefered_status }>{ userPartnerExpectationData.prefered_status }</span></li>

                        <li><b>{t('complexion')}:</b> <span title={ userPartnerExpectationData.partner_complexion_title }>{ userPartnerExpectationData.partner_complexion_title }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_partner_expectation">
                <div class="modal-dialog modal-lg">
                    <Formik
                        initialValues={{ 
                            general_requirement: userPartnerExpectationData.general_requirement,
                            partner_min_age: userPartnerExpectationData.partner_min_age,
                            partner_max_age: userPartnerExpectationData.partner_max_age,
                            partner_min_height: userPartnerExpectationData.partner_min_height,
                            partner_max_height: userPartnerExpectationData.partner_max_height,
                            partner_min_weight: userPartnerExpectationData.partner_min_weight,
                            partner_max_weight: userPartnerExpectationData.partner_max_weight,
                            partner_marital_status: userPartnerExpectationData.partner_marital_status_id,
                            with_children_acceptables: userPartnerExpectationData.with_children_acceptables_id,
                            partner_country_of_residence: userPartnerExpectationData.partner_country_of_residence_id,
                            partner_religion: userPartnerExpectationData.partner_religion_id,
                            partner_caste: userPartnerExpectationData.partner_caste_id,
                            partner_sub_caste: userPartnerExpectationData.partner_sub_caste_id,
                            partner_complexion: userPartnerExpectationData.partner_complexion_id,
                            partner_education: userPartnerExpectationData.partner_education_id,
                            partner_profession: userPartnerExpectationData.partner_profession_id,
                            partner_drinking_habits: userPartnerExpectationData.partner_drinking_habits_id,
                            partner_smoking_habits: userPartnerExpectationData.partner_smoking_habits_id,
                            partner_diet: userPartnerExpectationData.partner_diet_id,
                            partner_body_type: userPartnerExpectationData.partner_body_type_id,
                            partner_personal_value: userPartnerExpectationData.partner_personal_value,
                            manglik: userPartnerExpectationData.manglik_id,
                            partner_any_disability: userPartnerExpectationData.partner_any_disability_id,
                            partner_mother_tongue: userPartnerExpectationData.partner_mother_tongue_id,
                            partner_family_value: userPartnerExpectationData.partner_family_value_id,
                            prefered_country: userPartnerExpectationData.prefered_country_id,
                            prefered_state: userPartnerExpectationData.prefered_state_id,
                            prefered_status: userPartnerExpectationData.prefered_status
                        }}
                        validationSchema={PartnerExpectationSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('your_likes')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('general_requirement')}:</label>
                                            <input type="text" id="general_requirement" class="form-control" placeholder={t('enter_general_requirement')} name="general_requirement" onChange={formik.handleChange} value={formik.values.general_requirement} />
                                            {formik.errors.general_requirement ? <div className='m-t-10 text-left error'>{formik.errors.general_requirement}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label class="col-md-12 p-0 float-left lb font-15">{t('age')}:</label>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('min')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_min_age" class="form-control" placeholder={t('enter_partner_min_age')} name="partner_min_age" onChange={formik.handleChange} value={formik.values.partner_min_age} />
                                                            {formik.errors.partner_min_age ? <div className='m-t-10 text-left error'>{formik.errors.partner_min_age}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <div className='row'>
                                                        <label class="col-md-12 p-0 float-left lb font-15">&nbsp;</label>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('max')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_max_age" class="form-control" placeholder={t('enter_partner_max_age')} name="partner_max_age" onChange={formik.handleChange} value={formik.values.partner_max_age} />
                                                            {formik.errors.partner_max_age ? <div className='m-t-10 text-left error'>{formik.errors.partner_max_age}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label class="col-md-12 p-0 float-left lb font-15">{t('height')}:</label>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('min')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_min_height" class="form-control" placeholder={t('enter_partner_min_height')} name="partner_min_height" onChange={formik.handleChange} value={formik.values.partner_min_height} />
                                                            {formik.errors.partner_min_height ? <div className='m-t-10 text-left error'>{formik.errors.partner_min_height}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <div className='row'>
                                                        <label class="col-md-12 p-0 float-left lb font-15">&nbsp;</label>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('max')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_max_height" class="form-control" placeholder={t('enter_partner_max_height')} name="partner_max_height" onChange={formik.handleChange} value={formik.values.partner_max_height} />
                                                            {formik.errors.partner_max_height ? <div className='m-t-10 text-left error'>{formik.errors.partner_max_height}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label class="col-md-12 p-0 float-left lb font-15">{t('weight')}:</label>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('min')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_min_weight" class="form-control" placeholder={t('enter_partner_min_weight')} name="partner_min_weight" onChange={formik.handleChange} value={formik.values.partner_min_weight} />
                                                            {formik.errors.partner_min_weight ? <div className='m-t-10 text-left error'>{formik.errors.partner_min_weight}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <div className='row'>
                                                        <label class="col-md-12 p-0 float-left lb font-15">&nbsp;</label>
                                                        <div className='col-md-3'>
                                                            <label class="float-left lb font-15">{t('max')}:</label>
                                                        </div>
                                                        <div className='col-md-9'>
                                                            <input type="text" id="partner_max_weight" class="form-control" placeholder={t('enter_partner_max_weight')} name="partner_max_weight" onChange={formik.handleChange} value={formik.values.partner_max_weight} />
                                                            {formik.errors.partner_max_weight ? <div className='m-t-10 text-left error'>{formik.errors.partner_max_weight}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                    
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('marital_status')}:</label>
                                            <Select
                                                name="partner_marital_status"
                                                id="partner_marital_status"
                                                className="chosen-select sam-select"
                                                value={preSelectedMaritalStatus}
                                                options={marital_status_data}
                                                getOptionLabel ={(option)=>option.name}
                                                getOptionValue ={(option)=>option.marital_status_id}
                                                onChange={handleMaritalStatusChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_marital_status ? <div className='m-t-10 text-left error'>{formik.errors.partner_marital_status}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('with_children_acceptables')}:</label>
                                            <select class="chosen-select" id="with_children_acceptables" name='with_children_acceptables' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {decision_data.map((option, index) => (
                                                    <option key={index} value={option.decision_id} selected={(option.decision_id == userPartnerExpectationData.with_children_acceptables_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.with_children_acceptables ? <div className='m-t-10 text-left error'>{formik.errors.with_children_acceptables}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('country_of_residence')}:</label>
                                            <select class="chosen-select" id="country" name='country' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userPartnerExpectationData.partner_country_of_residence_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.country_of_residence ? <div className='m-t-10 text-left error'>{formik.errors.country_of_residence}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('religion')}:</label>
                                            <select class="chosen-select" id="partner_religion" name='partner_religion' onChange={(event) => {
                                                //console.log(event.target.value);
                                                changeReligion(event.target);
                                                //this.handleChange();
                                                //setCastes(event.target.value);
                                                setReligionVal(event.target.value);
                                            }} >
                                                <option value="">- {t('choose')} -</option>
                                                {religion_data.map((option, index) => (
                                                    <option key={index} value={option.religion_id} selected={(option.religion_id == userPartnerExpectationData.partner_religion_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.partner_religion ? <div className='m-t-10 text-left error'>{formik.errors.partner_religion}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('caste')}:</label>
                                            <Select
                                                name="partner_caste"
                                                id="partner_caste"
                                                className="chosen-select sam-select"
                                                value={preSelectedCaste}
                                                options={caste_data}
                                                getOptionLabel ={(option)=>option.caste_name}
                                                getOptionValue ={(option)=>option.caste_id}
                                                onChange={handleCasteChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_caste ? <div className='m-t-10 text-left error'>{formik.errors.partner_caste}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('sub_caste')}: </label>
                                            <Select
                                                name="books"
                                                id="books"
                                                className="chosen-select sam-select"
                                                value={preSelectedDiet}
                                                //options={diet_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.favourite_book_id}
                                                //onChange={handleDietChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.books ? <div className='m-t-10 text-left error'>{formik.errors.books}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('education')}:</label>
                                            <Select
                                                name="partner_education"
                                                id="partner_education"
                                                className="chosen-select sam-select"
                                                value={preSelectedEducation}
                                                options={education_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.education_id}
                                                onChange={handleEducationChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_education ? <div className='m-t-10 text-left error'>{formik.partner_education.interest}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('profession')}:</label>
                                            <Select
                                                name="profession"
                                                id="profession"
                                                className="chosen-select sam-select"
                                                value={preSelectedProfession}
                                                options={profession_data}
                                                getOptionLabel ={(option)=>option.name}
                                                getOptionValue ={(option)=>option.occupation_id}
                                                onChange={handleProfessionChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.profession ? <div className='m-t-10 text-left error'>{formik.errors.profession}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                    

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('drinking_habits')}:</label>
                                            <select class="chosen-select" id="partner_drinking_habits" name='partner_drinking_habits' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {drinking_habits_data.map((option, index) => (
                                                    <option key={index} value={option.drinking_habits_id} selected={(option.drinking_habits_id == userPartnerExpectationData.partner_drinking_habits_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.partner_drinking_habits ? <div className='m-t-10 text-left error'>{formik.errors.partner_drinking_habits}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('smoking_habits')}:</label>
                                            <select class="chosen-select" id="partner_smoking_habits" name='partner_smoking_habits' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {drinking_habits_data.map((option, index) => (
                                                    <option key={index} value={option.drinking_habits_id} selected={(option.drinking_habits_id == userPartnerExpectationData.partner_smoking_habits_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.smoking_habits ? <div className='m-t-10 text-left error'>{formik.errors.smoking_habits}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('diet')}: </label>
                                            <Select
                                                name="partner_diet"
                                                id="partner_diet"
                                                className="chosen-select sam-select"
                                                value={preSelectedDiet}
                                                options={diet_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.favourite_book_id}
                                                onChange={handleDietChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.diet ? <div className='m-t-10 text-left error'>{formik.errors.diet}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('body_type')}:</label>
                                            <Select
                                                name="partner_body_type"
                                                id="partner_body_type"
                                                className="chosen-select sam-select"
                                                value={preSelectedBodyType}
                                                options={body_type_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.body_type_id}
                                                onChange={handleBodyTypeChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.body_type ? <div className='m-t-10 text-left error'>{formik.errors.body_type}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('personal_value')}:</label>
                                            <input type="text" id="partner_personal_value" class="form-control" placeholder={t('enter_personal_value')} name="partner_personal_value" onChange={formik.handleChange} value={formik.values.partner_personal_value} />
                                            {formik.errors.partner_personal_value ? <div className='m-t-10 text-left error'>{formik.errors.partner_personal_value}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('manglik')}:</label>
                                            <select class="chosen-select" id="manglik" name='manglik' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {decision_data.map((option, index) => (
                                                    <option key={index} value={option.decision_id} selected={(option.decision_id == userPartnerExpectationData.manglik_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.manglik ? <div className='m-t-10 text-left error'>{formik.errors.manglik}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('any_disability')}:</label>
                                            <Select
                                                name="partner_any_disability"
                                                id="partner_any_disability"
                                                className="chosen-select sam-select"
                                                value={preSelectedDisability}
                                                options={physically_challenged_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.physically_challenged_id}
                                                onChange={handleDisabilityChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_any_disability ? <div className='m-t-10 text-left error'>{formik.errors.partner_any_disability}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('mother_tongue')}:</label>
                                            <Select
                                                name="partner_mother_tongue"
                                                id="partner_mother_tongue"
                                                className="chosen-select sam-select"
                                                value={preSelectedMotherTongue}
                                                options={language_data}
                                                getOptionLabel ={(option)=>option.name}
                                                getOptionValue ={(option)=>option.language_id}
                                                onChange={handleMotherTongueChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_mother_tongue ? <div className='m-t-10 text-left error'>{formik.errors.partner_mother_tongue}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('family_value')}: </label>
                                            <Select
                                                name="partner_family_value"
                                                id="partner_family_value"
                                                className="chosen-select sam-select"
                                                value={preSelectedFamilyValue}
                                                options={family_value_data}
                                                getOptionLabel ={(option)=>option.name}
                                                getOptionValue ={(option)=>option.family_value_id}
                                                onChange={handleFamilyValueChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_family_value ? <div className='m-t-10 text-left error'>{formik.errors.partner_family_value}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('prefered_country')}:</label>
                                            <Select
                                                name="prefered_country"
                                                id="prefered_country"
                                                className="chosen-select sam-select"
                                                value={preSelectedPreferedCountry}
                                                options={country_data}
                                                getOptionLabel ={(option)=>option.name}
                                                getOptionValue ={(option)=>option.country_id}
                                                onChange={handlePreferedCountryChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.prefered_country ? <div className='m-t-10 text-left error'>{formik.errors.prefered_country}</div> : null}
                                        </div>
                                    </div>
                                    <div className='clear'></div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('prefered_status')}: </label>
                                            <input type="text" id="prefered_status" class="form-control" placeholder={t('enter_prefered_status')} name="prefered_status" onChange={formik.handleChange} value={formik.values.prefered_status} />
                                            {formik.errors.prefered_status ? <div className='m-t-10 text-left error'>{formik.errors.prefered_status}</div> : null}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('complexion')}:</label>
                                            <Select
                                                name="partner_complexion"
                                                id="partner_complexion"
                                                className="chosen-select sam-select"
                                                value={preSelectedComplexion}
                                                options={complexion_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.complexion_id}
                                                onChange={handleComplexionChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {formik.errors.partner_complexion ? <div className='m-t-10 text-left error'>{formik.errors.partner_complexion}</div> : null}
                                        </div>
                                    </div>                                    

                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default PartnerExpectation