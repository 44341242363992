import {React, useEffect} from 'react'

import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Processing from '../../components/common/processing';
import Footer from '../../components/common/footer';

function Logout()
{
    let temp_common_data = JSON.parse(COMMONFUNCTIONS.getSession('common_data'));
    localStorage.clear();
    sessionStorage.clear();
    //COMMONFUNCTIONS.setSession('common_data', temp_common_data);
    //COMMONFUNCTIONS.setSession('isUserLoggedIn', false);
    //let loggedIn = COMMONFUNCTIONS.getSession('isUserLoggedIn');

    COMMONFUNCTIONS.commonMessageInfoToast('You are successfully logged-out.');
    COMMONFUNCTIONS.RedirectToPage('/index.html');

    return <>
        <Top/>
        <Header/>
        <Processing/>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <Footer />
    </>
}

export default Logout