import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

function BasicInformation(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [marital_status_data] = useState(common_data.common_data.marital_status_data);
    const [onbehalf_data] = useState(common_data.common_data.onbehalf_data);

    //const [userData, ] = useState(props.userData);
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userBasicData, setUserBasicData] = useState(userData.basic_info);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const inputElement = React.useRef()
    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
        {
            setAllUsersData(userData);
        }
    }, [userData])

    const BasicInfoSchema = Yup.object({
        marital_status: Yup.string().required('Required'),
        on_behalf: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_basic_info";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            //console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                    /* let temp_user_data = JSON.parse(COMMONFUNCTIONS.getSession('UserDetails'));
                    //console.log(temp_user_data);
                    setUserBasicData(temp_user_data.basic_info); */
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        //console.log(user_data.basic_info);
        setUserBasicData(user_data.basic_info);
    }

    return (
        <div>
            {
                (userBasicData) ? <div class="pr-bio-c pr-bio-info">
                <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_basic_information">Basic Information</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_basic_information" aria-hidden="true"></i></h3>
                <ul>
                    <li><b>First Name:</b> <span title={ userData.first_name }>{ userData.first_name }</span></li>

                    <li><b>Last Name:</b> <span title={ userData.last_name }>{ userData.last_name }</span></li>

                    <li><b>Gender:</b> <span title={ userData.gender_title }>{ userData.gender_title }</span></li>

                    { (userData.date_of_birth) ? <li><b>Date Of Birth:</b> <span title={ userData.date_of_birth }>{ userData.date_of_birth } ({ userData.age } Years)</span></li> : '' }

                    <li><b>Mobile:</b> <span title={ userData.mobile }>{ userData.mobile }</span></li>

                    { (userData.email) ? <li><b>Email:</b> <span title={ userData.email }>{ userData.email }</span></li> : '' }

                    { (userBasicData.on_behalf_title) ? <li><b>Profile Manage By:</b> <span title={ userBasicData.on_behalf_title }>{ userBasicData.on_behalf_title }</span></li> : '' }
                    
                    <li><b>Marital Status:</b> <span title={ userBasicData.marital_status_title }>{ userBasicData.marital_status_title }</span></li>

                    
                    <li><b>Number Of Children:</b> <span title={ userBasicData.number_of_children }>{ userBasicData.number_of_children }</span></li>

                    { (userBasicData.area) ? <li><b>Area:</b> <span title={ userBasicData.area }>{ userBasicData.area }</span></li> : '' }
                </ul>
            </div> : ''
            }

            <div class="modal fade" id="user_basic_information">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  marital_status: userBasicData.marital_status_id, number_of_children: userBasicData.number_of_children, on_behalf: userBasicData.on_behalf_id, area: userBasicData.area }}
                        validationSchema={BasicInfoSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">Basic Information</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {/* <div class="form-group">
                                            <label for="pro_about">First Name</label>
                                            <input type="text" id="first_name" class="form-control" placeholder="Enter your first name" name="first_name" onChange={formik.handleChange} value={formik.values.first_name} />
                                            {formik.errors.first_name ? <div className='m-t-10 text-left error'>{formik.errors.first_name}</div> : null}
                                        </div> */}
                                        <div class="form-group">
                                            <label class="float-left lb font-15">First Name:</label>
                                            <div className='float-left p-l-10'>{ userData.first_name }</div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Last Name: </label>
                                            <div className='float-left p-l-10'>{ userData.last_name }</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Gender:</label>
                                            <div className='float-left p-l-10'>{ userData.gender_title }</div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Date Of Birth: </label>
                                            <div className='float-left p-l-10'>{ userData.date_of_birth }</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Mobile:</label>
                                            <div className='float-left p-l-10'>{ userData.mobile }</div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Email: </label>
                                            <div className='float-left p-l-10'>{ userData.email }</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Profile Manage By:</label>
                                            <select class="chosen-select" id="on_behalf" name='on_behalf' onChange={formik.handleChange}>
                                                <option value="">- Choose -</option>
                                                {onbehalf_data.map((option, index) => (
                                                    <option key={index} value={option.on_behalf_id} selected={(option.on_behalf_id == userBasicData.on_behalf_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.on_behalf ? <div className='m-t-10 text-left error'>{formik.errors.on_behalf}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Marital Status:</label>
                                            <select class="chosen-select" id="marital_status" name='marital_status' onChange={formik.handleChange} value={formik.values.marital_status}>
                                                <option value="">- Choose -</option>
                                                {marital_status_data.map((option, index) => (
                                                    <option key={index} value={option.marital_status_id} selected={(option.marital_status_id == userBasicData.marital_status_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.marital_status ? <div className='m-t-10 text-left error'>{formik.errors.marital_status}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                    <div class="form-group">
                                            <label class="float-left lb font-15">Number Of Children: </label>
                                            <input type="text" id="number_of_children" class="form-control" placeholder="Enter number of children" name="number_of_children" onChange={formik.handleChange} value={formik.values.number_of_children} />
                                            {formik.errors.number_of_children ? <div className='m-t-10 text-left error'>{formik.errors.number_of_children}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">Area: </label>
                                            <input type="text" id="area" class="form-control" placeholder="Enter area" name="area" onChange={formik.handleChange} value={formik.values.area} />
                                            {formik.errors.area ? <div className='m-t-10 text-left error'>{formik.errors.area}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default BasicInformation