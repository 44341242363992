import React from 'react'
import * as CONSTANTS from '../../Constants';

import Top from '../common/top';
import Header from '../common/header';
import Search from '../common/search';
import Quick_Access from '../common/quick_access';
import Trust_Brands from '../common/trust_brands';
import Why_Us from '../common/why_us';
import About_Short from '../common/about_short';
import Counts from '../common/counts';
import FAQItems from '../common/faq_items';
import Moments from '../common/moments';
import Happy_Stories from '../common/happy_stories';
import Photo_Gallery from '../common/photo_gallery';
import Blog_Articles from '../common/blog_articles';
import Home_Register_Banner from '../common/home_register_banner';


import Footer from '../common/footer';
/*import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';*/


const home = props => {
    return (
        <div>
            <Top/>
            <Header/>
            {/* BANNER
                <section>
                    <div class="str">
                        <div class="ban-inn ab-ban">
                            <div class="container">
                                <div class="row">
                                    <div class="hom-ban">
                                        <div class="ban-tit">
                                            <span><i class="no1">#1</i> Wedding Website</span>
                                            <h1>About us</h1>
                                            <p>Most Trusted and premium Matrimony Service in the World.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* END */}

                {/* START */}
                <Why_Us container_class={'ban-inn ab-ban'} />
                {/* END */}

                {/* START */}
                <About_Short />
                {/* END */}

                {/* START */}
                <Counts />
                {/* END */}

                {/* RECENT COUPLES */}
                {/* <section>
                    <div class="hom-partners abo-partners" id="testimonials">
                        <div class="container">
                            <div class="row">
                                <div class="sub-tit-caps">
                                    <h2>Customer <span class="animate animate__animated" data-ani="animate__flipInX"
                                            data-dely="0.1">Testimonials</span></h2>
                                    <p>Fusce imperdiet ullamcorper fringilla.</p>
                                </div>

                                <div class="wedd-shap">
                                    <span class="abo-shap-1"></span>
                                    <span class="abo-shap-3"></span>
                                </div>

                                <div id="demo" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <ul>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>John Smith</h4>
                                                                <span>IT Profession</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>Julia Ann</h4>
                                                                <span>Teacher</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/7.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>William Son</h4>
                                                                <span>Government Staff</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="carousel-item">
                                            <ul>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>Johny Smith</h4>
                                                                <span>IT Profession</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>Juliya Ann</h4>
                                                                <span>Teacher</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ab-testmo">
                                                        <div class="ab-test-rat">
                                                            <div class="ab-test-star">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                                                <span>(50 Reviews)</span>
                                                            </div>
                                                            <div class="ab-test-conte">
                                                                <p>It is a long established fact that a reader will be distracted by
                                                                    the readable content of a page when looking at its layout.</p>
                                                            </div>
                                                        </div>
                                                        <div class="ab-rat-user">
                                                            <img src={"/assets/images/profiles/7.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <div>
                                                                <h4>Williamy Son</h4>
                                                                <span>Government Staff</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#demo" data-slide="prev">
                                        <span class="carousel-control-prev-icon"></span>
                                    </a>
                                    <a class="carousel-control-next" href="#demo" data-slide="next">
                                        <span class="carousel-control-next-icon"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* END */}


                {/* START */}
                {/* <section>
                    <div class="ab-team pg-abo-ab-team">
                        <div class="container">
                            <div class="row">
                                <div class="home-tit">
                                    <p>OUR PROFESSIONALS</p>
                                    <h2><span>Meet Our Team</span></h2>
                                    <span class="leaf1"></span>
                                </div>
                                <ul>
                                    <li>
                                        <div>
                                            <img src={"/assets/images/profiles/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" />
                                            <h4>Ashley Jen</h4>
                                            <p>Marketing Manager</p>
                                            <ul class="social-light">
                                                <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <img src={"/assets/images/profiles/7.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" />
                                            <h4>Ashley Jen</h4>
                                            <p>Marketing Manager</p>
                                            <ul class="social-light">
                                                <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <img src={"/assets/images/profiles/8.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" />
                                            <h4>Emily Arrov</h4>
                                            <p>Creative Manager</p>
                                            <ul class="social-light">
                                                <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <img src={"/assets/images/profiles/9.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" />
                                            <h4>Julia sear</h4>
                                            <p>Client Coordinator</p>
                                            <ul class="social-light">
                                                <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* END */}

                {/* START */}
                <section>
                    <div class="ab-faq" id="faq">

                        <div class="container">
                            <div class="row">
                                {/* SUB TITLE */}
                                <div class="sub-tit-caps">
                                    <h2>KB <span class="animate animate__animated" data-ani="animate__flipInX"
                                            data-dely="0.1">knowledge base</span></h2>
                                    <p>Frequently Asked Questions</p>
                                </div>
                                {/* TESTMONIAL BACKGROUND SHAPES */}
                                <div class="wedd-shap">
                                    <span class="abo-shap-1"></span>
                                    <span class="abo-shap-2"></span>
                                    <span class="abo-shap-4"></span>
                                    <span class="abo-shap-5"></span>
                                </div>
                                <div class="col-lg-6">
                                    <div class="ab-faq-lhs">
                                        <FAQItems />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="ab-faq-rhs">
                                        <img src={"/assets/images/about/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END */}
            <Footer/>
        </div>
    )
}

home.propTypes = {

}

export default home
