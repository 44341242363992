import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

let rest_params = {};

function FAQItems(props)
{
    const[faqContent, setFaqContent] = useState('');
    const[isLoading, setIsLoading] = useState(true);

    function getFaqItems()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/faq', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.content);
                if(responce.data.success)
                {
                    setFaqContent(responce.data.content);
                }
                setIsLoading(false);
            })
            .catch(error => {
                //console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            //console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    const loadItems = (items) => {
        //console.log(items);
        if(items.length > 0)
        {
            let json_items = JSON.parse(items);
            //console.log(json_items);
            return (
                json_items.map((item, index) => {
                    //console.log(item);

                    return(
                        <div class="card">
                            <div class="card-header">
                                <a class={(index != 0) ? "collapsed card-link" : "card-link" } data-toggle="collapse" href={"#collapse"+index}>{item.question}</a>
                            </div>
                            <div id={"collapse"+index} class={(index == 0) ? "collapse show" : "collapse" } data-parent="#accordion">
                                <div class="card-body">
                                    <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
        }
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
        //if(pageContent === ''   )
            getFaqItems();
    }, [faqContent])

    return (
            <div id="accordion" class="text-left">
                { (isLoading) ? <center>Loading...</center> : loadItems(faqContent)}
                {/* <loadItems items={faqContent} /> */}
            </div>
        )
}

export default FAQItems