import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function Language(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [language_data] = useState(common_data.common_data.language_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userLanguageData, setUserLanguageData] = useState(userData.language);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const LanguageSchema = Yup.object({
        mother_tongue: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_language";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserLanguageData(user_data.language);
    }

    return (
        <div>
            {
                (userLanguageData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_language">{t('language')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_language" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>Mother Tongue:</b> <span title={ userLanguageData.mother_tongue_title }> { userLanguageData.mother_tongue_title }</span></li>

                        <li><b>Language:</b> <span title={ userLanguageData.language_title }> { userLanguageData.language_title }</span></li>

                        <li><b>Speak:</b> <span title={ userLanguageData.speak_title }> { userLanguageData.speak_title }</span></li>

                        <li><b>Read:</b> <span title={ userLanguageData.read_title }> { userLanguageData.read_title }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_language">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  mother_tongue: userLanguageData.mother_tongue_id, language: userLanguageData.language_id, speak: userLanguageData.speak_id, read: userLanguageData.read_id }}
                        validationSchema={LanguageSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('language')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('mother_tongue')}:</label>
                                            <select class="chosen-select" id="mother_tongue" name='mother_tongue' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {language_data.map((option, index) => (
                                                    <option key={index} value={option.language_id} selected={(option.language_id == userLanguageData.mother_tongue_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.mother_tongue ? <div className='m-t-10 text-left error'>{formik.errors.mother_tongue}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('language')}:</label>
                                            <select class="chosen-select" id="language" name='language' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {language_data.map((option, index) => (
                                                    <option key={index} value={option.language_id} selected={(option.language_id == userLanguageData.language_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.language ? <div className='m-t-10 text-left error'>{formik.errors.language}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('speak')}:</label>
                                            <select class="chosen-select" id="speak" name='speak' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {language_data.map((option, index) => (
                                                    <option key={index} value={option.language_id} selected={(option.language_id == userLanguageData.speak_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.speak ? <div className='m-t-10 text-left error'>{formik.errors.speak}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('read')}: </label>
                                            <select class="chosen-select" id="read" name='read' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {language_data.map((option, index) => (
                                                    <option key={index} value={option.language_id} selected={(option.language_id == userLanguageData.read_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.read ? <div className='m-t-10 text-left error'>{formik.errors.read}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Language