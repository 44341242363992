import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import SubmitLoader from '../../components/common/submit_loader';

let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;

function MyPackage()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoggedIn, checkLoginStatus] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [picPrivacyData, setPicPrivacyData] = useState(userData.pic_privacy_data[0]);

    const SignupSchema = Yup.object({
        profile_pic_show: Yup.string().required('Required'),
        gallery_show: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        values['para1'] = 'update_pic_privacy';
        values['user_id'] = user_id;
        console.log(values);
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                COMMONFUNCTIONS.fetchLoggedInUserProfileData(user_id);
                setUserData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                setPicPrivacyData(userData.pic_privacy_data[0]);
            }
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);

            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);                
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
        setSubmitting(false);
    }

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
                <section>
                    <div class="profi-pg profi-ban">
                        <div class="">
                            <div class="">
                                <div class="profile">
                                    <div class="pg-pro-big-im">
                                        <div class="s1">
                                            {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                            { getProfileImage(userData.profile_image) }
                                        </div>
                                    </div>
                                </div>
                                <div class="profi-pg profi-bio">
                                    <div class="lhs">
                                        <div class="pro-pg-intro">
                                            <h1 className='header'>{t('picture_privacy_settings')}<Link className="float-right" to="/my-profile.html"><i class="fa fa-times" aria-hidden="true"></i></Link></h1>
                                        </div>
                                    </div>

                                    {/* PROFILE lHS */}
                                    <div class="rhs">
                                        <div className='row'>
                                            <div className='col-md-11 m-t-20 p-20 gray-border'>
                                                <Formik
                                                    initialValues={{  profile_pic_show: picPrivacyData.profile_pic_show, gallery_show: picPrivacyData.gallery_show }}
                                                    validationSchema={SignupSchema}
                                                    onSubmit={SubmitForm}
                                                    >
                                                {formik => (
                                                    <form onSubmit={formik.handleSubmit} className='w-95'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('profile_picture')}:</label>
                                                            <select class="chosen-select" id="profile_pic_show" name='profile_pic_show' onChange={formik.handleChange}>
                                                                <option value="only_me" selected={(picPrivacyData.profile_pic_show == 'only_me') ? 'selected' : '' }>{t('only_me')}</option>
                                                                <option value="all" selected={(picPrivacyData.profile_pic_show == 'all') ? 'selected' : '' }>{t('all_member')}</option>
                                                                <option value="premium" selected={(picPrivacyData.profile_pic_show == 'premium') ? 'selected' : '' }>{t('premium_member')}</option>
                                                            </select>
                                                            {formik.errors.profile_pic_show ? <div className='m-t-10 text-left error'>{formik.errors.profile_pic_show}</div> : null}
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="lb">{t('gallery_images')}:</label>
                                                            <select class="chosen-select" id="gallery_show" name='gallery_show' onChange={formik.handleChange}>
                                                                <option value="only_me" selected={(picPrivacyData.gallery_show == 'only_me') ? 'selected' : '' }>{t('only_me')}</option>
                                                                <option value="all" selected={(picPrivacyData.gallery_show == 'all') ? 'selected' : '' }>{t('all_member')}</option>
                                                                <option value="premium" selected={(picPrivacyData.gallery_show == 'premium') ? 'selected' : '' }>{t('premium_member')}</option>
                                                            </select>
                                                            {formik.errors.gallery_show ? <div className='m-t-10 text-left error'>{formik.errors.gallery_show}</div> : null}
                                                        </div>
                                                        <div className='text-right'>
                                                            <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                                        </div>
                                                        
                                                    </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END PROFILE lHS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END PROFILE */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default MyPackage