import React, { useEffect, useState } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next'
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'  

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../common/top';
import Header from '../common/header';
import Processing from '../common/processing';
import Footer from '../common/footer';
import SubmitLoader from '../common/submit_loader';

import "react-datepicker/dist/react-datepicker.css";

let old_date_m = new Date(new Date().setFullYear(new Date().getFullYear() - 21))
let dd_m = String(old_date_m.getDate()).padStart(2, '0');
let mm_m = String(old_date_m.getMonth()+1).padStart(2, '0'); //January is 0!
let yyyy_m = old_date_m.getFullYear();

/* console.log(yyyy_m);
console.log(mm_m);
console.log(dd_m); */

let default_birth_date_m = new Date(yyyy_m+'-'+mm_m+'-'+dd_m);//new Date('2011-09-24');//new Date(yyyy_m, mm_m, dd_m);

console.log(default_birth_date_m);

let old_date_f = new Date(new Date().setFullYear(new Date().getFullYear() - 19))
let dd_f = String(old_date_f.getDate()).padStart(2, '0');
let mm_f = String(old_date_f.getMonth()).padStart(2, '0'); //January is 0!
let yyyy_f = old_date_f.getFullYear();

let default_birth_date_f = new Date(yyyy_f+'-'+mm_f+'-'+dd_f);//new Date(yyyy_f, mm_f, dd_f);

let temp_date_of_birth = '';

function Register(props)
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoggedIn, checkLoginStatus] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    const [submitBtnText, changeSubmitBtnText] = useState(t('create_account'));
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [gender_data] = useState(common_data.common_data.gender_data);
    const [onbehalf_data] = useState(common_data.common_data.onbehalf_data);
    const [startDate, setStartDate] = useState(default_birth_date_m);
    const [max_date_of_birth, setMax_date_of_birth] = useState(default_birth_date_m);
    const [genderVal, setGenderVal] = useState(1);

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    })

    function blockSpecialChar(evt, obj){
        /*evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode == 46)) {
            return false;
        }
        return true;*/

        console.log(evt);
        console.log(evt.key);

        var data = evt.key;

        var regex = new RegExp("^[0-9]$");
        var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
        else if(data.Length >= 10)
        {
            evt.preventDefault();
            return false;
        }
    }

    const RegisterSchema = Yup.object({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        //gender: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address'),
        //date_of_birth: Yup.string().required('Required'),
        //on_behalf: Yup.string().required('Required'),
        mobile: Yup.string().required('Required').min(10, 'Mobile must be exactly 10 digits').max(10, 'Mobile must be exactly 10 digits'),
        password: Yup.string()
            .min(4, 'Must be 4 characters or more')
            .required('Required'),
        confirm_password: Yup
            .string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    function changeMaxDOB(value) {
        if( value == 2 )
        {
            setStartDate(default_birth_date_f);
            setMax_date_of_birth(default_birth_date_f);
        }
        else
        {
            setStartDate(default_birth_date_m);
            setMax_date_of_birth(default_birth_date_m);
        }
    }

    if(isLoggedIn)
    {
        COMMONFUNCTIONS.commonMessageInfoToast('You are already logged-in.');
        COMMONFUNCTIONS.GoToPage(navigate, '/index.html');
    }

    const SubmitForm = (values, { setSubmitting }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        values['date_of_birth'] = startDate;
        values['gender'] = genderVal;
        console.log(values);
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/register',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageInfoToast('You are registered successfully.');
                    COMMONFUNCTIONS.GoToPage(navigate, '/login.html');
                }
                else
                {
                    var error_message = response.data.error.replace(/[^a-zA-Z. ]/g, "");
                    error_message = error_message.replace('.n', '.');
                    COMMONFUNCTIONS.commonMessageErrorToast(error_message);
                    changeSubmitBtnText(t('create_account'));
                    changeSubmitBtnStatus(false);
                }
            })
            .catch(error => {
                console.log(error);
                //if(error.response.data.message.length > 0)
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                changeSubmitBtnText(t('create_account'));
                changeSubmitBtnStatus(false);
            });
            setSubmitting(false);
    }

    return <>
        <Top/>
        <Header/>
        {/* REGISTER */}
            <section>
                <div class="login">
                    <div class="container">
                        <div class="row">

                            <div class="inn">
                                <div class="lhs">
                                    <div class="tit">
                                        <h2>{t('now')} <b>{t('find_your_life_partner')}</b> {t('easy_and_fast')}.</h2>
                                    </div>
                                    <div class="im">
                                        <img src={"/assets/images/login-couple.png?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                    </div>
                                    <div class="log-bg">&nbsp;</div>
                                </div>
                                <div class="rhs p-l-40 p-r-40">
                                    <div>
                                        <div class="form-tit">
                                            <h4>{t('start_for_free')}</h4>
                                            <h1>{t('sign_up_to_matrimony')}</h1>
                                            <p>{t('already_a_member')}? <Link to="/login.html">{t('login')}</Link></p>
                                        </div>
                                        <div class="form-login">
                                            <Formik
                                                initialValues={{  first_name: '', last_name: '', gender: '', email: '', date_of_birth: '', on_behalf: '', mobile: '', password: '', confirm_password: '' }}
                                                validationSchema={RegisterSchema}
                                                onSubmit={SubmitForm}
                                                >
                                            {formik => (
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('first_name')}:</label>
                                                            <input type="text" class="form-control" placeholder={t('enter_your_first_name')} name="first_name" value={formik.values.first_name} onChange={formik.handleChange} />
                                                            {formik.errors.first_name ? <div className='m-t-10 text-left error'>{formik.errors.first_name}</div> : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('last_name')}:</label>
                                                            <input type="text" class="form-control" placeholder={t('enter_your_last_name')} name="last_name" value={formik.values.last_name} onChange={formik.handleChange} />
                                                            {formik.errors.last_name ? <div className='m-t-10 text-left error'>{formik.errors.last_name}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('gender')}:</label>
                                                            <select class="chosen-select" name="gender" onChange={(event) => {
                                            //props.setFieldValue('gender', itemValue);
                                            /* this.setState({selectedValue: itemValue}); */
                                            /* props.handleChange(event); */
                                            console.log(event.target.value);
                                            changeMaxDOB(event.target.value);
                                            setGenderVal(event.target.value);
                                        }}>
                                                                {gender_data.map((option, index) => (
                                                                    <option key={index} value={option.gender_id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.errors.gender ? <div className='m-t-10 text-left error'>{formik.errors.gender}</div> : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('email')}:</label>
                                                            <input type="email" class="form-control" id="email"
                                                                placeholder={t('enter_email')} name="email" value={formik.values.email} onChange={formik.handleChange} />
                                                            {formik.errors.email ? <div className='m-t-10 text-left error'>{formik.errors.email}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('date_of_birth')}:</label>
                                                            {/* <input type="text" class="form-control" placeholder={t('enter_your_first_name')}
                                                                name="name" /> */}
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={startDate}
                                                                onChange={(date) => {setStartDate(date);console.log(date);/*  date.handleChange; */}}
                                                                maxDate={max_date_of_birth}  
                                                                showYearDropdown
                                                                dateFormatCalendar="MMMM"
                                                                yearDropdownItemNumber={50}
                                                                scrollableYearDropdown
                                                                className="form-control"
                                                                name='date_of_birth'
                                                                //value={formik.values.date_of_birth}
                                                            />
                                                            {formik.errors.date_of_birth ? <div className='m-t-10 text-left error'>{formik.errors.date_of_birth}</div> : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('mobile')}:</label>
                                                            <input type="number" class="form-control" id="mobile"
                                                                placeholder={t('enter_mobile_number')} name="mobile" value={formik.values.mobile} onChange={formik.handleChange} pattern="\d{10}" minLength = "10" maxLength = "10" onInvalid="this.setCustomValidity('Please enter 10 digit mobile number.')" onInput={(event) => {if (event.target.value.length >= 10) { event.target.value = event.target.value.slice(0, 10); console.log('if'); /* this.setCustomValidity('') */ } else if (event.target.value.length < 10) {  console.log('else');/* this.setCustomValidity('Please enter 10 digit mobile number.'); */ }}}  onKeyPress={(event) => {return blockSpecialChar(event)}} />
                                                            {formik.errors.mobile ? <div className='m-t-10 text-left error'>{formik.errors.mobile}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="lb">{t('on_behalf')}:</label>
                                                    <select class="chosen-select" id="on_behalf" name='on_behalf' onChange={formik.handleChange}>
                                                        <option value="">- Choose -</option>
                                                        {onbehalf_data.map((option, index) => (
                                                            <option key={index} value={option.on_behalf_id}>{option.name}</option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.on_behalf ? <div className='m-t-10 text-left error'>{formik.errors.on_behalf}</div> : null}
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('password')}:</label>
                                                            <input type="Password" class="form-control" id="password"
                                                                placeholder={t('enter_password')} name="password" value={formik.values.password} onChange={formik.handleChange} />
                                                            {formik.errors.password ? <div className='m-t-10 text-left error'>{formik.errors.password}</div> : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div class="form-group">
                                                            <label class="lb">{t('confirm_password')}:</label>
                                                            <input type="Password" class="form-control" id="confirm_password"
                                                                placeholder={t('enter_confirm_password')} name="confirm_password" value={formik.values.confirm_password} onChange={formik.handleChange} />
                                                            {formik.errors.confirm_password ? <div className='m-t-10 text-left error'>{formik.errors.confirm_password}</div> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group form-check">
                                                    <label class="form-check-label">
                                                        {t('by_clicking_register_you_agree_to_our')} <a target='_blank' href="/terms_and_conditions.html">{t('terms_and_conditions')}</a>.
                                                    </label>
                                                </div>
                                                {/* <button type="submit" class="btn btn-primary">{t('create_account')}</button> */}
                                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                            </form>
                                            )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <Footer />
    </>
}

export default Register