import React, { useState, useEffect } from 'react'

import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from 'react-router-dom';

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
 
let temp_search_params = {};
let final_search_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;
let page = 1;
let rcount = 0;
const fetchData = (setItems, items) => {

  final_search_params = temp_search_params;
  final_search_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
  final_search_params['count'] = rcount;
  final_search_params['method'] = 'search';
    //axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=10`)
    axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/ajax_member_list',final_search_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
   .then((res) => {
    console.log(res);
     setItems([...items, ...res.data.records]);
     page = page + 1;
     rcount = rcount + 10;
     console.log(rcount);
   });
};
 
const refresh = (setItems) => {};
 
export default function Scroll() {
 const [items, setItems] = React.useState([]);
const navigate = useNavigate();

 function gotoUserProfile(member_id)
{
    //console.log(member_id);
    navigate("/profile-details.html", { state: {member_id : member_id}, replace: true });
}

function getProfileImage(img_obj)
    {
		if(img_obj !== undefined)
		{
            //console.log(img_obj);
			let image_json_data = JSON.parse(img_obj);

			if(image_json_data[0].profile_image.length > 1)
				image_name = image_json_data[0].profile_image;
		}

		let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }
 
 React.useEffect(()=>{
   fetchData(setItems,items)
 },[])
 return (
   <InfiniteScroll
     dataLength={items.length} //This is important field to render the next data
     next={() => {
       fetchData(setItems, items);
     }}
     hasMore={true}
     loader={<h4>Loading...</h4>}
     endMessage={
       <p style={{ textAlign: "center" }}>
         <b>Yay! You have seen it all</b>
       </p>
     }
     // below props only if you need pull down functionality
     refreshFunction={refresh}
     pullDownToRefresh
     pullDownToRefreshThreshold={50}
     pullDownToRefreshContent={
       <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
     }
     releaseToRefreshContent={
       <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
     }
   >
     <div style={{ minHeight: "100vh" }}>
     {items.map(item => (
            <li>
                <div class="all-pro-box" data-useravil="avilno" data-aviltxt="Offline">
                    <div class="pro-img">
                        <a onClick={() => {gotoUserProfile(item.member_id)}}>
                            {/* <img src="/assets/images/profiles/10.jpg" alt="" /> */}
                            { getProfileImage(item.profile_image) }
                        </a>
                        <div class="pro-ave" title="User currently available">
                            <span class="pro-ave-yes"></span>
                        </div>
                        {/* <div class="pro-avl-status">
                            <span class="marqu">Last login 10 mins ago | I'll be available on 10:00
                                AM</span>
                        </div> */}
                    </div>

                    <div class="pro-detail">
                        <h4><a onClick={() => {gotoUserProfile(item.member_id)}}>{item.first_name} {item.last_name} ( {item.member_id} )</a></h4>
                        <div class="pro-bio">
                            <span>{item.education_title}</span>
                            <span>{item.occupation_title}</span>
                            <span>{item.calculated_age} Yeard old</span>
                            <span>{item.religion_title}</span>
                        </div>

                        <div class="links">
                            <a href="#!" title='Express Interest'><i class="fa fa-heart"></i></a>
                            <a href="#!" title='Shortlist'><i class="fa fa-list-ul"></i></a>
                            <a href="#!" title='Follow'><i class="fa fa-star"></i></a>
                            <a href="#!" title='Ignore Profile'><i class="fa fa-ban"></i></a>
                            <a title="Go to profile" onClick={() => {gotoUserProfile(item.member_id)}}><i class="fa fa-id-card"></i></a>
                        </div>
                    </div>

                    <span class="enq-sav" data-toggle="tooltip"
                        title="Click to save this provile."><i class="fa fa-thumbs-o-up"
                            aria-hidden="true"></i></span>

                </div>
            </li>
        ))}
     </div>
   </InfiniteScroll>
 );
}