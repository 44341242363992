import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

import {Link} from "react-router-dom";

function Why_Us(props)
{
    return (
        <div>
            {/* BANNER */}
            <section>
                <div class="str">
                    <div class={props.container_class}>
                        <div class="container">
                            <div class="row">
                                <div class="hom-ban">
                                    <div class="ban-tit">
                                        <span><i class="no1">#1</i> Wedding Website</span>
                                        <h2>Why Choose Us</h2>
                                        <p>Most Trusted and premium Matrimony Service in the India.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* START */}
            <section>
                <div class="ab-sec2">
                    <div class="container">
                        <div class="row">
                            <ul>
                                <li>
                                    <div class="animate animate__animated animate__slower" data-ani="animate__flipInX"
                                        data-dely="0.1">
                                        <img src={"/assets/images/icon/prize.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                        <h4>Real profiles</h4>
                                        <p>We build trust with authenticated profiles. We validate profiles with photo ID and documents.</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="animate animate__animated animate__slower" data-ani="animate__flipInX"
                                        data-dely="0.3">
                                        <img src={"/assets/images/icon/trust.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                        <h4>Security</h4>
                                        <p>We are bind to keep your personal data safe and secure at Lagna Sohala Matrimony.</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="animate animate__animated animate__slower" data-ani="animate__flipInX"
                                        data-dely="0.3">
                                        <img src={"/assets/images/icon/rings.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                        <h4>Expected Matches</h4>
                                        <p>We help you to match various profiles as per your expectations to start a new journey of life.</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="animate animate__animated animate__slower" data-ani="animate__flipInX"
                                        data-dely="0.6">
                                        <img src={"/assets/images/icon/wedding-8.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                        <h4>Connect - Meet - Marry</h4>
                                        <p>Get connected & meet through the secure portal of Lagna Sohala & marry your soulmate/partner.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Why_Us