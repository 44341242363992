import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next'

import {connect} from 'react-redux';
import {compose} from 'redux';
import loginUser from '../../redux/actions/auth.actions'

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import SubmitLoader from '../../components/common/submit_loader';

function Login()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoggedIn, checkLoginStatus] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [submitBtnText, changeSubmitBtnText] = useState('Sign In');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    })

    const SignupSchema = Yup.object({
        //email: Yup.string().email('Invalid email address').required('Required'),
        username: Yup.string().required('Required'),
        password: Yup.string()
            .min(4, 'Must be 4 characters or more')
            .required('Required'),
    });

    const SubmitForm = (values, { setSubmitting }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/login',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    /* toast.info('Wow so easy!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }); */
                    COMMONFUNCTIONS.setSession('isUserLoggedIn', true);
                    COMMONFUNCTIONS.setSession('member_id', parseInt(response.data.userDetails.member_id));

                    COMMONFUNCTIONS.setSession('member_name', response.data.userDetails.first_name);
                    COMMONFUNCTIONS.setSession('member_fullname', response.data.userDetails.first_name+' '+response.data.userDetails.last_name);
                    COMMONFUNCTIONS.setSession('member_email', response.data.userDetails.email);
                    COMMONFUNCTIONS.setSession('member_mobile', response.data.userDetails.mobile);

                    COMMONFUNCTIONS.setSession('UserDetails', response.data.userDetails);
                    //goToPage('/index.html');
                    //RedirectExample();

                    //navigate('/index.html');
                    {/* <Navigate replace to="/index.html" /> */}
                    //redirect("/");
                    //COMMONFUNCTIONS.RedirectToPage('/index.html');
                    //isLoggedIn = true;
                    COMMONFUNCTIONS.commonMessageSuccessToast('You are successfully logged-in.');
                    COMMONFUNCTIONS.GoToPage(navigate, '/index.html');
                    ///navigate('/index.html');
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    changeSubmitBtnText('Sign In');
                    changeSubmitBtnStatus(false);
                }
                    
            })
            .catch(error => {
                console.log(error);
                //if(error.response.data.message.length > 0)
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                changeSubmitBtnText('Sign In');
                changeSubmitBtnStatus(false);
            });
            setSubmitting(false);
    }

    if(isLoggedIn)
    {
        COMMONFUNCTIONS.commonMessageInfoToast('You are already logged-in.');
        COMMONFUNCTIONS.GoToPage(navigate, '/index.html');
    }

    /* setTimeout(() => {
        navigate("/index.html");
    }, 5000); */

    return <>{
        /* isLoggedIn ? 
        <Navigate to="/index.html" />
        : */
        <div>
            <Top/>
            <Header/>
            {/* LOGIN */}
            <section>
                <div class="login">
                    <div class="container">
                        <div class="row">
                            <div class="inn">
                                <div class="lhs">
                                    <div class="tit">
                                        <h2>{t('now')} <b>{t('find')} <br/> {t('your_life_partner')}</b> {t('easy_and_fast')}.</h2>
                                    </div>
                                    <div class="im">
                                        <img src={"/assets/images/login-couple.png?x="+CONSTANTS.RANDOM_PWD} alt=""/>
                                    </div>
                                    <div class="log-bg">&nbsp;</div>
                                </div>
                                <div class="rhs">
                                    <div>
                                        <div class="form-tit">
                                            <h4>{t('get_started')}</h4>
                                            <h1>{t('sign_in_to_matrimony')}</h1>
                                            <p>{t('not_a_member')}? <Link to="/sign-up.html">{t('sign_up_now')}</Link></p>
                                        </div>
                                        <div class="form-login">
                                        <Formik
                                            initialValues={{  username: '', password: '' }}
                                            validationSchema={SignupSchema}
                                            onSubmit={SubmitForm}
                                            >
                                        {formik => (
                                            <form onSubmit={formik.handleSubmit}>
                                                <div class="form-group">
                                                    <label class="lb">{t('username')}:</label>
                                                    <input type="text" class="form-control" id="username" placeholder={t('enter_username')} name="username" onChange={formik.handleChange} value={formik.values.username} autoComplete="off"/>
                                                    {formik.errors.username ? <div className='m-t-10 text-left error'>{formik.errors.username}</div> : null}
                                                </div>
                                                <div class="form-group">
                                                    <label class="lb">{t('password')}:</label>
                                                    <input type="password" class="form-control" id="password" placeholder={t('enter_password')} name="password" onChange={formik.handleChange} value={formik.values.password} autoComplete="off"/>
                                                    {formik.errors.password ? <div className='m-t-10 text-left error'>{formik.errors.password}</div> : null}
                                                </div>
                                                {/* <div class="form-group form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" name="agree"/> Remember me
                                                    </label>
                                                </div> */}
                                                {/* <button type="submit" class="btn btn-primary">{submitBtnText}</button> */}
                                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} Button_Class={'btn btn-primary'} />
                                            </form>
                                            )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            <Footer/>
        </div>
    }</>
}

export default Login
/* const mapStateToProps = (state) => ({
    loginUser: state.authReducer.loginUser
})

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(Login); */