import { combineReducers } from 'redux';

import authReducer from './auth.reducer';
import userReducer from './user.reducer';

const reducers = {
    authReducer,
    userReducer
};
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGGED_OUT_SUCCESS") {
        state = {}
    }

    return appReducer(state, action);
}

export default rootReducer;