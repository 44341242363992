import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function PersonalAttitudeBehavior(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [country_data] = useState(common_data.common_data.country_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    //const [userPersonalAttitudeBehaviorData, setUserPersonalAttitudeBehaviorData] = useState(userData.personal_attitude_and_behavior);
    const [userPersonalAttitudeBehaviorData, setUserPersonalAttitudeBehaviorData] = useState({});

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const PersonalAttitudeBehaviorSchema = Yup.object({
        /* country: Yup.string().required('Required'),
        state: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_personal_attitude_and_behavior";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        if(user_data.personal_attitude_and_behavior !== undefined && user_data.personal_attitude_and_behavior !== '' && user_data.personal_attitude_and_behavior !== null)
            setUserPersonalAttitudeBehaviorData(user_data.personal_attitude_and_behavior);
    }

    return (
        <div>
            {
                (userPersonalAttitudeBehaviorData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_personal_attitude_and_behavior">{t('personal_attitude_and_behavior')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_personal_attitude_and_behavior" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('affection')}:</b> <span title={ userPersonalAttitudeBehaviorData.affection }>{ userPersonalAttitudeBehaviorData.affection }</span></li>

                        <li><b>{t('humor')}:</b> <span title={ userPersonalAttitudeBehaviorData.humor }>{ userPersonalAttitudeBehaviorData.humor }</span></li>

                        <li><b>{t('political_view')}:</b> <span title={ userPersonalAttitudeBehaviorData.political_view }>{ userPersonalAttitudeBehaviorData.political_view }</span></li>

                        <li><b>{t('religious_service')}:</b> <span title={ userPersonalAttitudeBehaviorData.religious_service }>{ userPersonalAttitudeBehaviorData.religious_service }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_personal_attitude_and_behavior">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  affection: userPersonalAttitudeBehaviorData.affection, humor: userPersonalAttitudeBehaviorData.humor, political_view: userPersonalAttitudeBehaviorData.political_view, religious_service: userPersonalAttitudeBehaviorData.religious_service }}
                        validationSchema={PersonalAttitudeBehaviorSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('personal_attitude_and_behavior')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('affection')}:</label>
                                            <input type="text" id="affection" class="form-control" placeholder={t('enter_affection')} name="affection" onChange={formik.handleChange} value={formik.values.affection} />
                                            {formik.errors.affection ? <div className='m-t-10 text-left error'>{formik.errors.affection}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('humor')}:</label>
                                            <input type="text" id="humor" class="form-control" placeholder={t('enter_humor')} name="humor" onChange={formik.handleChange} value={formik.values.humor} />
                                            {formik.errors.humor ? <div className='m-t-10 text-left error'>{formik.errors.humor}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('political_view')}:</label>
                                            <input type="text" id="political_view" class="form-control" placeholder={t('enter_political_view')} name="political_view" onChange={formik.handleChange} value={formik.values.political_view} />
                                            {formik.errors.political_view ? <div className='m-t-10 text-left error'>{formik.errors.political_view}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('religious_service')}: </label>
                                            <input type="text" id="religious_service" class="form-control" placeholder={t('enter_religious_service')} name="religious_service" onChange={formik.handleChange} value={formik.values.religious_service} />
                                            {formik.errors.religious_service ? <div className='m-t-10 text-left error'>{formik.errors.religious_service}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default PersonalAttitudeBehavior