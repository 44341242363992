import React, { useEffect } from 'react'

import * as COMMONFUNCTIONS from '../../utils/CommonFunctions'

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';

import { Link } from "react-router-dom";

function PUMSuccess()
{
    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();

        COMMONFUNCTIONS.commonMessageSuccessToast('Payment Success !');
    })
    return (
        <div>
            <Top/>
            <Header/>
            {/* REGISTER */}
            <section>
                <div class="login pg-soon">
                    <div class="container">
                        <div class="row">
                            <div class="inn">
                                <div class="counter">
                                    <div class="timer p-b-20">
                                        <span class="seconds"><i class="fa fa-check-circle font-50" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div class="tit p-b-20">
                                    <h4 className='p-b-10'>Payment Complete</h4>
                                    <h1>Thank You !</h1>
                                </div>
                                <div class="menu-pop-soci p-b-20">
                                    <h5>Your payment received successfully ! You will shortly receive an email of your payment.</h5>
                                </div>
                                <div>
                                    <Link class="btn btn-outline-primary" to="/payment-info.html">Go To Payment List</Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className='clear'></div> */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default PUMSuccess