import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

import {Link} from "react-router-dom";

function About_Short()
{
    return (
        <div>
            {/* ABOUT START */}
            <section>
                <div class="ab-wel">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="ab-wel-lhs">
                                    <span class="ab-wel-3"></span>
                                    <img src={"/assets/images/about/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" class="ab-wel-1"/>
                                    <img src={"/assets/images/couples/20.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" class="ab-wel-2"/>
                                    <span class="ab-wel-4"></span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="ab-wel-rhs p-0">
                                    <div class="ab-wel-tit">
                                        <h2>Welcome to <em>{CONSTANTS.PROJECT_TITLE}</em></h2>
                                        <p>LagnaSohalaa Matrimony - India's No. 1 and Most Trusted Matrimony service, has helped Millions like you find matches from across different regions, cities, communities such as Agarwal, Brahmin, Kalita, Kayastha, Khandayat, Khatri, Lingayath, Maratha, Nair, Parsi, Rajput, Reddy and many more.... </p>
                                        <p> <Link to="/plans.html">Click here to</Link> Start your matrimony service now.</p>
                                    </div>
                                    <div class="ab-wel-tit-1">
                                        <div class="pr-bio-c pr-bio-info">
                                            <ul>
                                                <li className='w-100 text-left' title='No.1 & most trusted matrimony service'>No.1 & most trusted matrimony service</li>
                                                <li className='w-100 text-left' title=''>100% verified mobile numbers</li>
                                                <li className='w-100 text-left'>Our largest number of profiles increases your chances of meeting the right person</li>
                                                <li className='w-100 text-left'>Millions have found their life partner here</li>
                                                <li className='w-100 text-left'>Trusted service for more than 19 years</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="ab-wel-tit-2">
                                        <ul>
                                            <li class="w-100 p-b-25 m-b-10">
                                                <div>
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <h4 className='text-left'>{/* Enquiry  */}<em className='p-l-15'><a href={'tel:'+CONSTANTS.PUBLIC_CONTACT_NUMBER}>{CONSTANTS.PUBLIC_CONTACT_NUMBER}</a></em></h4>
                                                </div>
                                            </li>
                                            <li class="w-100">
                                                <div>
                                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                    <h4 className='text-left'>{/* Get Support  */}<em className='p-l-15'><a href={'mailto:'+CONSTANTS.PUBLIC_CONTACT_EMAIL}>{CONSTANTS.PUBLIC_CONTACT_EMAIL}</a></em></h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default About_Short