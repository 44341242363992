import React from 'react'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../Constants';

import {Link} from "react-router-dom";

function Moments()
{
    return (
        <div>
            {/* MOMENTS START */}
            <section>
                <div class="wedd-tline">
                    <div class="container">
                        <div class="row">
                            <div class="home-tit">
                                <p>Moments</p>
                                <h2><span>How it works</span></h2>
                                <span class="leaf1"></span>
                                <span class="tit-ani-"></span>
                            </div>
                            <div class="inn">
                                <ul>
                                    <li>
                                        <div class="tline-inn">
                                            <div class="tline-im animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/rings.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                            <div class="tline-con animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <h5>Register</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tline-inn tline-inn-reve">
                                            <div class="tline-con animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <h5>Find your Match</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                            <div class="tline-im animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/wedding-2.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tline-inn">
                                            <div class="tline-im animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/love-birds.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                            <div class="tline-con animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <h5>Send Interest</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tline-inn tline-inn-reve">
                                            <div class="tline-con animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <h5>Get Profile Information</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                            <div class="tline-im animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/network.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tline-inn">
                                            <div class="tline-im animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/chat.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                            <div class="tline-con animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <h5>Start Meetups</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tline-inn tline-inn-reve">
                                            <div class="tline-con animate animate__animated animate__slower"
                                                data-ani="animate__fadeInUp">
                                                <h5>Getting Marriage</h5>
                                                <span>Timing: 7:00 PM</span>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever.</p>
                                            </div>
                                            <div class="tline-im animate animate__animated animate__slow"
                                                data-ani="animate__fadeInUp">
                                                <img src={"/assets/images/icon/wedding-couple.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* MOMENTS END */}
        </div>
    )
}

export default Moments