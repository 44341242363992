import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

let rest_params = {};

function Happy_Stories()
{
    const[happyStories, setHappyStories] = useState({});
    const[isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(Object.keys(happyStories).length < 1)
            getHappyStories();
    }, [happyStories])

    function getHappyStories()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_happy_stories', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setHappyStories(responce.data.happy_stories_data);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            {/* RECENT COUPLES */}
            <section>
                <div class="hom-couples-all">
                    <div class="container">
                        <div class="row">
                            <div class="home-tit">
                                <p>trusted brand</p>
                                <h2><span>Recent Couples</span></h2>
                                <span class="leaf1"></span>
                                <span class="tit-ani-"></span>
                            </div>
                        </div>
                    </div>
                    <div class="hom-coup-test">
                        <ul class="couple-sli">
                            { (happyStories.length) > 0 && happyStories.map((option, index) => (
                                <li>
                                    <div class="hom-coup-box">
                                        <span class="leaf"></span>
                                        <img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.STORY_DIR_PATH+option.image[0].img+"?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                        <div class="bx">
                                            <h4>{option.title} <span>{option.post_time}</span></h4>
                                            {/* <a href="wedding-video.html" class="sml-cta cta-dark">View more</a> */}
                                        </div>
                                    </div>
                                </li>
                            ))}
                            {/* <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/7.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/8.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/9.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/10.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/4.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding-video.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="hom-coup-box">
                                    <span class="leaf"></span>
                                    <img src={"/assets/images/couples/5.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"/>
                                    <div class="bx">
                                        <h4>Dany & July <span>New York</span></h4>
                                        <a href="wedding.html" class="sml-cta cta-dark">View more</a>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Happy_Stories