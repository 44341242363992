import React, { useState } from 'react'

function SubmitLoader(props)
{
    //const [submitBtnText, changeSubmitBtnText] = useState('Sign In');
    return (
            <button type={props.Button_Type} class={props.Button_Class} disabled={props.Button_Status}>{props.Button_Title}</button>
        )
}

export default SubmitLoader