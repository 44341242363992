import React, { useEffect, useState } from 'react'
import axios from 'axios';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

let rest_params = {};

function Photo_Gallery()
{
    const[galleryImages, setGalleryImages] = useState({});
    const[isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(Object.keys(galleryImages).length < 1)
            getGalleryImages();
    }, [galleryImages])

    function getGalleryImages()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_gallery_images', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setGalleryImages(responce.data.images_data);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }    
    return (
        <div>
            {/* GALLERY START */}
            <section>
                <div class="wedd-gall home-wedd-gall">
                    <div class="">
                        <div class="gall-inn">
                            <div class="home-tit">
                                <p>collections</p>
                                <h2><span>Photo gallery</span></h2>
                                <span class="leaf1"></span>
                                <span class="tit-ani-"></span>
                            </div>

                            { (galleryImages.length) > 0 && galleryImages.map((option, index) => (
                                <div class="col-sm-6 col-md-3">
                                    <div class="gal-im animate animate__animated animate__slow" data-ani="animate__flipInX">
                                        <img src={CONSTANTS.SERVER_BASE_URL+CONSTANTS.GALLERY_DIR_PATH+option.name} class="gal-siz-1" alt="" loading="lazy"/>
                                        <div class="txt">
                                            {/* <span>{option.title}</span> */}
                                            <h4>{option.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                            {/* <div class="col-sm-6 col-md-2">
                                <div class="gal-im animate animate__animated animate__slow" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/1.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-1" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/9.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-2" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/3.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-2" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/4.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-1" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-2">
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/5.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-1" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/6.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-2" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/7.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-2" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/gallery/8.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-1" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/couples/9.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-2" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                                <div class="gal-im animate animate__animated animate__slower" data-ani="animate__flipInX">
                                    <img src={"/assets/images/couples/11.jpg?x="+CONSTANTS.RANDOM_PWD} class="gal-siz-1" alt="" loading="lazy"/>
                                    <div class="txt">
                                        <span>Wedding</span>
                                        <h4>Bride & Groom</h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Photo_Gallery