import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
//import Select from "react-select";

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function EducationCareer(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [education_data] = useState(common_data.common_data.education_data);
    const [occupation_data] = useState(common_data.common_data.occupation_data);
    const [annual_income_data] = useState(common_data.common_data.annual_income_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userEducationCareerData, setUserEducationCareerData] = useState(userData.education_and_career);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const EducationCareerSchema = Yup.object({
        highest_education: Yup.string().required('Required'),
        occupation: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_education_and_career";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserEducationCareerData(user_data.education_and_career);
    }

    return (
        <div>
            {
                (userEducationCareerData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_education_and_career">{t('education_and_career')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_education_and_career" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('highest_education')}:</b> <span title={ userEducationCareerData.highest_education_title }> { userEducationCareerData.highest_education_title }</span></li>

                        <li><b>{t('occupation')}:</b> <span title={ userEducationCareerData.occupation_title }>{ userEducationCareerData.occupation_title }</span></li>

                        { (userEducationCareerData.annual_income_title) ? <li><b>{t('annual_income')}:</b> <span title={ userEducationCareerData.annual_income_title }>{ userEducationCareerData.annual_income_title }</span></li> : '' }
                        
                        <li><b>{t('occupation_details')}:</b> <span title={ userEducationCareerData.occupation_details }>{ userEducationCareerData.occupation_details }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_education_and_career">
                <div class="modal-dialog modal-lg">

                <Formik
                        initialValues={{  highest_education: userEducationCareerData.highest_education_id, occupation: userEducationCareerData.occupation_id, annual_income: userEducationCareerData.annual_income_id, occupation_details: userEducationCareerData.occupation_details }}
                        validationSchema={EducationCareerSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('education_and_career')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('highest_education')}:</label>
                                            <select class="chosen-select" id="highest_education" name='highest_education' onChange={formik.handleChange} >
                                                <option value="">- {t('choose')} -</option>
                                                {education_data.map((option, index) => (
                                                    <option key={index} value={option.education_id} selected={(option.education_id == userEducationCareerData.highest_education_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.highest_education ? <div className='m-t-10 text-left error'>{formik.errors.highest_education}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('occupation')}:</label>
                                            < select class="chosen-select" id="occupation" name='occupation' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {occupation_data.map((option, index) => (
                                                    <option key={index} value={option.occupation_id} selected={(option.occupation_id == userEducationCareerData.occupation_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.occupation ? <div className='m-t-10 text-left error'>{formik.errors.occupation}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('annual_income')}:</label>
                                            <select class="chosen-select" id="annual_income" name='annual_income' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {annual_income_data.map((option, index) => (
                                                    <option key={index} value={option.annual_income_id} selected={(option.annual_income_id == userEducationCareerData.annual_income_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.annual_income ? <div className='m-t-10 text-left error'>{formik.errors.annual_income}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('occupation_details')}: </label>
                                            <textarea class="form-control textarea-no-border" id="occupation_details" name="occupation_details" placeholder={t('occupation_details')} onChange={formik.handleChange} value={formik.values.occupation_details} rows={8}></textarea>
                                            {formik.errors.occupation_details ? <div className='m-t-10 text-left error'>{formik.errors.occupation_details}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default EducationCareer