import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
//import Select from "react-select";

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function SpiritualSocialBackground(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [religion_data] = useState(common_data.common_data.religion_data);
    const [community_data] = useState(common_data.common_data.community_data);
    const [family_value_data] = useState(common_data.common_data.family_value_data);
    const [family_status_data] = useState(common_data.common_data.family_status_data);
    const [decision_data] = useState(common_data.common_data.decision_data);

    //const [userData, ] = useState(props.userData);
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userSpiritualSocialBackgroundData, setUserSpiritualSocialBackgroundData] = useState(userData.spiritual_and_social_background);
    const [religion, setReligion] = useState(null);
    const [castes, setCastes] = useState(null);
    const [sub_castes, setSubCastes] = useState(null);

    const [selectedReligion, setSelectedReligion] = useState(userSpiritualSocialBackgroundData.religion_id);
    const [selectedCaste, setSelectedCaste] = useState(userSpiritualSocialBackgroundData.caste_id);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);

        if(castes == null)
            getCastesByReligion(userSpiritualSocialBackgroundData.religion_id);

        if(sub_castes == null)
            getSubCasteByCaste(userSpiritualSocialBackgroundData.caste_id);

    }, [userData, castes])

    const SpiritualSocialBackgroundSchema = Yup.object({
        religion: Yup.string().required('Required'),
        caste: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_spiritual_and_social_background";
        values['user_id'] = userData.member_id;
        values['religion'] = selectedReligion;
        values['caste'] = selectedCaste;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserSpiritualSocialBackgroundData(user_data.spiritual_and_social_background);
    }

    function setReligionVal (value)
    {
        setSelectedReligion(value);
    }

    function setCasteVal (value)
    {
        setSelectedCaste(value);
    }

    const changeReligion = (event) => {
        getCastesByReligion(event.value);
    };

    function getCastesByReligion(religion_id)
    {
        values['religion_id'] = religion_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_caste_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
                setCastes(response.data.caste_data);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const caste_options = (caste, value) => {
        if (typeof caste === 'object' && caste !== null )
        {
            return caste.map((el) => <option key={el.caste_id} value={el.caste_id} selected={(el.caste_id == value) ? 'selected' : ''} >{el.caste_name}</option>);
        }
    }

    const changeCaste = (event) => {
        getSubCasteByCaste(event.value);
    };

    function getSubCasteByCaste(caste_id)
    {
        values['caste_id'] = caste_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_sub_caste_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
                setSubCastes(response.data.sub_caste_data);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const sub_caste_options = (sub_castes, value) => {
        if (typeof sub_castes === 'object' && sub_castes !== null )
        {
            return sub_castes.map((el) => <option key={el.sub_caste_id} value={el.sub_caste_id} selected={(el.sub_caste_id == value) ? 'selected' : ''} >{el.sub_caste_name}</option>);
        }
    }

    return (
        <div>
            {
                (userSpiritualSocialBackgroundData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_spiritual_and_social_background">{t('spiritual_and_social_background')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_spiritual_and_social_background" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('religion')}:</b> <span title={ userSpiritualSocialBackgroundData.religion_title }>{ userSpiritualSocialBackgroundData.religion_title }</span></li>

                        <li><b>{t('caste')}:</b> <span title={ userSpiritualSocialBackgroundData.caste_title }>{ userSpiritualSocialBackgroundData.caste_title }</span></li>

                        <li><b>{t('sub_caste')}:</b> <span title={ userSpiritualSocialBackgroundData.sub_caste_title }>{ userSpiritualSocialBackgroundData.sub_caste_title }</span></li>

                        <li><b>{t('community')}:</b> <span title={ userSpiritualSocialBackgroundData.community_title }>{ userSpiritualSocialBackgroundData.community_title }</span></li>

                        <li><b>{t('ethnicity')}:</b> <span title={ userSpiritualSocialBackgroundData.ethnicity }>{ userSpiritualSocialBackgroundData.ethnicity }</span></li>

                        <li><b>{t('personal_value')}:</b> <span title={ userSpiritualSocialBackgroundData.personal_value }>{ userSpiritualSocialBackgroundData.personal_value }</span></li>

                        <li><b>{t('family_value')}:</b> <span title={ userSpiritualSocialBackgroundData.family_value_title }>{ userSpiritualSocialBackgroundData.family_value_title }</span></li>

                        <li><b>{t('family_status')}:</b> <span title={ userSpiritualSocialBackgroundData.family_status_title }>{ userSpiritualSocialBackgroundData.family_status_title }</span></li>

                        <li><b>{t('manglik')}:</b> <span title={ userSpiritualSocialBackgroundData.u_manglik }>{ userSpiritualSocialBackgroundData.u_manglik }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_spiritual_and_social_background">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  religion: userSpiritualSocialBackgroundData.religion_id, caste: userSpiritualSocialBackgroundData.caste_id, sub_caste: userSpiritualSocialBackgroundData.sub_caste_id, ethnicity: userSpiritualSocialBackgroundData.ethnicity, personal_value: userSpiritualSocialBackgroundData.personal_value, family_value: userSpiritualSocialBackgroundData.family_value_id, community: userSpiritualSocialBackgroundData.community, family_status: userSpiritualSocialBackgroundData.family_status_id, u_manglik: userSpiritualSocialBackgroundData.u_manglik }}
                        validationSchema={SpiritualSocialBackgroundSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('spiritual_and_social_background')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('religion')}:</label>
                                            <select class="chosen-select" id="religion" name='religion' onChange={(event) => {
                                                console.log(event.target.value);
                                                changeReligion(event.target);
                                                //this.handleChange();
                                                setReligion(event.target.value);
                                                setReligionVal(event.target.value);
                                            }} >
                                                <option value="">- {t('choose')} -</option>
                                                {religion_data.map((option, index) => (
                                                    <option key={index} value={option.religion_id} selected={(option.religion_id == userSpiritualSocialBackgroundData.religion_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.religion ? <div className='m-t-10 text-left error'>{formik.errors.religion}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('caste')}:</label>
                                            < select class="chosen-select" id="caste" name='caste' onChange={(event) => {
                                                console.log(event.target.value);
                                                //this.handleChange();
                                                changeCaste(event.target);
                                                setCasteVal(event.target.value);
                                            }} >
                                                <option value="">- {t('choose')} -</option>
                                                { caste_options(castes, userSpiritualSocialBackgroundData.caste_id) }
                                            </select>
                                            {formik.errors.caste ? <div className='m-t-10 text-left error'>{formik.errors.caste}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('sub_caste')}:</label>
                                            <select class="chosen-select" id="sub_caste" name='sub_caste' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                { sub_caste_options(sub_castes, userSpiritualSocialBackgroundData.caste_id) }
                                            </select>
                                            {formik.errors.sub_caste ? <div className='m-t-10 text-left error'>{formik.errors.sub_caste}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('community')}:</label>
                                            <select class="chosen-select" id="community" name='community' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {community_data.map((option, index) => (
                                                    <option key={index} value={option.community_id} selected={(option.community_id == userSpiritualSocialBackgroundData.community) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.community ? <div className='m-t-10 text-left error'>{formik.errors.community}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('ethnicity')}: </label>
                                            <input type="text" id="ethnicity" class="form-control" placeholder={t('ethnicity')} name="ethnicity" onChange={formik.handleChange} value={formik.values.ethnicity} />
                                            {formik.errors.ethnicity ? <div className='m-t-10 text-left error'>{formik.errors.ethnicity}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('personal_value')}: </label>
                                            <input type="text" id="personal_value" class="form-control" placeholder={t('enter_personal_value')} name="personal_value" onChange={formik.handleChange} value={formik.values.personal_value} />
                                            {formik.errors.personal_value ? <div className='m-t-10 text-left error'>{formik.errors.personal_value}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('family_value')}:</label>
                                            <select class="chosen-select" id="family_value" name='family_value' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {family_value_data.map((option, index) => (
                                                    <option key={index} value={option.family_value_id} selected={(option.family_value_id == userSpiritualSocialBackgroundData.family_value_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.family_value ? <div className='m-t-10 text-left error'>{formik.errors.family_value}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('family_status')}:</label>
                                            <select class="chosen-select" id="family_status" name='family_status' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {family_status_data.map((option, index) => (
                                                    <option key={index} value={option.family_status_id} selected={(option.family_status_id == userSpiritualSocialBackgroundData.family_status_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.family_status ? <div className='m-t-10 text-left error'>{formik.errors.family_status}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('manglik')}:</label>
                                            <select class="chosen-select" id="u_manglik" name='u_manglik' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {decision_data.map((option, index) => (
                                                    <option key={index} value={option.decision_id} selected={(option.decision_id == userSpiritualSocialBackgroundData.u_manglik) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.u_manglik ? <div className='m-t-10 text-left error'>{formik.errors.u_manglik}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default SpiritualSocialBackground