import React, { Component } from 'react'
import { Link } from "react-router-dom";
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

export class login_menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoggedIn: COMMONFUNCTIONS.getSession('isUserLoggedIn')
        };
      }

    render() {
        let { isLoggedIn } = this.state;
        //console.log(isLoggedIn);
        return (
            <>
                {(function() {
                if (isLoggedIn) {
                    return <li className='ml-5'><Link to="/logout.html">Logout</Link></li>;
                } else {
                    return <><li><Link to="/login.html">Login</Link></li><li><Link to="/sign-up.html">Register Now</Link></li></>;
                    //return <li className='ml-5'><Link to="/sign-up.html">Register Now</Link></li>;
                }
                })()}                
            </>
        )
    }
}

export default login_menu