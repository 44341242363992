import React from 'react'
import axios from 'axios';

//import  * as COMMONFUNCTIONS from '@app/utils/CommonFunctions';
import {Navigate, useNavigate} from 'react-router-dom';

import * as CONSTANTS from '../../src/Constants';
import { toast } from 'react-toastify';

let values = {};

/* Functions for Common Toast Messages */

export const commonMessageInfoToast = (message) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const commonMessageSuccessToast = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const commonMessageErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

export const setSession = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getSession = (key) => {
    const sessionVal = localStorage.getItem(key);
    return sessionVal
};

export const destroySession = () => {
    var hours = 5; // to clear the localStorage after 1 hour
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now)
    } else {
        if(now-setupTime > hours*60*60*1000) {
            let temp_common_data = JSON.parse(getSession('common_data'));
            localStorage.clear();
            sessionStorage.clear();
            this.setSession('common_data', temp_common_data);
            localStorage.setItem('setupTime', now);
        }
    }
};

export const getPostHeaders = () => {
    const post_headers = {
        'Authorization': 'Basic '+CONSTANTS.AUTH_VALUE,
        /* "Access-Control-Allow-Origin": "*",
        "method": 'post', */
        'Content-Type': 'application/x-www-form-urlencoded', 
        //'Content-Type': 'application/json',
    }
    return post_headers
};

export function GoToPage(navigate, page_link) {
    //const navigate = useNavigate();
    setTimeout(() => {
        navigate(page_link);
    }, 1000);
}

export const RedirectToPage = (page_link) => {
    //const navigate = useNavigate();
    //navigate(page_link);
    //console.log(page_link);
    //return <Navigate to={page_link} />;
    //window.location.assign(page_link);
    //return <Navigate to="/index.html" />;
    setTimeout(() => {
        window.location.assign(page_link);
    }, 1000);
};

export const moveToTop = () => {
    window.scrollTo(0, 0);
};

export const camelCase = (str) => {
    /* const result = str.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1); */
	// Using replace method with regEx
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
		return index == 0 ? word.toUpperCase() : word.toUpperCase();
	}).replace(/\s+/g, ' ');
}

export const generatePassword = (length) => {
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const fetchLoggedInUserProfileData = async (user_id) => {
    console.log('in fetchUserProfileData');
    try {
        let post_params = {};
        post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        post_params['member_id'] = user_id;
        post_params['user_id'] = user_id;

        console.log('in fetchUserProfileData try');

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/member_profile',post_params, {headers: getPostHeaders()})
            .then(response => {
                //console.log(response);
                if(response.data.success)
                {
                    console.log(response);
                    setSession('UserDetails', response.data.member_data);
                }
                else
                    commonMessageErrorToast(response.data.message);
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    commonMessageErrorToast(error.response.data.message);
                else
                    commonMessageErrorToast(error.message);
            });
        
    } catch (error) {
        console.log('in catch');
        console.log(error);
        //setError(error);
    } finally {
        console.log('in finally');
        //setIsLoading(false);
    }
}

export const saveExpressInterest1 = async (user_id, member_id) => {

    values['user_id'] = user_id;
    values['member_id'] = member_id;
    values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

    axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_interest',values, {headers: getPostHeaders()})
    .then(response => {
        console.log(response);
        if(response.data.success)
        {
            commonMessageSuccessToast(response.data.message);
        }
        else
            commonMessageErrorToast(response.data.message);
    })
    .catch(error => {
        console.log(error);
        //if(error.response.data.message.length > 0)
        /* if(error.response !== undefined && error.response !== '' && error.response !== null)
            COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
        else
            COMMONFUNCTIONS.commonMessageErrorToast(error.message); */
    });

    /* const responceJson = await fetchApi("/api/main/add_interest", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    } */
}

/* export const saveShortlist  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};

    const responceJson = await fetchApi("/api/main/add_shortlist", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const removeShortlist  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};
    
    const responceJson = await fetchApi("/api/main/remove_shortlist", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const saveFollowlist  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};

    const responceJson = await fetchApi("/api/main/add_follow", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const addUnfollowList  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};
    const responceJson = await fetchApi("/api/main/add_unfollow", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const saveIgnorelist  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};

    const responceJson = await fetchApi("/api/main/add_ignore", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const doUnblock  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};
    const responceJson = await fetchApi("/api/main/do_unblock", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
}

export const saveReportlist  = async (user_id, member_id) => {
    let payload = {'user_id' : user_id, 'member_id' : member_id};

    const responceJson = await fetchApi("/api/main/add_report", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        this.commonMessageSuccessToast(responceJson.responseBody.message);
        return true;
    }
    else
    {
        this.commonMessageErrorToast(responceJson.responseBody.message);
        return false;
    }
} */

export const getUsersRemainingInterests  = async (user_id) => {
    let interestscount = 0;

    values['user_id'] = user_id;
    values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

    axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/getUsersRemainingInterest',values, {headers: getPostHeaders()})
    .then(response => {
        console.log(response.data.remaining_interest_count);
        if(response.data.success)
            interestscount = response.data.remaining_interest_count;
        else
            commonMessageErrorToast(response.data.message);
    })
    .catch(error => {
        console.log(error);
        //if(error.response.data.message.length > 0)
        /* if(error.response !== undefined && error.response !== '' && error.response !== null)
            COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
        else
            COMMONFUNCTIONS.commonMessageErrorToast(error.message); */
    });

    console.log(interestscount);
    //return ({interestscount : interestscount});
    return new Promise(resolve => setTimeout(() => setSession('user_interest_count', interestscount), 1000));
    //return new Promise(interestscount => interestscount);
    //return interestscount;

    /* const responceJson = await fetchApi("/api/main/getUsersRemainingInterest", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        interestscount = responceJson.responseBody.remaining_interest_count;
    }
    return interestscount; */
}

/* export const getUsersRemainingGallery  = async (user_id) => {
    let payload = {'user_id' : user_id};
    let gallerycount = 0;

    const responceJson = await fetchApi("/api/main/getUsersRemainingGallery", "POST", payload, 200);
    if(responceJson.responseBody.success)
    {
        gallerycount = responceJson.responseBody.remaining_gallery_count;
    }
    return gallerycount;
} */