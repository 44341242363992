import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
//import Select from "react-select";

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function ResidencyInformation(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [country_data] = useState(common_data.common_data.country_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userResidencyInformationData, setUserResidencyInformationData] = useState(userData.residency_information);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);

    }, [userData])

    const ResidencyInformationSchema = Yup.object({
        birth_country: Yup.string().required('Required'),
        citizenship_country: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_residency_information";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserResidencyInformationData(user_data.residency_information);
    }

    return (
        <div>
            {
                (userResidencyInformationData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_residency_information">{t('residency_information')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_residency_information" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('birth_country')}:</b> <span title={ userResidencyInformationData.birth_country_title }>{ userResidencyInformationData.birth_country_title }</span></li>

                        <li><b>{t('residency_country')}:</b> <span title={ userResidencyInformationData.residency_country_title }>{ userResidencyInformationData.residency_country_title }</span></li>

                        <li><b>{t('citizenship_country')}:</b> <span title={ userResidencyInformationData.citizenship_country_title }>{ userResidencyInformationData.citizenship_country_title }</span></li>
                        
                        <li><b>{t('grow_up_country')}:</b> <span title={ userResidencyInformationData.grow_up_country_title }>{ userResidencyInformationData.grow_up_country_title }</span></li>

                        <li><b>{t('immigration_status')}:</b> <span title={ userResidencyInformationData.immigration_status }>{ userResidencyInformationData.immigration_status }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_residency_information">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  birth_country: userResidencyInformationData.birth_country_id, residency_country: userResidencyInformationData.residency_country_id, citizenship_country: userResidencyInformationData.citizenship_country_id, grow_up_country: userResidencyInformationData.grow_up_country_id, immigration_status: userResidencyInformationData.immigration_status }}
                        validationSchema={ResidencyInformationSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('residency_information')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('birth_country')}:</label>
                                            <select class="chosen-select" id="birth_country" name='birth_country' onChange={formik.handleChange} >
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userResidencyInformationData.birth_country_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.birth_country ? <div className='m-t-10 text-left error'>{formik.errors.birth_country}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('residency_country')}:</label>
                                            < select class="chosen-select" id="residency_country" name='residency_country' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userResidencyInformationData.residency_country_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.residency_country ? <div className='m-t-10 text-left error'>{formik.errors.residency_country}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('citizenship_country')}:</label>
                                            <select class="chosen-select" id="citizenship_country" name='citizenship_country' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userResidencyInformationData.citizenship_country_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.citizenship_country ? <div className='m-t-10 text-left error'>{formik.errors.citizenship_country}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('grow_up_country')}:</label>
                                            <select class="chosen-select" id="grow_up_country" name='grow_up_country' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {country_data.map((option, index) => (
                                                    <option key={index} value={option.country_id} selected={(option.country_id == userResidencyInformationData.grow_up_country_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.grow_up_country ? <div className='m-t-10 text-left error'>{formik.errors.grow_up_country}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('immigration_status')}: </label>
                                            <input type="text" id="immigration_status" class="form-control" placeholder={t('immigration_status')} name="postal_code" onChange={formik.handleChange} value={formik.values.immigration_status} />
                                            {formik.errors.immigration_status ? <div className='m-t-10 text-left error'>{formik.errors.immigration_status}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default ResidencyInformation