import React, { Suspense, useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

/* import { BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams } from "react-router-dom" */

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Offline, Online } from "react-detect-offline";

import * as CONSTANTS from '../src/Constants';
import * as COMMONFUNCTIONS from '../src/utils/CommonFunctions';

import Home from './components/pages/home';
import About from './components/pages/about';
import Plans from './components/pages/plans';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Members from './components/pages/members';
import Profile from './components/pages/profile';
import MyProfile from './components/pages/member_profile';
import Logout from './components/pages/logout';
import Contact from './components/pages/contact';
import MyPackage from './components/pages/my_package';
import ChangePassword from './components/pages/change_password';
import PicturePrivacy from './components/pages/picture_privacy';
import PaymentInfo from './components/pages/payment_info';
import PayUSuccess from './components/payment/pum_success';
import PayUFailure from './components/payment/pum_failure';
import DeleteAccount from './components/pages/delete_account';

import FullCMS from './components/pages/full_cms';
import FAQ from './components/pages/faq';
import Loader from './components/pages/loader';
import Scroll from './components/pages/scroll';

import PrivateRoute from './components/pages/private_route'
import Error404 from './components/error/404';

let values=  {};

function App()
{
  const [common_data, setCommonData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if(Object.is(common_data, null))
    {
        console.log('1');
        //console.log(common_data);
        //console.log(common_data.common_data.gender_data);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_common_data', values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response.data.success);
            if(response.data.success === true)
            {
                console.log('set data');
                console.log(response.data);
                setCommonData(JSON.parse(response.data.common_data));
                COMMONFUNCTIONS.setSession('common_data', JSON.parse(response.data.common_data));
                //COMMONFUNCTIONS.commonMessageInfoToast('Data fetched successfully.');
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
            }
                
        })
        .catch(error => {
            console.log(error);
            //COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }
    else{
      var test_data = common_data;
      //console.log(test_data.common_data.gender_data);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    COMMONFUNCTIONS.destroySession();   //    Destroy Session after every 5 Hrs Automatically
  }, []);

  return (
    <>
      <Suspense>
        <div className="App">
          {/* <Online> */}
            <div class="wrapper-main">
            {/* <Router>
              <Switch>
                <Route index path="/" component={Home} />
                <Route path="/index" component={Home} />
                <Route path="/login" component={Login} />
              </Switch>
            </Router> */}
              { isLoading ?  <Loader /> : <Routes>
                  {/* <Route path="/" element={<Layout />}>  */}
                  {/*   START LOGIN PAGES  */}
                      <Route exact path='/' element={<PrivateRoute/>}>
                        <Route exact path='/my-profile.html' element={<MyProfile/>}/>
                        <Route exact path='/my-package.html' element={<MyPackage/>}/>
                        <Route exact path='/change-password.html' element={<ChangePassword/>}/>
                        <Route exact path='/picture-privacy.html' element={<PicturePrivacy/>}/>
                        <Route exact path='/payment-info.html' element={<PaymentInfo/>}/>
                        <Route exact path='/pum-success.html' element={<PayUSuccess/>}/>
                        <Route exact path='/pum-failure.html' element={<PayUFailure/>}/>
                        <Route exact path='/profile-details.html' element={<Profile/>} />
                        <Route exact path='/delete-account.html' element={<DeleteAccount/>}/>
                      </Route>
                  {/*   ENT LOGIN PAGES  */}
                  {/*  START GUEST PAGES  */}
                      <Route exact path="/" index element={<Home common_data={common_data}/>} />
                      <Route exact path="/index.html" element={<Home/>} />
                      <Route exact path="/about.html" element={<About/>} />
                      <Route exact path="/members.html"  element={<Members member_type={'all'} />} />
                      <Route exact path="/premium.html"  element={<Members member_type={'premium_members'} />} />
                      <Route exact path="/free.html"  element={<Members member_type={'free_members'}/>} />
                      <Route exact path="/plans.html" element={<Plans/>} />
                      <Route exact path="/login.html"  element={<Login/>} />
                      <Route exact path="/sign-up.html"  element={<Register common_data={common_data}/>} />
                      <Route exact path="/logout.html"  element={<Logout/>} />
                      <Route exact path='/contact.html' element={<Contact/>} />
                      <Route exact path="/faq.html"  element={<FAQ />} />
                      <Route exact path="/terms_and_conditions.html"  element={<FullCMS page_title={'Terms & Conditions'} api_url={'terms_and_conditions'}/>} />
                      <Route exact path="/privacy_policy.html"  element={<FullCMS page_title={'Privacy Policy'} api_url={'privacy_policy'}/>} />
                      <Route exact path="/refund_policy.html"  element={<FullCMS page_title={'Refund Policy'} api_url={'refund_policy'}/>} />
                      {/* <Route path="/*" element={<Error404 />} /> */}
                      <Route path="/*" element={<Home/>} />
                      <Route exact path="/scroll.html"  element={<Scroll />} />
                      {/* <Route path="blogs" element={<Blogs />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="*" element={<NoPage />} /> */}
                  {/*  END GUEST PAGES  */}
                  
                {/* </Route> */}
              </Routes>}
              <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
              />
              
            </div>
          {/* </Online>
          <Offline>
        		<p>You are offline. Please check your internet connection.</p>
      		</Offline> */}
        </div>
      </Suspense>
    </>
  );
}

export default App;
