import React, { useState, useEffect } from 'react'

import axios from 'axios';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import { stringify } from 'qs';

let post_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;

function PaymentInfo()
{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useLocation();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [paymentData, setPaymentData] = useState([]);
    const [loadingPaymentText, setLoadingPaymentText] = useState(<i class="fa fa-spinner fa-spin"></i>);
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    console.log(params);

    const fetchUserPaymentData = async () => {
        try {
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = user_id;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/getPaymentList',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    //console.log(response);
                    if(response.data.success)
                    {
                        console.log(response.data.total_payment);
                        if(response.data.total_payment.length > 0)
                            setPaymentData(response.data.total_payment);
                        else
                            setLoadingPaymentText(t('no_records_found'));
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

    function setAllUsersData (user_data)
    {
        setUserData(user_data);
        /* setUserBasicInfoData(user_data.basic_info);
        setUserPermanentAddressData(user_data.permanent_address);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
        setUserPhysicalData(user_data.physical_attributes);

        setIsInterested(user_data.is_interested);
        setShortlisted(user_data.is_shortlisted);
        setFollowed(user_data.is_followed);
        setIgnored(user_data.is_ignored);
        setReported(user_data.is_reported); */
    }

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    const downloadInvoiceFile = (pp_id, invoicenum) => {
        var fileName = 'Invoice-ML'+invoicenum+'.pdf';
        post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['user_id'] = user_id;
            post_params['pp_id'] = pp_id;
        fetch(CONSTANTS.SERVER_BASE_URL+'/api/main/downloadInvoice', {
            method: 'post',
            headers: {
            'Authorization': 'Basic '+CONSTANTS.AUTH_VALUE,
            'Content-Type': 'application/x-www-form-urlencoded',
            //'Content-Type': 'application/pdf',
            },
            headers: new Headers({
            'Authorization': 'Basic '+CONSTANTS.AUTH_VALUE,
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: stringify(post_params)
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });
        };

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
        fetchUserPaymentData();
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
                <section>
                    <div class="profi-pg profi-ban">
                        <div class="">
                            <div class="">
                                <div class="profile">
                                    <div class="pg-pro-big-im">
                                        <div class="s1">
                                            {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                            { getProfileImage(userData.profile_image) }
                                        </div>
                                    </div>
                                </div>
                                <div class="profi-pg profi-bio">
                                    <div class="lhs">
                                        <div class="pro-pg-intro">
                                            <h1 className='header'>Payment Information<Link className="float-right" to="/my-profile.html"><i class="fa fa-times" aria-hidden="true"></i></Link></h1>
                                            {/* <div class="pro-info-status">
                                                <span class="stat-1"><b>100</b> viewers</span>
                                                <span class="stat-2"><b>Available</b> online</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>City: <strong>{ userBasicInfoData.marital_status_title }</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-age.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Age: <strong>{ userData.age } Yrs</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Height: <strong>{ userData.height } Feet</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Job: <strong>{ userBasicInfoData.on_behalf_title }</strong></span>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>

                                    {/* PROFILE lHS */}
                                    <div class="rhs">
                                        <div class="card m-t-40">
                                            {/* <div class="card-header">
                                                <h3 class="card-title">My Interests</h3>
                                            </div> */}
                                            <div class="card-body p-0 table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: 10+'px'}}>#</th>
                                                            <th>Date</th>
                                                            <th>Payment Type</th>
                                                            <th>Amount</th>
                                                            <th>Package</th>
                                                            <th style={{width: 40+'px'}}>Status</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    { (paymentData != null) && paymentData.map((payment_item, index) => (
                                                            <tr>
                                                                <td>{index+1}.</td>
                                                                <td>{payment_item.created_date}</td>
                                                                <td>{payment_item.payment_type}</td>
                                                                <td>{payment_item.amount}</td>
                                                                <td>{payment_item.name}</td>
                                                                <td>{(payment_item.payment_status !== 'paid') ? <span class="badge bg-danger white-text">{COMMONFUNCTIONS.camelCase( payment_item.payment_status)}</span> : <span class="badge bg-success white-text">{ COMMONFUNCTIONS.camelCase( payment_item.payment_status)}</span> }</td>
                                                                <td>{(payment_item.payment_status == 'paid') ? <button className='badge bg-primary white-text p-2' onClick={() => {downloadInvoiceFile(payment_item.package_payment_id, payment_item.invoicenum)}} title='Download Invoice'><i class="fa fa-download" aria-hidden="true"></i></button> : '' }</td>
                                                            </tr>
                                                    ))}
                                                    { (paymentData.length  < 1) && <tr>
                                                                <td colSpan={6}><center className='header'>{loadingPaymentText}</center></td>
                                                            </tr> }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END PROFILE lHS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END PROFILE */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default PaymentInfo