import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';

let values = {};

function LifeStyle(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [life_style_data] = useState(common_data.common_data.life_style_data);
    const [diet_data] = useState(common_data.common_data.dietary_habits_data);
    const [drink_data] = useState(common_data.common_data.drinking_habits_data);
    const [decision_data] = useState(common_data.common_data.decision_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userLifeStyleData, setUserLifeStyleData] = useState(userData.life_style);

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const LifeStyleSchema = Yup.object({
        //mother_tongue: Yup.string().required('Required'),
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_life_style";
        values['user_id'] = userData.member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserLifeStyleData(user_data.life_style);
    }

    return (
        <div>
            {
                (userLifeStyleData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_life_style">{t('life_style')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_life_style" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('diet')}:</b> <span title={ userLifeStyleData.diet_title }>{ userLifeStyleData.diet_title }</span></li>

                        <li><b>{t('drink')}:</b> <span title={ userLifeStyleData.drink_title }>{ userLifeStyleData.drink_title }</span></li>

                        <li><b>{t('smoke')}:</b> <span title={ userLifeStyleData.smoke_title }>{ userLifeStyleData.smoke_title }</span></li>

                        <li><b>{t('living_with')}:</b> <span title={ userLifeStyleData.living_with }>{ userLifeStyleData.living_with }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_life_style">
                <div class="modal-dialog modal-lg">
                <Formik
                        initialValues={{  diet: userLifeStyleData.diet_id, drink: userLifeStyleData.drink_id, smoke: userLifeStyleData.smoke_id, living_with: userLifeStyleData.living_with }}
                        validationSchema={LifeStyleSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('life_style')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('diet')}:</label>
                                            <select class="chosen-select" id="diet" name='diet' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {diet_data.map((option, index) => (
                                                    <option key={index} value={option.dietary_habits_id} selected={(option.dietary_habits_id == userLifeStyleData.diet_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.diet ? <div className='m-t-10 text-left error'>{formik.errors.diet}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('drink')}:</label>
                                            <select class="chosen-select" id="drink" name='drink' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {drink_data.map((option, index) => (
                                                    <option key={index} value={option.drinking_habits_id} selected={(option.drinking_habits_id == userLifeStyleData.drink_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select>
                                            {formik.errors.drink ? <div className='m-t-10 text-left error'>{formik.errors.drink}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('smoke')}:</label>
                                            <select class="chosen-select" id="smoke" name='smoke' onChange={formik.handleChange}>
                                                <option value="">- {t('choose')} -</option>
                                                {decision_data.map((option, index) => (
                                                    <option key={index} value={option.decision_id} selected={(option.decision_id == userLifeStyleData.smoke_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select>
                                            {formik.errors.smoke ? <div className='m-t-10 text-left error'>{formik.errors.smoke}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('living_with')}: </label>
                                            <input type="text" id="living_with" class="form-control" placeholder={t('enter_living_with')} name="living_with" onChange={formik.handleChange} value={formik.values.living_with} />
                                            {formik.errors.living_with ? <div className='m-t-10 text-left error'>{formik.errors.living_with}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default LifeStyle