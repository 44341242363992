import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Search from '../../components/common/search';
import Quick_Access from '../../components/common/quick_access';
import Trust_Brands from '../../components/common/trust_brands';
import Why_Us from '../../components/common/why_us';
import About_Short from '../../components/common/about_short';
import Counts from '../../components/common/counts';
import Moments from '../../components/common/moments';
import Happy_Stories from '../../components/common/happy_stories';
import Photo_Gallery from '../../components/common/photo_gallery';
import Blog_Articles from '../../components/common/blog_articles';
import Home_Register_Banner from '../../components/common/home_register_banner';

import Footer from '../../components/common/footer';
/*import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';*/

import axios from 'axios';
import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  let values=  {};
  let config = {
    headers: {'Authorization': 'Basic bGFnbmFzb2hhbGFfYWRtaW46TGFnbmFATWF0cmltb255LmMwbQ=='},
    params: {
      'LSM-AUTH-API-KEY': '4GeRbtbLSBeDg3ZxZ48o'
    },
  }

function Home()
{
    const [common_data, setCommonData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));

    /* useEffect(() => {
      fetch(CONSTANTS.SERVER_BASE_URL+'/api/main/get_common_data')
        .then(res => {
          alert('123');
          console.log('res:'+res);
        })
        .then(data => {
          alert('456');
          //setItems(data)
          console.log('data:'+data);
        });
    }, []); */

    /* if(Object.is(common_data, null))
    {
        console.log('1');
        //console.log(common_data);
        //console.log(common_data.common_data.gender_data);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_common_data', values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response.data.success);
            if(response.data.success === true)
            {
                console.log('set data');
                console.log(response.data);
                setCommonData(JSON.parse(response.data.common_data));
                COMMONFUNCTIONS.setSession('common_data', JSON.parse(response.data.common_data));
                COMMONFUNCTIONS.commonMessageInfoToast('Data fetched successfully.');
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
            }
                
        })
        .catch(error => {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
        });
    }
    else{
      console.log('2');
      var test_data = common_data;
      console.log(test_data.common_data.gender_data);
    } */
    
    
    return (
        <div>
            <Top/>
            <Header/>
            <Search common_data={common_data} />
            <Quick_Access />
            {/* <Trust_Brands /> */}
            <Why_Us container_class={'ban-inn ban-home1 m-t-0'} />
            <About_Short />
            <Counts />
            {/* <Moments /> */}
            <Happy_Stories />
            <Photo_Gallery />
            {/* <Blog_Articles /> */}
            <Home_Register_Banner />
            <Footer/>
        </div>
    )
}

export default Home
