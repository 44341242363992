import React, { useState } from 'react';
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import ProfileActionPanel from '../../components/common/profile_action_panel';

function MemberItems(props)
{
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [member_id] = useState(props.item.member_id);
    const navigate = useNavigate();

    function gotoUserProfile(member_id)
    {
        //console.log(member_id);
        navigate("/profile-details.html", { state: {member_id : member_id}, replace: true });
    }

    let caste_religion = '';

    if (props.item.caste_title != null && props.item.caste_title != "Doesn't Matter" && props.item.caste_title != "Caste No Bar" && props.item.caste_title != '') caste_religion += props.item.caste_title;
    if (props.item.caste_title != null && props.item.religion_title != "Doesn't Matter" && props.item.religion_title != '')
    {
        if(caste_religion.length > 1)
            caste_religion += ', ';
        
        caste_religion += props.item.religion_title;
    }

    let city_state_country = '';

    if (props.item.city_title != null && props.item.city_title != "Doesn't Matter" && props.item.city_title != '') city_state_country += props.item.city_title;
    if (props.item.state_title != null && props.item.state_title != "Doesn't Matter" && props.item.state_title != '')
    {
        if(city_state_country.length > 1)
        city_state_country += ', ';
        
        city_state_country += props.item.state_title;
    }

    if (props.item.country_title != null && props.item.state_ticountry_titletle != "Doesn't Matter" && props.item.country_title != '')
    {
        if(city_state_country.length > 1)
        city_state_country += ', ';
        
        city_state_country += props.item.country_title;
    }

    return (
        <li>
            <div class="all-pro-box" data-useravil="avilno" data-aviltxt="Offline">
                <div class="pro-img gray-border">
                    <a onClick={() => {props.openMemberProfileModal(props.item.member_id);/* gotoUserProfile(member_id) */}}>
                        {/* <img src="/assets/images/profiles/10.jpg" alt="" /> */}
                        { props.getProfileImage(props.item.profile_image) }
                    </a>
                    <div class="pro-ave" title="User currently available">
                        <span class="pro-ave-yes"></span>
                    </div>
                    {/* <div class="pro-avl-status">
                        <span class="marqu">Last login 10 mins ago | I'll be available on 10:00
                            AM</span>
                    </div> */}
                </div>

                <div class="pro-detail">
                    <h4><a onClick={() => {props.openMemberProfileModal(props.item.member_id);/* props.gotoUserProfile(member_id) */}} title={COMMONFUNCTIONS.camelCase(props.item.first_name+' '+props.item.last_name)+ ' ( '+props.item.member_profile_id }>{COMMONFUNCTIONS.camelCase(props.item.first_name+' '+props.item.last_name)} ( {props.item.member_profile_id} )</a></h4>
                    <div class="pro-bio">
                        <span>{props.item.calculated_age} Years</span>
                        { (props.item.height > 0) ? <span>{props.item.height} Feet</span> : '' }
                        { (props.item.marital_status_title != "Doesn't Matter") ? <span>{props.item.marital_status_title}</span> : '' }
                        { (city_state_country != "") ? <span>{city_state_country}</span> : '' }
                        { (props.item.education_title != "Doesn't Matter") ? <span>{props.item.education_title}</span> : '' }
                        { (props.item.occupation_title != "Doesn't Matter") ? <span>{props.item.occupation_title}</span> : '' }
                        { (caste_religion != "") ? <span>{caste_religion}</span> : '' }
                        { (props.item.language_title != "Doesn't Matter") ? <span>{props.item.language_title}</span> : '' }
                        {/* <span onClick={()=> {props.modalOpenMemberProfileBtnRef.current.click()}}>Test</span> */}
                    </div>

                    <ProfileActionPanel item={props.item} setTextLoginModal={props.setTextLoginModal} modalOpenLoginBtnRef={props.modalOpenLoginBtnRef} modalOpenExpressBtnRef={props.modalOpenExpressBtnRef} setContentExpressModal={props.setContentExpressModal} setExpressModalHeaderText={props.setExpressModalHeaderText} setSelectedMemberId={props.setSelectedMemberId} updateExpressInterest={props.updateExpressInterest} openMemberProfileModal={props.openMemberProfileModal} />
                </div>

                {/* <span class="enq-sav" data-toggle="tooltip"
                    title="Click to save this profile."><i class="fa fa-thumbs-o-up"
                        aria-hidden="true"></i></span> */}

            </div>
        </li>
    )
}

export default MemberItems