import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';
import sha512 from 'js-sha512';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import PlanItems from '../common/plan_item';
import Footer from '../../components/common/footer';

let rest_params = {};

function Plans()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const[payPlans, setPayPlans] = useState({});
    const[payUPlan, setPayUPlan] = useState({});
    const[transactionId, setTransactionId] = useState('');
    const[transactionHash, setTransactionHash] = useState('');
    const[isLoading, setIsLoading] = useState(true);
    const[paymentGateway, setPaymentGateway] = useState('phonepe');

    const modalOpenPaymentBtnRef = useRef(null);
    const modalClosePaymentBtnRef = useRef(null);
    
    const modalOpenPayUPaymentBtnRef = useRef(null);
    const modalClosePayUPaymentBtnRef = useRef(null);

    function getPayPackages()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_plans', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setPayPlans(responce.data.plans_data);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        //console.log(Object.keys(payPlans).length);
        if(Object.keys(payPlans).length < 1)
            getPayPackages();
    }, [payPlans])

    function RenderPlanProps(plan_props)
    {
        //console.log(plan_props);
        if(plan_props.length > 0)
        {
            return plan_props.slice(0,1).map((plan_prop) => {
                return (<li><i class="fa fa-check" aria-hidden="true"></i>{plan_prop.express_interest}
                </li>);
            })
        }
    }

    function List({ list }) {
        return (
            <ul>
                {Object.entries(list).map((item, key) => {
                    let item_details = item[1];
                    //console.log(item_details.amount);
                    return <li>
                                <div class="pri-box">
                                    <h2>{item_details.name}</h2>
                                    <p>Printer took a type and scrambled </p>
                                    <a href="sign-up.html" class="cta">Get Started</a>
                                    <span class="pri-cou"><b>${item_details.amount}</b>/mo</span>
                                    <ol>
                                    {
                                        //console.log(item_details.plan_description)
                                        Object.entries(item_details.plan_description).map((plan_prop, key) => {
                                            return <ListItem key={key} item={plan_prop[1]} />;
                                        })
                                    }
                                        {/* {
                                            ListItem(plan.plan_description)//plan_props_itemsRenderPlanProps(plan_props)
                                        } */}
                                        {/* <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li> */}
                                    </ol>
                                </div>
                            </li>
                })}
            </ul>
        );
      }
      
    function ListItem({ item }) {
        //console.log(item);
        const {label, value} = item;
        if (typeof value === 'object') {
            return (
                <li>{value}</li>
            );
        }
        return <li>{value}</li>;
    }

    function RenderPlans()
    {
        //console.log(payPlans);
        if(payPlans.length > 0)
        {
            return payPlans.map((plan, index) => {
                //console.log(plan);
                let plan_props_items = {};
                //plan_props_items = RenderPlanProps(plan.plan_description);
                //console.log(plan_props_items);
                return (
                    <li>
                        <div class="pri-box">
                            <h2>{plan.name}</h2>
                            <p>Printer took a type and scrambled </p>
                            <a href="sign-up.html" class="cta">Get Started</a>
                            <span class="pri-cou"><b>&#8377; {plan.amount}</b>/mo</span>
                            <ol>
                                {
                                    ListItem(plan.plan_description)//plan_props_itemsRenderPlanProps(plan_props)
                                }
                                {/* <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li> */}
                            </ol>
                        </div>
                    </li>
                )
            })
        }
        else
            return <li>No plans to load.</li>
    }

    function ListPlanDetailsItem({ item }) {
        //console.log(item[1]);
        //const {label, value} = item[1];
        let values = Object.values(item[1]);

        if (typeof values === 'object') {
            return (
                <li><i class="fa fa-check" aria-hidden="true"></i>{values}</li>
            );
        }
        return <li><i class="fa fa-check" aria-hidden="true"></i>{values}</li>;
    }

    function openPaymentModal(payment_gateway)
    {
        setPaymentGateway(payment_gateway);
        modalOpenPaymentBtnRef.current.click();
    }

    function openPayUPaymentModal(plan_index)
    {
        if(isLoggedIn)
        {
            console.log(payPlans[plan_index]);
            setPayUPlan(payPlans[plan_index]);
            generate_hash(payPlans[plan_index]);
            modalOpenPayUPaymentBtnRef.current.click();

            console.log(transactionId);
            console.log(transactionHash);
        }
        else
            COMMONFUNCTIONS.commonMessageErrorToast('Please Login for payment');
    }

    function generate_hash(plan_details)
    {
        var generated_hash = '';
        if(userData)
        {
            var new_transaction_id = COMMONFUNCTIONS.generatePassword(8);
            setTransactionId(new_transaction_id);
            //key|txnid|amount|productinfo|firstname|email|udf1|udf2|udf3|udf4|udf5|udf6|udf7|udf8|udf9|udf10
            //hash
            var hash_elements = [];
            hash_elements.push(CONSTANTS.PUM_MERCHANT_KEY);
            hash_elements.push(new_transaction_id);

            if(userData.member_id == '101')
                hash_elements.push(1);
            else
                hash_elements.push(plan_details.amount);

            hash_elements.push(plan_details.name);
            //hash_elements.push(plan_details.name);
            hash_elements.push(userData.first_name);
            hash_elements.push(userData.email);
            hash_elements.push(plan_details.plan_id);
            hash_elements.push(userData.member_id);
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push('');
            hash_elements.push(CONSTANTS.PUM_MERCHANT_SALT);

            generated_hash = hash_elements.join("|");

            console.log(generated_hash);

            var new_transaction_hash = sha512(generated_hash);

            console.log(new_transaction_hash);

            //console.log('test hash');
            //console.log(sha512('Z7x1Gs57|159368|1|test|sam|sam@test.com|||||||||||AqDjk0vx'));

            setTransactionHash(new_transaction_hash);
        }
        

        return generated_hash;
    }

    return (
        <div>
            <Top/>
            <Header/>
            {/* PRICING PLANS */}
            <section>
                <div class="plans-ban">
                    <div class="container">
                        <div class="row">
                            <span class="pri">Pricing</span>
                            <h1>Get Started <br/> Pick your Plan Now</h1>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                            <span class="nocre">Premium Plans</span>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* PRICING PLANS */}
            <section>
                <div class="plans-main">
                    <div class="container">
                        <div class="row">
                            <ul>
                                {
                                    (isLoading) ? <center>Loading...</center> : <PlanItems items={payPlans}  openPaymentModal={openPaymentModal} openPayUPaymentModal={openPayUPaymentModal} />/*<List list={payPlans} />RenderPlans()  <PlanItems items={payPlans} /> payPlans.map((index, value) => {
                                        <li>
                                            <div class="pri-box">
                                                <h2>Free</h2>
                                                <p>Printer took a type and scrambled </p>
                                                <a href="sign-up.html" class="cta">Get Started</a>
                                                <span class="pri-cou"><b>$0</b>/mo</span>
                                                <ol>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i> 5 Premium Profiles view /mo
                                                    </li>
                                                    <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li>
                                                </ol>
                                            </div>
                                        </li>
                                    }) */
                                }
                                {/* <li>
                                    <div class="pri-box">
                                        <h2>Free</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$0</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i> 5 Premium Profiles view /mo
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div class="pri-box pri-box-pop">
                                        <span class="pop-pln">Most popular plan</span>
                                        <h2>Gold</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$349</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> 20 Premium Profiles view /mo</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div class="pri-box">
                                        <h2>Platinum</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$549</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> 50 Premium Profiles view /mo</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <a ref={modalOpenPaymentBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#payment_scanner_modal">login</a>
                <div class="modal fade" id="payment_scanner_modal">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content notification-modal">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{t('payment')}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="text-center"><img src={CONSTANTS.SERVER_BASE_URL+'/template/front/images/'+paymentGateway+'.png'} width={50+'%'} /></div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalClosePaymentBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <a ref={modalOpenPayUPaymentBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#payment_payu_modal">login</a>
                {(isLoggedIn) &&
                    <div class="modal fade" id="payment_payu_modal">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content notification-modal">

                                {/* Modal Header */}
                                <div class="modal-header">
                                    <h4 class="modal-title seninter-tit">
                                        <span className='intename2'>{t('payment')}</span>
                                    </h4>
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>

                                {/* Modal body */}
                                <div class="modal-body seninter">
                                    <div class="text-center">
                                        <div class="pri-box ">
                                            <h2>{payUPlan.name}</h2>
                                            <span class="pri-cou"><b>₹ {payUPlan.amount}</b></span>
                                            <ol>
                                            {
                                                (payUPlan.plan_description !== undefined && payUPlan.plan_description !== '' && payUPlan.plan_description !== null) && Object.entries(payUPlan.plan_description).map((item, key) => {
                                                    return <ListPlanDetailsItem key={key} item={item} />;
                                                })
                                            }
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal footer */}
                                <div class="modal-footer">
                                    <form action="https://secure.payu.in/_payment" method="post" name="payuForm">
                                        <div className='hide'>
                                            <input type="hidden" id="surl" name="surl" value={CONSTANTS.SERVER_FRONT_URL+"home/pum_success_api_new"} />
                                            <input type="hidden" id="furl" name="furl" value={CONSTANTS.SERVER_FRONT_URL+"home/pum_failure_api_new"} />
                                            <input type="hidden" id="key" name="key" value={CONSTANTS.PUM_MERCHANT_KEY} />
                                            <input type="hidden" id="txnid" name="txnid" value={transactionId} />
                                            <input type="hidden" id="udf1" name="udf1" value={payUPlan.plan_id} />
                                            <input type="hidden" id="udf2" name="udf2" value={userData.member_id} />
                                            {(userData.member_id == '101') ? <input type="hidden" id="amount" name="amount" value="1"/> : <input type="hidden" id="amount" name="amount" value={payUPlan.amount} /> }                                
                                            <input type="hidden" id="productinfo" name="productinfo" value={payUPlan.name} />
                                            <input type="hidden" id="firstname" name="firstname" value={userData.first_name} />
                                            <input type="hidden" id="lastname" name="lastname" value={userData.last_name} />
                                            <input type="hidden" id="zipcode" name="zipcode" value={userData.present_address.postal_code} />
                                            <input type="hidden" id="email" name="email" value={userData.email} />
                                            <input type="hidden" id="phone" name="phone" value={userData.mobile} />
                                            <input type="hidden" id="address1" name="address1" value={userData.present_address.postal_code} />
                                            <input type="hidden" id="city" name="city" value={userData.present_address.city_title} />
                                            <input type="hidden" id="state" name="state" value={userData.present_address.state_title} />
                                            <input type="hidden" id="country" name="country" value={userData.present_address.country_title} />
                                            <input type="hidden" id="hash" name="hash" value={transactionHash} />
                                        </div>
                                        <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalClosePayUPaymentBtnRef}>Cancel</button>
                                        <button type="submit" class="btn btn-outline-primary m-l-15">Submit</button>
                                    </form>`
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
            {/* END */}
            <Footer/>
        </div>
    )
}

export default Plans