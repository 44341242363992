import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';
import Select, {
    components,
    MultiValueGenericProps,
    MultiValueProps,
    OnChangeValue,
    Props,
  } from 'react-select';

import * as CONSTANTS from '../../../Constants';
import  * as COMMONFUNCTIONS from '../../../utils/CommonFunctions';

import SubmitLoader from '../../../components/common/submit_loader';
import MultiSelect from '../../../components/common/multi_select';

let values = {};

function YourLikes(props)
{
    const {t} = useTranslation();

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));
    const [hobbies_data] = useState(common_data.common_data.hobbies_data);
    const [interest_data] = useState(common_data.common_data.interest_data);
    const [music_data] = useState(common_data.common_data.music_data);
    const [books_data] = useState(common_data.common_data.book_data);
    const [movies_data] = useState(common_data.common_data.movies_data);
    const [sports_data] = useState(common_data.common_data.sports_data);
    const [cuisine_data] = useState(common_data.common_data.cuisine_data);
    const [dress_style_data] = useState(common_data.common_data.dress_style_data);

    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userHobbiesInterestData, setUserHobbiesInterestData] = useState(userData.hobbies_and_interest);

    const [selectedHobbies, setSelectedHobbies] = useState({});
    const [preSelectedHobbies, setPreSelectedHobbies] = useState({});

    const [selectedInterests, setSelectedInterests] = useState({});
    const [preSelectedInterests, setPreSelectedInterests] = useState({});

    const [selectedMusic, setSelectedMusic] = useState({});
    const [preSelectedMusic, setPreSelectedMusic] = useState({});

    const [selectedBooks, setSelectedBooks] = useState({});
    const [preSelectedBooks, setPreSelectedBooks] = useState({});

    const [selectedMovies, setSelectedMovies] = useState({});
    const [preSelectedMovies, setPreSelectedMovies] = useState({});

    const [selectedSportsShow, setSelectedSportsShow] = useState({});
    const [preSelectedSportsShow, setPreSelectedSportsShow] = useState({});

    const [selectedCuisine, setSelectedCuisine] = useState({});
    const [preSelectedCuisine, setPreSelectedCuisine] = useState({});

    const [selectedDressStyle, setSelectedDressStyle] = useState({});
    const [preSelectedDressStyle, setPreSelectedDressStyle] = useState({});

    const [submitBtnText, changeSubmitBtnText] = useState('Save');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const modalCloseBtnRef = useRef(null);

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userData);
    }, [userData])

    const LanguageSchema = Yup.object({
        /* mother_tongue: Yup.string().required('Required'), */
    });

    const SubmitForm = (values, { setSubmitting, resetForm }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values['para1'] = "update_hobbies_and_interest";
        values['user_id'] = userData.member_id;
        values['hobby'] = selectedHobbies;
        values['interest'] = selectedInterests;
        values['music'] = selectedMusic;
        values['books'] = selectedBooks;
        values['movie'] = selectedMovies;
        values['sports_show'] = selectedSportsShow;
        values['cuisine'] = selectedCuisine;
        values['dress_style'] = selectedDressStyle;

        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        console.log(values);

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/profile',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.setSession('UserDetails', '');
                props.fetchUserProfileData();
                setTimeout(() => {
                    COMMONFUNCTIONS.commonMessageInfoToast(response.data.message);
                    changeSubmitBtnText('Save');
                    changeSubmitBtnStatus(false);
                    setAllUsersData(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
                }, 2500);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                changeSubmitBtnText('Save');
                changeSubmitBtnStatus(false);
            }
            setTimeout(() => {
                setSubmitting(false);
                modalCloseBtnRef.current.click();
            }, 2000);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            changeSubmitBtnText('Save');
            changeSubmitBtnStatus(false);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserHobbiesInterestData(user_data.hobbies_and_interest);
        
        generatePreSelectedHobbyArray(user_data.hobbies_and_interest.hobby_id);
        generatePreSelectedInterestArray(user_data.hobbies_and_interest.interest_id);
        generatePreSelectedMusicArray(user_data.hobbies_and_interest.music_id);
        generatePreSelectedBooksArray(user_data.hobbies_and_interest.books_id);
        generatePreSelectedMoviesArray(user_data.hobbies_and_interest.movie_id);
        generatePreSelectedSportsShowArray(user_data.hobbies_and_interest.sports_show_id);
        generatePreSelectedCuisineArray(user_data.hobbies_and_interest.cuisine_id);
        generatePreSelectedDressStyleArray(user_data.hobbies_and_interest.dress_style_id);
    }

    // Hobbies Change Functions Start

    function handleHobbiesChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.hobbies_id);
        });
        setSelectedHobbies(selected_items.toString());
        setPreSelectedHobbies(data);
    }

    function generatePreSelectedHobbyArray(value_array)
    {
        let value_data = [];
        hobbies_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.hobbies_id))
                    value_data.push(hobbies_data[i]);
            }
        );
        setPreSelectedHobbies(value_data);
    }

    // Hobbies Change Functions End

    // Interests Change Functions Start
    function handleInterestsChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.interest_id);
        });
        setSelectedInterests(selected_items.toString());
        setPreSelectedInterests(data);
    }

    function generatePreSelectedInterestArray(value_array)
    {
        let value_data = [];
        interest_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.interest_id))
                    value_data.push(interest_data[i]);
            }
        );
        setPreSelectedInterests(value_data);
    }

    // Interests Change Functions End

    // Music Change Functions Start

    function handleMusicChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.favourite_music_id);
        });
        setSelectedMusic(selected_items.toString());
        setPreSelectedMusic(data);
    }

    function generatePreSelectedMusicArray(value_array)
    {
        let value_data = [];
        music_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.favourite_music_id))
                    value_data.push(music_data[i]);
            }
        );
        setPreSelectedMusic(value_data);
    }

    // Music Change Functions End

    // Books Change Functions Start

    function handleBooksChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.favourite_book_id);
        });
        setSelectedBooks(selected_items.toString());
        setPreSelectedBooks(data);
    }

    function generatePreSelectedBooksArray(value_array)
    {
        let value_data = [];
        books_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.favourite_book_id))
                    value_data.push(books_data[i]);
            }
        );
        setPreSelectedBooks(value_data);
    }

    // Books Change Functions End

    // Movies Change Functions Start

    function handleMoviesChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.favourite_movies_id);
        });
        setSelectedMovies(selected_items.toString());
        setPreSelectedMovies(data);
    }

    function generatePreSelectedMoviesArray(value_array)
    {
        let value_data = [];
        movies_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.favourite_movies_id))
                    value_data.push(movies_data[i]);
            }
        );
        setPreSelectedMovies(value_data);
    }

    // Movies Change Functions End

    // SportsShow Change Functions Start

    function handleSportsShowChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.sports_id);
        });
        setSelectedSportsShow(selected_items.toString());
        setPreSelectedSportsShow(data);
    }

    function generatePreSelectedSportsShowArray(value_array)
    {
        let value_data = [];
        sports_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.sports_id))
                    value_data.push(sports_data[i]);
            }
        );
        setPreSelectedSportsShow(value_data);
    }

    // SportsShow Change Functions End

    // Cuisine Change Functions Start

    function handleCuisineChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.cuisine_id);
        });
        setSelectedCuisine(selected_items.toString());
        setPreSelectedCuisine(data);
    }

    function generatePreSelectedCuisineArray(value_array)
    {
        let value_data = [];
        cuisine_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.cuisine_id))
                    value_data.push(cuisine_data[i]);
            }
        );
        setPreSelectedCuisine(value_data);
    }

    // Cuisine Change Functions End

    // DressStyle Change Functions Start

    function handleDressStyleChange(data)
    {
        let selected_items = [];
        data.forEach((item, index) => {
            selected_items.push(item.dress_style_id);
        });
        setSelectedDressStyle(selected_items.toString());
        setPreSelectedDressStyle(data);
    }

    function generatePreSelectedDressStyleArray(value_array)
    {
        let value_data = [];
        dress_style_data.forEach((x, i) => {
                if(value_array != null && value_array.includes(x.dress_style_id))
                    value_data.push(dress_style_data[i]);
            }
        );
        setPreSelectedDressStyle(value_data);
    }

    // DressStyle Change Functions End

    const MyOption = ({ children, ...props }) => {
        console.log(children);
        console.log(props);
        return (
            <components.Option {...props}>
            {children}
            {/* <option value={props.data.hobbies_id}>{props.data.title}</option> */}
            </components.Option>
        );
    };

    return (
        <div>
            {
                (userHobbiesInterestData) ? <div class="pr-bio-c pr-bio-info">
                    <h3><b className='cursor-pointer' data-toggle="modal" data-target="#user_hobbiesinterest">{t('your_likes')}</b> <i class="fa fa-pencil float-right mv-pro cursor-pointer" data-toggle="modal" data-target="#user_hobbiesinterest" aria-hidden="true"></i></h3>
                    <ul>
                        <li><b>{t('hobby')}:</b> <span title={ userHobbiesInterestData.hobby_title }>{ userHobbiesInterestData.hobby_title }</span></li>

                        <li><b>{t('interest')}:</b> <span title={ userHobbiesInterestData.interest_title }>{ userHobbiesInterestData.interest_title }</span></li>

                        <li><b>{t('music')}:</b> <span title={ userHobbiesInterestData.music_title }>{ userHobbiesInterestData.music_title }</span></li>

                        <li><b>{t('books')}:</b> <span title={ userHobbiesInterestData.books_title }>{ userHobbiesInterestData.books_title }</span></li>

                        <li><b>{t('movie')}:</b> <span title={ userHobbiesInterestData.movie_title }>{ userHobbiesInterestData.movie_title }</span></li>

                        <li><b>{t('tv_show')}:</b> <span title={ userHobbiesInterestData.tv_show }>{ userHobbiesInterestData.tv_show }</span></li>

                        <li><b>{t('sports_show')}:</b> <span title={ userHobbiesInterestData.sports_show_title }>{ userHobbiesInterestData.sports_show_title }</span></li>

                        <li><b>{t('fitness_activity')}:</b> <span title={ userHobbiesInterestData.fitness_activity }>{ userHobbiesInterestData.fitness_activity }</span></li>

                        <li><b>{t('cuisine')}:</b> <span title={ userHobbiesInterestData.cuisine_title }>{ userHobbiesInterestData.cuisine_title }</span></li>

                        <li><b>{t('dress_style')}:</b> <span title={ userHobbiesInterestData.dress_style_title }>{ userHobbiesInterestData.dress_style_title }</span></li>
                    </ul>
                </div> : ''
            }

            <div class="modal fade" id="user_hobbiesinterest">
                <div class="modal-dialog modal-lg">
                    <Formik
                        initialValues={{ hobby: userHobbiesInterestData.hobby_id, interest: userHobbiesInterestData.interest_id, music: userHobbiesInterestData.music_id, books: userHobbiesInterestData.books_id, movie: userHobbiesInterestData.movie_id, tv_show: userHobbiesInterestData.tv_show, sports_show: userHobbiesInterestData.sports_show_id, fitness_activity: userHobbiesInterestData.fitness_activity, cuisine: userHobbiesInterestData.cuisine_id, dress_style: userHobbiesInterestData.dress_style_id }}
                        validationSchema={LanguageSchema}
                        onSubmit={SubmitForm}
                        >
                    {formik => (
                    <form class="cform fvali" onSubmit={formik.handleSubmit}>
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">{t('your_likes')}</h4>
                                <button type="button" class="close" data-dismiss="modal" ref={modalCloseBtnRef}>&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('hobby')}:</label>
                                            {/* <select class="chosen-select" id="hobby" name='hobby' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {hobbies_data.map((option, index) => (
                                                    <option key={index} value={option.hobbies_id} selected={(option.hobbies_id == userHobbiesInterestData.hobby_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {/* <Select components={{Option: MyOption}} options={hobbies_data}  /> */}
                                            <Select
                                                name="hobby"
                                                id="hobby"
                                                className="chosen-select sam-select"
                                                //defaultValue={[hobbies_data[2], hobbies_data[3]]}
                                                //defaultValue={selectedHobbies}
                                                value={preSelectedHobbies}
                                                options={hobbies_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.hobbies_id}
                                                onChange={handleHobbiesChange}
                                                //onChange={this.setSubTag}
                                                //onChange={formik.handleChange}
                                                /* onChange={selectedOption => {
                                                    let event = {target: {name: 'hobby', value: selectedOption}}
                                                    //handleChange(hobby)
                                                  }} */
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <Select
                                                options={optionList}
                                                placeholder="Select color"
                                                value={selectedOptions}
                                                onChange={handleSelect}
                                                isSearchable={true}
                                                isMulti
                                            /> */}
                                            {formik.errors.hobby ? <div className='m-t-10 text-left error'>{formik.errors.hobby}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('interest')}:</label>
                                            <Select
                                                name="interest"
                                                id="interest"
                                                className="chosen-select sam-select"
                                                value={preSelectedInterests}
                                                options={interest_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.interest_id}
                                                onChange={handleInterestsChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="interest" name='interest' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {interest_data.map((option, index) => (
                                                    <option key={index} value={option.interest_id} selected={(option.interest_id == userHobbiesInterestData.interest_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.interest ? <div className='m-t-10 text-left error'>{formik.errors.interest}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('music')}:</label>
                                            <Select
                                                name="music"
                                                id="music"
                                                className="chosen-select sam-select"
                                                value={preSelectedMusic}
                                                options={music_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.favourite_music_id}
                                                onChange={handleMusicChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="music" name='music' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {music_data.map((option, index) => (
                                                    <option key={index} value={option.music_id} selected={(option.music_id == userHobbiesInterestData.music_id) ? 'selected' : '' }>{option.name}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.music ? <div className='m-t-10 text-left error'>{formik.errors.music}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('books')}: </label>
                                            <Select
                                                name="books"
                                                id="books"
                                                className="chosen-select sam-select"
                                                value={preSelectedBooks}
                                                options={books_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.favourite_book_id}
                                                onChange={handleBooksChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="books" name='books' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {books_data.map((option, index) => (
                                                    <option key={index} value={option.favourite_book_id} selected={(option.favourite_book_id == userHobbiesInterestData.read_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.books ? <div className='m-t-10 text-left error'>{formik.errors.books}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('movies')}:</label>
                                            <Select
                                                name="movies"
                                                id="movies"
                                                className="chosen-select sam-select"
                                                value={preSelectedMovies}
                                                options={movies_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.favourite_movies_id}
                                                onChange={handleMoviesChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="movie" name='movie' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {movies_data.map((option, index) => (
                                                    <option key={index} value={option.favourite_movies_id} selected={(option.favourite_movies_id == userHobbiesInterestData.movie_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.movie ? <div className='m-t-10 text-left error'>{formik.errors.movie}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('tv_show')}: </label>
                                            <input type="text" id="tv_show" class="form-control" placeholder={t('enter_tv_show')} name="tv_show" onChange={formik.handleChange} value={formik.values.tv_show} />
                                            {formik.errors.tv_show ? <div className='m-t-10 text-left error'>{formik.errors.tv_show}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('sports_show')}:</label>
                                            <Select
                                                name="sports_show"
                                                id="sports_show"
                                                className="chosen-select sam-select"
                                                value={preSelectedSportsShow}
                                                options={sports_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.sports_id}
                                                onChange={handleSportsShowChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="sports_show" name='sports_show' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {sports_data.map((option, index) => (
                                                    <option key={index} value={option.sports_id} selected={(option.sports_id == userHobbiesInterestData.sports_show_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.sports_show ? <div className='m-t-10 text-left error'>{formik.errors.sports_show}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('fitness_activity')}: </label>
                                            <input type="text" id="fitness_activity" class="form-control" placeholder={t('enter_fitness_activity')} name="fitness_activity" onChange={formik.handleChange} value={formik.values.fitness_activity} />
                                            {formik.errors.fitness_activity ? <div className='m-t-10 text-left error'>{formik.errors.fitness_activity}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-t-15'>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('cuisine')}:</label>
                                            <Select
                                                name="cuisine"
                                                id="cuisine"
                                                className="chosen-select sam-select"
                                                value={preSelectedCuisine}
                                                options={cuisine_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.cuisine_id}
                                                onChange={handleCuisineChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="cuisine" name='cuisine' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {cuisine_data.map((option, index) => (
                                                    <option key={index} value={option.cuisine_id} selected={(option.cuisine_id == userHobbiesInterestData.cuisine_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.cuisine ? <div className='m-t-10 text-left error'>{formik.errors.cuisine}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label class="float-left lb font-15">{t('dress_style')}: </label>
                                            <Select
                                                name="dress_style"
                                                id="dress_style"
                                                className="chosen-select sam-select"
                                                value={preSelectedDressStyle}
                                                options={dress_style_data}
                                                getOptionLabel ={(option)=>option.title}
                                                getOptionValue ={(option)=>option.dress_style_id}
                                                onChange={handleDressStyleChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                component={MultiSelect}
                                                placeholder={' - '+t('choose')+' - '}/>
                                            {/* <select class="chosen-select" id="dress_style" name='dress_style' onChange={formik.handleChange} multiple>
                                                <option value="">- {t('choose')} -</option>
                                                {dress_style_data.map((option, index) => (
                                                    <option key={index} value={option.dress_style_id} selected={(option.dress_style_id == userHobbiesInterestData.dress_style_id) ? 'selected' : '' }>{option.title}</option>
                                                ))}
                                            </select> */}
                                            {formik.errors.dress_style ? <div className='m-t-10 text-left error'>{formik.errors.dress_style}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'}  Button_Class={'btn btn-primary'} />
                                {/* <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button> */}
                            </div>

                        </div>
                    </form>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default YourLikes