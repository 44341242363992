import React, { useState, useEffect } from 'react'

import {useNavigate, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;

function MyPackage()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [packageData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserPackageDetails')));
    const [remPackageData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserRemPackageDetails')));
    const [userData, setUserData] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
                <section>
                    <div class="profi-pg profi-ban">
                        <div class="">
                            <div class="">
                                <div class="profile">
                                    <div class="pg-pro-big-im">
                                        <div class="s1">
                                            {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                            { getProfileImage(userData.profile_image) }
                                        </div>
                                    </div>
                                </div>
                                <div class="profi-pg profi-bio">
                                    <div class="lhs">
                                        <div class="pro-pg-intro">
                                            <h1 className='header'>{t('my_package')}<Link className="float-right" to="/my-profile.html"><i class="fa fa-times" aria-hidden="true"></i></Link></h1>
                                        </div>
                                    </div>

                                    {/* PROFILE lHS */}
                                    <div class="rhs">
                                        <div class="card m-t-40">
                                            <div class="card-body p-0">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <h2 className='header text-center'>{t('your_current_package')}</h2>
                                                        <table class="table table-condensed table-bordered m-10 w-95">
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('title')}</th>
                                                                    <th>{t('info')}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('user_package')}</td>
                                                                    <td className='font-14'>{packageData.current_package}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('package_price')}</td>
                                                                    <td className='font-14'>&#8377; {packageData.package_price}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('package_gateway')}</td>
                                                                    <td className='font-14'>{packageData.payment_type}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <h2 className='header text-center'>{t('remaining_package')}</h2>
                                                        <table class="table table-condensed table-bordered m-10 w-95">
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('features')}</th>
                                                                    <th>{t('unit_left')}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('remaining_interests')}</td>
                                                                    <td className='font-14'>{remPackageData.remaining_interest} {t('times')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('remaining_messages')}</td>
                                                                    <td className='font-14'>{remPackageData.direct_messages} {t('times')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='font-14'>{t('photo_gallery')}</td>
                                                                    <td className='font-14'>{remPackageData.photo_gallery} {t('images')}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    {/* END PROFILE lHS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END PROFILE */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default MyPackage