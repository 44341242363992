import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom";

function Processing()
{
  return (
        <div>
            {/* REGISTER */}
            <section>
                <div class="login pg-404">
                    <div class="container">
                        <div class="row">
                            <div class="inn">
                                <div class="lhs">
                                    <div class="tit">
                                        <h2>Processing</h2>
                                    </div>
                                    <div class="log-bg">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Processing