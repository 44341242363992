import React, { useEffect, useState } from 'react'

import Top from '../common/top';
import Header from '../common/header';
import Footer from '../common/footer';
import FAQItems from '../common/faq_items';

function FAQ(props)
{
    const[pageContent, setPageContent] = useState('');
    const[isLoading, setIsLoading] = useState(true);

    return (
        <div>
            <Top/>
            <Header/>

            {/* REGISTER */}
            <section>
                <div class="login pg-faq">
                    <div class="container">
                        <div class="row">
                            <div class="inn ab-faq-lhs">
                                <div class="form-tit">
                                    <h4>FAQ</h4>
                                    <h1>Frequently asked questions</h1>
                                </div>
                                <FAQItems />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  END  */}
            <Footer/>
        </div>
    )
}

export default FAQ